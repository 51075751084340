@import 'init';

//Variables
$yellow: #f7cf57;
$gray: #aaaaaa;
$light-gray: #eaeaea;
$aqua: #92c6ce;
$menu-tan: #fcf3d9;
$yellow2: #f3d476;
$dark-gray: #707070;
$blue-font: #1d7db3;
$red-font: #9e0b0f;
$recycled-back: #ebf3dc;
$recycled-text: #536a27;
$wooden-back: #dbd1c3;
$wooden-text: #5e4d36;
$museo: "museo-sans";
$gio: "gioviale";
$mr-eaves-mod: "mr-eaves-xl-modern";
$mr-eaves-sans: "mr-eaves-xl-sans-narrow";
$slab: "museo-slab";
$tab: 770px;
$tab-l: 1024px;
$desk: 1240px;

/*-----------------------------------Landing Pages Styling ------------------------*/
#block-views-outdoor-living-banner-block,
#block-views-playset-banner-block,
#block-views-structure-banner-block,
#block-views-structure-banner-block-1,
#block-views-structure-banner-block-2,
#block-views-structure-banner-block-3,
#block-views-structure-banner-block-4,
#block-views-structure-banner-block-5 {
	height: 240px;
	overflow: hidden;
	.view-outdoor-living-banner,
	.view-structure-banner,
	.view-playset-banner {
		width: 100%;
		float: right;
	}
	.view-outdoor-living-banner .views-slideshow-cycle-main-frame,
	.view-playset-banner .views-slideshow-cycle-main-frame,
	.view-structure-banner-banner .views-slideshow-cycle-main-frame  {
		width: 100% !important;
		height: auto;
	}
	.view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
	.view-playset-banner .views-slideshow-cycle-main-frame-row,
	.view-structure-banner .views-slideshow-cycle-main-frame-row {
		width: 100% !important;
		height: auto;
	}
	.view-outdoor-living-banner .field-content,
	.view-playset-banner .field-content,
	.view-structure-banner .field-content {
		max-width: 100%;
		width: 100%;
	}
	.view-outdoor-living-banner .field-content img,
	.view-playset-banner .field-content img,
	.view-structure-banner .field-content img {
		max-width: 100%;
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
		min-width: 635px;
		margin-top: -19px;
	}
}

#block-block-4,
#block-block-5,
#block-block-9,
#block-block-10,
#block-block-11,
#block-block-19,
#block-block-20  {
	background-color: $yellow;
	color: #fff;
	text-align: right;
	height: 289px;

	h1 {
		text-transform: uppercase;
		margin-right: 30px;
		font-family: $museo;
		font-weight: 100;
		font-size: 3em;
		padding-top: .5em;
		margin-top: 0;
		letter-spacing: 3px;
		span {
			font-weight: 900;
		}
	}
	h2 {
		font-family: $museo;
		color: #fff;
		font-size: 2em;
		font-weight: 100;
		margin-right: 30px;
		span {
			font-weight: 100;
		}
	}	
	.contact-info {
		color: #fff;
		text-align: right;
		margin-right: 30px;
		padding-top: 20px;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;		
		h3 {
			line-height: 2rem;
			margin: 0;
		}
		.phone {
			font-weight: 300;
			img {
				width: 30px;
			}
		}
		.email {
			padding-left: 20px;
			img {
			 	width: 50px;
			 }
		}
		a {
			color: #fff;
			font-weight: 300;
		}
	}
}

.product-block {
	h1 {
		font-family: $museo;
		font-weight: 100;
		font-size: 2.5em;
		margin-bottom: 0px;
		span {
			font-weight: 900;
		}
	}
	.outdoor-icon {
		width: 235px;
		height: auto;	
		margin: 0 auto;
		display: block;
	}
	.view-products {
		font-family: $museo;
		text-transform: uppercase;
		font-weight: 900;
	}
}

.recycled {
	width: 90vw;
	margin: 30px 0px;	
	background-color: #ebf3dc;
	border-radius: 10px;
	padding: 1px 2em;
	color: #536a27;
	a {
		color: #536a27;
	}
}

.wooden {
	width: 90vw;
	margin: 30px auto;	
	background-color: #dbd1c3;
	border-radius: 10px;
	padding: 1px 2em;
	color: #5e4d36;
	a {
		color: #5e4d36;
	}	
}


.page-title {
	color: #536a27;
	font-family: $museo;
	font-weight: 900;
	text-align: center;
	font-size: 3em;
	span {
		font-weight: 100;
	}
}

.text {
	padding: 0px 13px;
}

.page-title-w {
	color: $wooden-text;
	font-family: $museo;
	font-weight: 900;
	text-align: center;
	font-size: 3em;
	span {
		font-weight: 100;
	}	
}


.seating {
	background-color: #ebf3dc;
	width: 90vw;
	margin: 0 auto;
	border-radius: 10px;
	a {
		color: $recycled-text;
	}
	h1 {
		text-align: left;
		font-family: $museo;
		font-size: 2.5em;
		padding-top: 13px;
		span {
			font-weight: 100;
		}
	}
	.text {
		color: #536a27;

		 .view-products {
			color: #536a27;
			text-align: right; 
			text-transform: uppercase;
			font-weight: 900;
		}
	}
	.seating-photo {
		background-image: url(/sites/default/files/families-images/recycled-chairs.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 250px;
		overflow: hidden;
		border-radius: 0px 0px 10px 10px;
		display: block;		
	}
}

.tables {
	background-color: $recycled-back;	
	width: 90vw;
	margin: 0 auto;
	border-radius: 10px;
	a {
		color: $recycled-text;
	}
	.text {
		color: $recycled-text;

		h1 {
			color: $recycled-text;
			font-family: $museo;
			font-size: 2.5em;
			padding-top: 13px;
			span {
				font-weight: 100;
			}
		}
		.view-products {
			color: $recycled-text;
			text-align: right;
			text-transform: uppercase;
			font-weight: 900;
		}
	}
	.tables-photo {
		background-image: url(/sites/default/files/families-images/reycled-outdoor-dining.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: bottom center;
		height: 250px;	
		overflow: hidden;
		border-radius: 0px 0px 10px 10px;
		display: block;
	}
}

.seating-w,
.tables-w {
	background-color: $wooden-back;	
	//width: 90vw;
	margin: 0 auto;
	border-radius: 10px;
	.text {
		color: $wooden-text;

		a {
			color: $wooden-text;
		}
		h1 {
			color: $wooden-text;
			font-family: $museo;
			font-size: 2.5em;
			padding-top: 13px;			
			span {
				font-weight: 100;
			}
		}
		.view-products {
			color: $wooden-text;
			text-align: right;
			text-transform: uppercase;
			font-weight: 900;
		}
	}
	.tables-photo {
		background-image: url(/sites/default/files/families-images/wooden-outdoor-dining.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 250px;	
		overflow: hidden;
		border-radius: 0px 0px 10px 10px;
		display: block;
	}
	.seating-photo {
		background-image: url(/sites/default/files/families-images/JW-adirondack.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 250px;	
		overflow: hidden;
		border-radius: 0px 0px 10px 10px;	
		display: block;	
	}	
}
.view.view-accessories.view-id-accessories.view-display-id-block_1,
.view.view-casual-seating.view-id-casual_seating.view-display-id-block_1,
.view.view-accessories.view-id-accessories.view-display-id-page {
	.view-content {
		display: grid;
		grid-template-columns: repeat(auto-fill,305px);
		justify-content: center;
		@include breakpoint ($desk) {
			grid-template-columns: repeat(auto-fill,380px);
		}
	}
	.view-filters {
		width: 237px;
		margin: auto;
		label {
			color: $recycled-text;
			font-weight: 600;
			font-family: $museo;
		}
		.form-submit {
			margin-top: 1.4em;
			background-color: $recycled-back;
			color: $recycled-text;
			border-radius: 5px;
			padding: 3px 15px;
			font-weight: 600;
			font-family: $museo;
		}
	}
}
	
.view-accessories,
.view-casual-seating,
.view.view-accessories.view-id-accessories.view-display-id-page {
	display: block;
	margin: 0 auto;
	max-width: 100%;
	width: 100%;
	min-width: 280px;
	.views-row {		
		width: 252px;
		height: 252px;
		margin: 2em auto;
		border-radius: 10px;
		position: relative;
	}
	.views-field-title {
		padding: 20px 0 0 20px;
		font-family: $museo;
		font-weight: 700;
			a {
			font-size: 1.2em;
		}
	}
	.views-field-body {
		width: 100%;
		padding: 10px 0 10px 20px;
			a {
				font-family: $museo;
			}
	}
	.views-field-field-thumbnail {
		text-align: center;
		height: 245px;
		overflow: hidden;
		padding-top: 8px;
		img {
			width: auto;
			max-height: 177px;
		}
	}	
	.views-field-field-image {
		text-align: center;
		height: 245px;
		overflow: hidden;
		padding-top: 8px;
		img {
			width: auto;
			max-height: 177px;
		}
	}
	.views-field-field-field-price2 {
		text-align: right;
	}
	
}

.view.view-accessories.view-id-accessories.view-display-id-page {
	display: block;
	margin: 0 auto;
	max-width: 100%;
	width: 100%;
	min-width: 280px;
	.acc-message {
		text-align: center;
		color: $red-font;
	}
	.views-exposed-form {
		width: 330px;
		margin: 0 auto;
	}	
	.view-empty {
		text-align: center;
	}
}

.field-name-field-product-name {
	font-family: $museo;
	font-size: 1.8em;
	font-weight: bold;
	line-height: 1.2;
}
.field-name-field-price {
	font-family: $museo;
	font-size: 1.3em;
}

.field-name-field-table {
	text-align: center;
}

.page-taxonomy-term-1,
.page-taxonomy-term-2,
.page-taxonomy-term-6,
.page-taxonomy-term-15,
.page-taxonomy-term-20,
.page-taxonomy-term-21,
.page-taxonomy-term-22,
.page-taxonomy-term-27,
.page-taxonomy-term-25,
.page-taxonomy-term-31,
.page-taxonomy-term-33,
.page-taxonomy-term-34,
.page-taxonomy-term-36 {
	.view-casual-seating,
	.view-accessories {
		.views-row{
			background-color: $recycled-back; 
		}
		.views-field-title {
			padding: 20px 0 0 20px;
				a {
					font-size: 1.2em;
					color: $recycled-text;
					font-family: $museo;
					font-weight: 700;
					@include breakpoint ($desk) {
						font-size: 1.5em;
					}					
				}
			}
		.views-field-title-1 a {
			color: $recycled-text;
		}
		
		.views-field-body {
			color: $recycled-text;
			font-family: $museo;
			font-weight: 300;
		}
		.views-field-field-thumbnail,
		.views-field-field-image {
			text-align: center;
			overflow: hidden;
			width: 100%;
			img {
			}
		}		
		.views-field-field-price2 {
			background-color: $recycled-text;
			color: $recycled-back;
			text-align: right;
			font-family: $museo;
			position: absolute;
			width: 100%;
			left: 0;
			padding: 10px;
			bottom: 0;
			border-radius: 0 0 10px 10px;
			font-weight: 900;
			a {
				color: $recycled-back;
				font-family: $museo;
				padding-left: 3px;
			}
		}
	}
}


.page-taxonomy-term-3,
.page-taxonomy-term-4,
.page-taxonomy-term-7,
.page-taxonomy-term-8,
.page-taxonomy-term-10,
.page-taxonomy-term-23,
.page-taxonomy-term-24,
.page-taxonomy-term-26,
.page-taxonomy-term-28,
.page-taxonomy-term-30,
.page-taxonomy-term-32,
.page-taxonomy-term-35 {
	.view-accessories,
	.view-casual-seating
		{
		.views-row{
			background-color: $wooden-back; 
		}
		.views-field-title {
			padding: 20px 0 0 20px;
		
			a {
				font-size: 1.2em;
				color: $wooden-text;
				font-family: $museo;
				@include breakpoint ($desk) {
					font-size: 1.5em;
				}
			}
		}
		.views-field-title-1 a {
			color: $wooden-text;
		}
		.views-field-body {
			color: $wooden-text;
			font-family: $museo;
			font-weight: 300;
		}		
		.views-field-field-price2 {
			background-color: $wooden-text;
			color: $wooden-back;
			text-align: right;
			font-family: $museo;
			position: absolute;
			width: 100%;
			left: 0;
			padding: 10px;
			bottom: 0;
			font-weight: 900;
			border-radius: 0 0 10px 10px;
			a {
				color: $wooden-back;
				font-family: $museo;
				padding-left: 3px;
			}
		}				
	}
}

#taxonomy-term-12 {
	.page-title {
		color: $wooden-text;
	}
}

.page-taxonomy-term-25 {
	.page-title {
		color: $aqua;
	}
}

.node-type-accessories {
	.page-title {
		display: none;
	}
	.views-field-field-image {
		img {
			//max-height: 120px;
			width: auto;
		}
	}
}

.view.view-accessories.view-id-accessories.view-display-id-page {
	.views-row {
		color: white;
		background-color: $recycled-back;
	}
	.views-field-body {
		color: $recycled-text;
	}
	.views-field-title a {
		font-size: 1.5em;
		color: $recycled-text;
	}
	.views-field-title-1 a {
		color: $recycled-text;
	}
	.views-field-field-image {
		text-align: center;
	}
	.views-field-field-price2 {
		background-color: $recycled-text;
		color: $recycled-back;
		text-align: right;
		font-family: $museo;
		position: absolute;
		width: 100%;
		left: 0;
		padding: 10px;
		bottom: 0;
		border-radius: 0 0 10px 10px;
		font-weight: 900;
		a {
			color: $recycled-back;
			font-family: $museo;
			padding-left: 3px;
		}
	}				
}


#block-block-7,
#block-block-21,
#block-block-22,
#block-block-23,
#block-block-24,
#block-block-25,
#block-block-26,
#block-block-27,
#block-block-28, {
	width: 267px;
	margin: 0 auto;
	a {
		display: flex;
		align-items: center;
		width: 310px;
		flex-wrap: wrap;
		color: $recycled-text;
	}
	img {
		width: 45px;
		margin: 0 18px;
	}
}

#block-views-casual-seating-block-2, 
#block-views-accessories-block-2 {
	display: none;
	@include breakpoint ($desk) {
		display: inline-block;
	}
}
#block-views-casual-seating-block-3 {
	display: block;
	@include breakpoint (1162px) {
			display: none;
	}
}

#block-views-casual-seating-block-2, 
#block-views-casual-seating-block-3,
#block-views-accessories-block-2 {
	margin: 0 auto;
	width: 260px;
	color: $recycled-text;
	background-color: $recycled-back;
	padding: 0 10px 10px 10px;
	border-radius: 10px;
	margin-top: 20px;
	text-align: left;
	.view-header {
		padding-left: 10px;
		padding-top: 10px;
		width: 200px;
		text-align: center;
		h3 {
			font-size: 1.5em;
			margin: 0;
			font-weight: bold;
		}
		a {
			font-weight: bold;
		}
	}
	.view-content {
		padding-left: 1.2em;
		width: 252px;
		a {
			font-size: 14px;
			font-weight: 500;
		}
	}
	a {
		color: $recycled-text;
		font-weight: 500;
	}
}

.node-type-products,
.node-type-accessories {
	.layout-3col__full {
	    max-width: 1500px;
	    margin: auto;
	    float: none;
	    width: 90%;
	    text-align: center;
	}
}

.node-products,
.node-accessories {
	padding: 0 2em;
	width: 90%;
	margin: 0 auto;
	text-align: left;
	.field-name-field-price,
	.field-name-field-price2 {
		font-weight: bold;
		color: $red-font;
		padding: 14px 0px;
	}

}

.gallery-frame {
	width: 97%;
	margin: 0 auto;
	
}
a.next-slide.slide-button {
	background-image: url(/sites/default/files/families-images/Arrow-right.png);
	color: transparent;
	width: 57px;
	height: 80px;
	display: block;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;

}
a.prev-slide.slide-button {
	background-image: url(/sites/default/files/families-images/Arrow-left.png);
	color: transparent;
	width: 57px;
	height: 80px;
	display: block;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;

}
.arrow.back {
	position: absolute;
	left: 6px;
	top: 6px;
	background-image: url(/sites/default/files/families-images/Arrow-left.png);
	color: transparent;
	width: 17px;
	height: 80px;
	display: block;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

.arrow.forward {
	position: absolute;
	right: 6px;
	top: 6px;
	background-image: url(/sites/default/files/families-images/Arrow-right.png);
	color: transparent;
	width: 17px;
	height: 80px;
	display: block;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

.galleryformatter .view-full {
	position: relative;
	color: $recycled-text;
}

.galleryformatter .slide-button {
	top: 43% !important;
}

.gallery-slides {
	height: 375px !important;
	width: 100% !important;
	.panel-overlay {
		display: none;
	}
}
.gallery-slide {
	a {
		float: right;
	}
	img {
		display: block;
		margin: auto;
		padding: 12px;
	}	
}
.gallery-thumbs {
    padding: 0 40px;
    width: 100% !important;
    height: 90px;
    margin: 20px auto !important;
    .wrapper {
	    background: 0 0;
		padding: 0 17px;
		height: 90px;
    }
    img {
	    height: 90px;
	    width: auto;
		border: 1px solid $light-gray;    
    }
}

#mini-panel-product_information_form {
	width: 275px;
	padding: 10px 27px;
	margin: 2em auto;
	background-color: $recycled-back;
	border-radius: 10px;
	box-shadow: 1px 1px 5px $gray;
	.form-item {
		margin: 1rem 0;
	}
	.form-number {
		margin-bottom: 0;
		font-size: 2.3em;
		color: #e5b211;
		text-align: center;
		line-height: 1.25rem;
	}
	.send-line {
		margin-top: 6px; 
	}
	.webform-component--website-url {
		display: none;
	}
	.form-actions {
		float: right;
		.webform-submit {
			border: none;
			background-color: transparent;
			color: $red-font;
		}
	}
	.form-options  {
		color: $recycled-text;
	}
}

thead {
	font-size: 75%;
}

tbody {
	font-size: 75%;
}

.wooden-box,
.recycled-box {
	margin: 0 auto;
	.why-text {
		width: 100%;
		h1 {
			color: $blue-font;
		}
		h2 {
			color: $red-font;
		}
		img {
			display: none;
		}
	}	
	.why-photos {
		width: 160px;
		margin: 0 auto;	
		img {
			border-radius: 50%;
			max-width: 150px;
		}	
	}
}
.wooden-box {
	.why-photos {
		img {
			border-radius: 50%;
		}
	}
}


/*---------------------------------- Tablet Stlyes -----------------------------*/

@include breakpoint ($tab) {
	#block-views-outdoor-living-banner-block,
	#block-views-structure-banner-block {
		width: 50vw;
		float: right;
		min-height: 317px;
		max-width: 50%;
		overflow: hidden;
		/*.view-outdoor-living-banner*/ .field-content img {
			float: right;
			max-width: 50vw;
			min-width: 736px;
			margin-top: -65px;
		}
	}
	
	#block-block-4,
	#block-block-5,
	#block-block-9,
	#block-block-10,
	#block-block-11,
	#block-block-19,
	#block-block-20 {
		width: 50vw;
		max-width: 50%;
		height: 317px;
		float: left;
		overflow: hidden;
		h1 {
			font-size: 3.5em;
		}
	}

	.product-block {
		display: flex;
		position: relative;
		h1 {
			font-size: 3.5em;
		}
	}
	
	.recycled {
		width: 39vw;
		float: left;
		.outdoor-icon {
			width: 245px;
		}
	}
	
	.wooden {
		width: 39vw;
		float: right;
		.outdoor-icon {
			width: 245px;
		}
	}
	
	.body {
		clear: both;
	}
	.seating,
	.tables,
	.seating-w,
	.tables-w 
	.fabric {
		width: 80vw;
		clear: both;
	}
	.view-accessories,
	.view-casual-seating,
	.view.view-accessories.view-id-accessories.view-display-id-page {
		.views-row {
			margin: 2em 1em;
			border-radius: 10px;
			width: 283px;
			height: 260px;
		}
	}
	
	.view.view-accessories.view-id-accessories.view-display-id-page {
		.views-field-field-image {
			float: none;
		}
	}
	
	.field-name-field-product-name {
		font-family: $museo;
		font-size: 2.7em;
		font-weight: bold;
		line-height: 1.2;
	}
	.field-name-field-price,
	.field-name-field-price2 {
		font-family: $museo;
		font-size: 2em;
		font-weight: bold;

	}

	.node-products,
	.node-accessories {
		margin: 20px auto 0;
		max-width: 50%;
		min-width: 544px;
	}
	
	.wooden-box,
	.recycled-box {
		margin: 0 auto;
		.why-text {
			float: left;
			clear: left;
			width: 70%;
			img {
				display: block;
				width: 20%;
				float: left;
				margin-top: 2em;
			}
			ul {
				float: right;
				width: 78%;
				padding: 0 3em;
			}
		}	
		.why-photos {
			float: right;
			margin-top: 5em;
			width: 180px;
			
		}
	}
}

/*-----------------------------------Tablet Landscape Styles ---------------------------------*/
@include breakpoint ($tab-l) {

	#block-block-4, 
	#block-block-5,
	#block-block-9,
	#block-block-10,
	#block-block-11,
	#block-block-19,
	#block-block-20 {
		h1 {
			font-size: 4em;
			line-height: 3.7rem;
		}
		.contact-info {
			

			h3 {
				line-height: 2rem;
				margin: 0;
			}
			.phone {
				font-weight: 300;
				img {
					width: 30px;
				}
			}
			.email {
				img {
				 	width: 50px;
				 }
			}
			a {
				font-weight: 300;
			}
		}
	}
		

	
	.recycled {
		position: relative;
		width: 39vw;
		.view-products {
			position: absolute;
			bottom: 19px;
			left: 32px;			
		}
	}
	
	.wooden {
		position: relative;
		width: 39vw;
		.view-products {
			position: absolute;
			bottom: 19px;
			left: 32px;
		}
	}
	.seating,
	.tables,
	.seating-w,
	.tables-w, {
		clear: both;
		margin-top: 2em;
		.text {
			width: 36%;
			float: left;
			
			p:last-child {
				text-align: right;
				margin-right: 24px;
			}	
		}
	}
	.seating {
		.seating-photo{
			min-height: 350px;
			border-radius: 0px 10px 10px 0px;
		}
	}
	.seating-w {
		.seating-photo{
			min-height: 320px;
			border-radius: 0px 10px 10px 0px;
			background-position: center bottom;
		}
	}	
	.tables {
		.tables-photo {
			min-height: 350px;
			border-radius: 0px 10px 10px 0px;
		}
	}
	.tables-w {
		.tables-photo {
			min-height: 320px;
			border-radius: 0px 10px 10px 0px;
			background-position: center bottom;
		}
	}	
	.wooden-box,
	.recycled-box {
		margin: 0 auto;
		.why-text {
			float: left;
			clear: left;
			width: 78%;
			img {
				width: 98px;
				float: left;
				margin-top: 2em;
			}
			ul {
				float: left;
				width: 78%;
			}
		}	
		.why-photos {
			float: right;
			margin-top: 5em;
		}
	}
	#block-block-7,
	#block-block-21,
	#block-block-22,
	#block-block-23,
	#block-block-24,
	#block-block-25,
	#block-block-26,
	#block-block-27,
	#block-block-28, {
		float: right;
		a {
			display: flex;
			align-items: center;
			width: 323px;
			flex-wrap: wrap;
			color: $recycled-text;
		}
		img {
			width: 45px;
			margin: 0 18px;
		}
	}	
	
	.node-products,
	.node-accessories {
		display: inline-block;
		
	}
	
	#block-panels-mini-product-information-form {
		width: 10px;
		float: right;
		clear: right;
		#mini-panel-product_information_form {
			float: right;
		}
}
	
#block-views-casual-seating-block-2,
#block-views-casual-seating-block-3,
#block-views-accessories-block-2 {
	float: left;
	}
	.node-type-products,
	.node-type-accessories {
		.layout-3col__full {
	    	width: 90%;
		}
	}
}

/*------------------------------ Desktop Styles --------------------*/

@include breakpoint ($desk) {
	.product-block {		
		h1 {
			line-height: 1;
		}
		.description {
			float: left;
			width: 50%;
			margin-bottom:20px;
		}
		.outdoor-icon {
			float: right;
			width: 213px;
			margin-right: -38px;
		}
		.view-products {
			clear: both;
			position: absolute;
			bottom: 42px;
		}
	}	
	.product-block {		
		.description {
			float: left;
			width: 50%;

		}
		.outdoor-icon {
			float: right;
			width: 239px;
			margin-right: -31px;
		}
		.wooden {
			.outdoor-icon {

			}
		}
		.view-products {
			clear: both;
			position: absolute;
			bottom: 19px;
		}
	}
	.seating {
		.seating-photo{
			min-height: 359px;
			@include breakpoint (1312px) {
				min-height: 300px;	
			}
		}
	}
	.tables {
		.tables-photo {
			min-height: 359px;
			@include breakpoint (1312px) {
				min-height: 300px;	
			}			
		}
	}
	.seating-w {
		.seating-photo {
			@include breakpoint (1312px) {
				min-height: 270px;	
				background-position: center;
			}			
		}
	}
	.tables-w {
		.tables-photo {
			@include breakpoint (1312px) {
				min-height: 270px;
				background-position: center;	
			}			
		}
	}
	.node-products {
		//max-width: 564px;
		//margin-left: 15px;
	}
	.view-casual-seating,
	.view-accessories {
		.views-row {
			width: 335px;
			height: 310px;
		}
		.views-field-field-thumbnail,
		.views-field-field-image {
			img {
				max-height: 230px;
			}
		}
	}		
}
	


/*------------------------------ A breakpoint for wider screens --------------------*/
@include breakpoint (1539px) {
	.product-block {		
		.description {
			float: left;
			width: 52%;

		}
		.outdoor-icon {
			float: right;
			width: 255px;
			margin-right: -2px;
			margin-top: -10px;
			margin-bottom: 0px;
		}
		.view-products {
			clear: both;
			position: absolute;
			bottom: 12px;
		}
	}
	#block-block-7,
	#block-block-21,
	#block-block-22,
	#block-block-23,
	#block-block-24,
	#block-block-25,
	#block-block-26,
	#block-block-27,
	#block-block-28, {
		//margin-right: 10vw;
	}	

}