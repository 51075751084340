@import 'init';

//Variables
$yellow: #f7cf57;
$gray: #aaaaaa;
$aqua: #92c6ce;
$menu-tan: #fcf3d9;
$menu-yellow: #f3d476;
$yellow2: #f3d476;
$dark-gray: #707070;
$blue-font: #1d7db3;
$red-font: #9e0b0f;
$recycled-text: #536a27;
$recycled-back: #ebf3dc;
$museo: "museo-sans";
$gio: "gioviale";
$mr-eaves-mod: "mr-eaves-xl-modern";
$mr-eaves-sans: "mr-eaves-xl-sans-narrow";
$slab: "museo-slab";
$tab: 770px;
$tab-l: 1024px;
$desk: 1240px;

#block-block-29, 
#block-block-30,
#block-block-31,
#block-block-44,
#block-block-45 {
	background-color: $aqua;
	color: #fff;
	text-align: right;
	height: 296px;
	padding-top: 2em;
	h1 {
		text-transform: uppercase;
		margin-right: 20px;
		font-family: $museo;
		font-weight: 100;
		font-size: 2.5em;
		margin-top: 0;
		letter-spacing: 3px;
		span {
			font-weight: 900;
		}
	}
	h2 {
		font-family: $museo;
		color: #fff;
		font-size: 2em;
		font-weight: 100;
		margin-right: 30px;
		span {
			font-weight: 100;
		}
	}	
	.contact-info {
		color: #fff;
		text-align: right;
		margin-right: 30px;
		padding-top: 20px;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		h3 {
			line-height: 2rem;
			margin: 0;
		}
		.phone {
			font-weight: 300;
			img {
				width: 30px;
			}
		}
		.email {
			padding-left: 20px;
			img {
			 	width: 50px;
			 }
		}
		a {
			color: #fff;
			font-weight: 300;
		}
	}
}

#block-block-43 {
	h2{
		text-align: center;
		color: $recycled-text;
	}
	text-align: left;
	clear: both;
	max-width: 300px;
	margin: 0 auto;
	background-color: $recycled-back;
	border-radius: 10px;
	color: $recycled-text;
	padding: 1px 35px;
	a {
		color: $recycled-text;
	}
}

.page-node-46 {
	.product-block {
		h1 {
			font-size: 3em;
		}
		.outdoor-icon {
			max-width: 400px;
			min-width: 220px;
			width: 60%;
		}
		.wooden {
			img{
				transform: scaleX(-1);	
			}
		}
	}
}

/*----------------------------Tablet Styles----------------------------*/
@include breakpoint ($tab) { 
	#block-block-29,
	#block-block-30,
	#block-block-31,
	#block-block-44,
	#block-block-45 {
		width: 50vw;
		max-width: 50%;
		overflow: hidden;		
		float: left;
		height: 317px;
		h1 {
			font-size: 3.5em;
			margin-right: 30px;
		}
	}
	.node-46 {
		.recycled {
			.description {
				float: none;
				width: 100%;
				min-height: 111px;	
			}
			.outdoor-icon {
				margin: 0 auto;
				float: none;
				width: 85%;
			}
		}
		.wooden {
			.description {
				float: none;
				width: 100%;
				min-height: 119px;			
			}			
			.outdoor-icon {
				margin: 0 auto;
				float: none;				
				
			}
		}
	}	
	
}


/*--------------------------Landscape Tablet Styles-------------------*/

@include breakpoint ($tab-l) {
	#block-block-29, 
	#block-block-30,
	#block-block-31,
	#block-block-44,
	#block-block-45 {
		h1 {
			font-size: 4em;
			line-height: 3.7rem;
		}
		.contact-info {

		}
	}	
	#block-block-43 {
		float: none;
		margin: 0 auto;
	}				
}

/*---------------------------Desktop Styles-------------------------------*/
@include breakpoint ($desk) {
	.page-node-164 {
		.narrow {
			max-width: 1200px;
		}
	}
	.node-46 {
		.product-block {
			h1 {
				font-size: 3.5em;
			}
		}
		.recycled {
			.description {
				float: left;
				width: 45%;	
			}
			.outdoor-icon {
				width: 45%;
				float: right;
				max-width: 251px;		
			}
		}
		.wooden {
			.description {
				float: left;
				width: 45%;	
			}			
			.outdoor-icon {
				width: 39%;
				float: right;				
				
			}
		}
	}	
}



/*----------------------------shed selector styles -----------------*/
@mixin image-border($width, $color, $shadow){
	border: $width solid $color;


}
.page-node-151 #all-content{
	text-align: left;
	padding: 0 2%;
	#shedContainer{
		position: relative;
		width: 100%;
		margin: 0 auto;
		padding-bottom: 72%;
		height: 0;
		overflow: hidden;
		.shed-image{
			position:absolute;
		    width:100%;
		    height:100%;
		    img{
			    width: 95%;
			    margin: 10px;
			    //@include image-border(5px, #fff, #000);
		    }
		}
	}
	.sub-info{
		width: 90%;
		margin: 0 auto;
		#optionLinks {
			a {
				color: $red-font;
			}
			p {
				margin: .5rem;
			}
		}
	}
	#a1,
	#a2,
	#a3,
	#a4{
		p{
			display: inline-flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			width: 10%;
			max-width: 85px;
			min-width: 77px;
			margin: .5em;
			margin: 0 22px;
			img{
				//@include image-border(2px, #fff, #000);
			}
		}
	}
}

@include breakpoint($tab){
	.page-node-151 #all-content{
		#shedContainer{
			position: relative;
			position:relative;
			width:80%;
			margin: 0 auto;
			padding-bottom: 53%;
			height:0;
			overflow:hidden;
		}
		#a1,
		#a2,
		#a3,
		#a4 {
			p {
				text-align: center;
			}
		}
	}
}

@include breakpoint($desk){
	.page-node-151 #all-content{
		#shedContainer{
			position: relative;
			position:relative;
			width:60%;
			margin: 0 auto;
			padding-bottom:43.2%;
			height:0;
			overflow:hidden;
		}
		#a1,
		#a2,
		#a3,
		#a4{
			p{
				//width: 16%;
			}
		}
	}
}