// @file
// The major stylesheet for this theme.
//
// We categorize our components by creating headings in this file. See the
// description on the style guide home page for more information.


// Colors and Sass
//
// Documentation for colors and Sass mixins and variables.
//
// Weight: -1
//
// Style guide: sass
@import 'init';

// Defaults
//
// These are the default base styles applied to HTML elements.
//
// Component classes can override these styles, but if no class applies a style
// to an HTML element, these styles will be the ones displayed.
//
// Style guide: base

// Ensure fonts get loaded first to minimize front-end performance impact.
@import 'base/fonts';
@import 'base/normalize';

// LDB ~ I styled the header and banner in the styles.scss (see below). Then I styled the pages in custom files.
//see the paths immediately following this comment.
@import 'custom/home';
@import 'custom/outdoor-pages';
@import 'custom/playset-pages';
@import 'custom/structures-pages';
@import 'custom/displays-pages';
@import 'custom/misc-pages';

// Layouts
//
// The layout styling for major parts of the page that are included with the
// theme. Note: some Panels layouts are included in other parts of the system
// and are not documented.
//
// Style guide: layouts
@import 'layouts/layout-3col/layout-3col';
@import 'layouts/layout-center/layout-center';
@import 'layouts/layout-swap/layout-swap';

// Components
//
// Design components are reusable designs that can be applied using just the CSS
// class names specified in the component.
//
// Weight: 1
//
// Style guide: components

@import 'components/box/box';
@import 'components/clearfix/clearfix';
@import 'components/comment/comment';
@import 'components/divider/divider';
@import 'components/header/header';
@import 'components/hidden/hidden';
@import 'components/highlight-mark/highlight-mark';
@import 'components/inline-links/inline-links';
@import 'components/inline-sibling/inline-sibling';
@import 'components/messages/messages';
@import 'components/print-none/print-none';
@import 'components/responsive-video/responsive-video';
@import 'components/visually-hidden/visually-hidden';
@import 'components/watermark/watermark';
@import 'components/wireframe/wireframe';

// Navigation
//
// Navigation components are specialized design components that are used for
// page navigation.
//
// Weight: 2
//
// Style guide: navigation

@import 'navigation/breadcrumb/breadcrumb';
@import 'navigation/more-link/more-link';
@import 'navigation/nav-menu/nav-menu';
@import 'navigation/navbar/navbar';
@import 'navigation/pager/pager';
@import 'navigation/skip-link/skip-link';
@import 'navigation/tabs/tabs';

// Forms
//
// Form components are specialized design components that are applied to forms
// or form elements.
//
// Weight: 3
//
// Style guide: forms

@import 'forms/autocomplete/autocomplete';
@import 'forms/button/button';
@import 'forms/collapsible-fieldset/collapsible-fieldset';
@import 'forms/form-item/form-item';
@import 'forms/form-table/form-table';
@import 'forms/progress-bar/progress-bar';
@import 'forms/progress-throbber/progress-throbber';
@import 'forms/resizable-textarea/resizable-textarea';
@import 'forms/table-drag/table-drag';
@import 'hamburgers';



//Variables
$yellow: #f7cf57;
$gray: #aaaaaa;
$aqua: #92c6ce;
$menu-tan: #fcf3d9;
$menu-yellow: #f3d476;
$yellow2: #f3d476;
$dark-gray: #707070;
$blue-font: #1d7db3;
$red-font: #9e0b0f;
$museo: "museo-sans";
$gio: "gioviale";
$sign: "house-script";
$mr-eaves-mod: "mr-eaves-xl-modern";
$mr-eaves-sans: "mr-eaves-xl-sans-narrow";
$slab: "museo-slab";
$tab: 770px;
$tab-l: 1024px;
$desk: 1240px;

/*---------------------- Universal, Header, Menu and Footer stlying in the this file ---------------*/


div.contextual-links-wrapper {
	z-index: 500;
}
.pager {
	display: none;
}
strong {
	font-weight: bold;
}

p {
	font-family: $museo;
	font-weight: 300;
}

.feed-icon {
	display: none;
}

#mmenu_right {
	background-color: #fcf3d9;
	color: $red-font;
	.icon-list2 {
		display: none;
	}
	
}

.mm-list > li > a, .mm-list > li > span {
	padding: 0;
}

.mm-menu .mm-list > li > a.mm-subclose {
	background: transparent;
	color: $red-font; 
	padding-bottom: 10px;
}

.mm-list a.mm-subclose:before {
	content: '';
	display: inline-block;
	border-top: 2px solid $red-font !important;
	border-left: 2px solid $red-font !important;
	color: $red-font;
	margin-bottom: 0px;
	font-weight: bold;	
	bottom: 41%;	
}

.mmenu-nav.mm-basic > .mm-header{
	color: $blue-font;
	background: $menu-tan !important;
	border-color: transparent;
	font-style: italic;	
	font-size: 1.35em;	
	font-weight: bold;
}

.mmenu-nav.mm-basic > .mm-footer {
	color: $blue-font;
	background-color: $menu-tan;
	font-style: italic;
	font-weight: bold;
}

.mmenu-block-wrap {
	border-bottom: none;
}

.mm-footer {
	border-top: none;
	font-weight: bold;
}

.mmenu-nav.mm-basic {
	text-shadow: none;
	text-transform: uppercase;
	.mm-subopen {
		border-left: 2px solid $menu-yellow;
	}
	.mm-subopen:after, 
	.mmenu-nav.mm-basic .mm-subclose:after {
		border-color: $red-font !important;
	}
	
}

.mmenu-nav.mm-basic > .mm-list li {
	border-bottom: 2px solid $menu-yellow;
	border-top: none;
}

.mmenu-nav.mm-basic > .mm-list li:first-child {
	border-top: solid 2px $menu-yellow;
}

a {
	text-decoration: none;
}

.layout-center {
	padding: 0;
	max-width: 100vw;
}
.page-node-1 {
	.layout-3col__full {
		padding-right: 0px;
		@include breakpoint (555px) {
			margin-top: -38px;
		}
		@include breakpoint ($tab-l) {
			margin-top: 0px;
		}
	}
}

.layout-3col__full {
	padding-right: 0px;
	@include breakpoint (555px) {
		margin-top: -48px;
	}
	@include breakpoint ($tab-l) {
		margin-top: -40px;
	}
}
.layout-3col {
	margin-right: 0px;
}

.breadcrumb {
	font-weight: 100;
	padding: 10px 20px 0px;
	margin-left: 7vw;
	color: #536a27;
	clear: both;
}

.narrow {
	margin-left: auto;
	margin-right: auto;
	width: 90vw;
}

.body {
	margin: 0 auto;
}

#block-block-1 {
	margin-top: -24px;
	position: relative;
	z-index: 5;
}

#right-mmenu-trigger {
	float: right;
	z-index: 5;
	margin-right: 1.5em;
	margin-top: -30px;
	.hamburger {
		
	}
	.hamburger-box {
		width: 56px !important;
		height: 43px !important;
		background-color: $menu-tan;
		padding-left: 8px;
		border-radius: 8px;
		padding-bottom: 48px;
		z-index: 5;
		.hamburger-inner,
		.hamburger-inner:before,
		.hamburger-inner:after {
			background-color: $menu-yellow;
			z-index: 12;
		}

	}
}

#block-block-2 {
	background-image: url(/sites/default/files/families-images/header-background.jpg);
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100vw;
	border-bottom: 4px solid $yellow;
	max-height: 230px;
	z-index: 4;
	img {
		max-width: 368px;
		margin-top: -9px;
		width: 57vw;
	}
	p {
		margin: 0;
	}
}

#block-superfish-1 {
	background-color: $menu-tan;
	border-top: 2px solid $yellow;
	border-bottom: 2px solid $yellow;
	height: 3em;
	width: 100vw;
	display: none;
	.sf-sub-indicator {
		color: $red-font;
		position: relative;
		margin: -19px auto 0 auto;
		right: 0;
	}
	.menu {
		width: 1140px;
		margin: 13px auto;
	}
	li {
		list-style: none;
		a {
			color: $red-font;
			text-transform: uppercase;
			padding: 0 1em;
			font-size: 20px;
			font-family: $mr-eaves-sans;
			font-weight: bold;
		}		
	}
	.sf-depth-2 {
		background-color: $menu-yellow;
		a {
			border-top: solid 2px $menu-tan;
			padding: 20px 8px;
		}
	}
	.sf-menu.sf-horizontal.sf-shadow ul, .sf-menu.sf-vertical.sf-shadow ul, .sf-menu.sf-navbar.sf-shadow ul ul {
		margin-top: -10px;
	}
}
.block-easy-breadcrumb {
	margin-left: 10vw;
	font-weight: 100;
	font-size: 14px;
	font-family: $museo;
	color: $gray;
	clear: both;
	z-index: 15;
}
.page-node-1 {
	.block-easy-breadcrumb {
		display: none;
	}
}
#menu-765-1 {
	a {
		font-family: $sign !important;
		font-weight: 400 !important;
		padding-top: 2px !important;
	}
}
li.mmenu-mm-list-mlid-765 {
	a {
		font-family: $sign !important;
		font-weight: 400 !important;	
		font-size: 18px !important;
	}
}

/*------------------------Webform Styles-----------------------------*/



/*------------------------ Random Sayings Styles -------------------*/
#block-views-random-sayings-block {
    width: 100vw;
    clear: both;
    padding-top: 3em;
}

.views-field-field-saying {
	width: 100vw;
	text-align: center;
	font-family: $gio;
	color: $red-font;
	font-size: 2em;
	padding: 0 1em;
}


/*--------------------Footer-----------------------------------*/
.slogan-bar {
	display: none;
	margin-top: 3em;
	@include breakpoint ($desk) {
		display: block;
		background-color: $yellow2;
		color: #fff;
		font-family: $museo;
		font-style: italic;
		height: 90px;
		padding-top: 1.5em;
		text-align: center;
		h2 {
			border: 2px solid #fff;
			margin: 0 auto;
			max-width: 1210px;
			width: 100vw;		
		}
	}
}

#block-panels-mini-footer-image {
	background-image: url(/sites/default/files/families-images/footer-main-image.jpg);
	background-position: left bottom;
	background-repeat: repeat-x;
	background-size: cover;
	height: 1175px;
	margin-top: 2em;
	@include breakpoint (486px){
		height: 795px;
	}
	h1 {
		font-size: 1.7rem;
	}
	h2 {
		font-size: 1.25rem;
	}
	p {
		margin: .5rem 0;
	}
	.panel-2col .panel-col-first,
	.panel-2col .panel-col-last {
		width: 100%;
		float: none;
		margin: auto;
	}
	.panel-2col .panel-col-first {
		max-width: 687px;
		
	}
	.panel-2col .panel-col-last {
		max-width: 366px;
		min-width: 300px;
	}	
	.fb_iframe_widget {
		height: 405px !important;
	}
	span {
		height: 405px !important;
	}
	iframe {
		height: 405px !important;
		width: 310px !important;
		@include breakpoint (350px) {
			width: 340px !important;
		}
	}
}



#block-panels-mini-footer-image {
	text-align: center;
		h1 {
		color: $blue-font;
		font-weight: 700;
		font-family: $museo;
		padding-top: 1.5em;
		margin-top: 0;
		margin-bottom: 0px;
		line-height: 2rem;
		text-transform: uppercase; 
	}
	.left, 
	.right {
		font-weight: 300;
		color: $red-font;
		padding: 0 7px;
		a {
			color: $red-font;
			font-weight: 700;
		}
		h2 {
			font-family: $museo;
			text-transform: uppercase;
		}
		.phone,
		.directions {
			font-weight: 700;
		}
		.address-line {
			display: none;
		}
	}
	.links {
		a {
			text-transform: uppercase;
			color: $red-font;
			font-size: 1.3rem;
			font-weight: 600;
		}
	}	
}
#block-block-3 {
	.footer {
		background-color: $dark-gray;
		color: #fff;
		height: 68px;
		padding-top: 13px;
		text-align: center;
		@include breakpoint (424px) {
			height: 53px;
		}
		p {
			margin: 0;
		}
		a {
			color: #fff;
		}
	}
}

/*---------------------------------------- Tablet styles --------------------------------*/
@include breakpoint ($tab) {
	.narrow {
		width: 80vw;
	}
	#block-block-2 {
		text-align: center;
		img {
			margin-top: -21px;
		}
	}
}


@include breakpoint (1024px) {

	#block-panels-mini-footer-image {
		text-align: left;
		height: 602px;
		background-size: contain;
		.addresses {
			float: left;
			margin-left: 4vw;
			.left {
				float: left;
				width: 350px
			}
			.right {
				float: right;
				width: 247px;
			}
		}
		.links {
			float: right;
			margin-right: 4vw;
			line-height: 3;
		}
		.left, 
		.right {
			.address-line, 
			.directions {
				display: block;
			}
		}
		.panel-2col .panel-col-first {
			float: left;
			width: 64%;
			max-width: 710px;
		}
		.panel-2col .panel-col-last {
			float: right;
			width: 30%;
			margin-top: 2em;
			margin-right: 5%;
		}
		
	}	
}
	
/*----------------------------------------- Tablet Landscape Styles --------------------------------*/
@include breakpoint ($tab-l) {
	.narrow {
		width: 80vw;
	}
	.footer-background {
		height: 418px;
		background-size: contain;
	}

	#block-panels-mini-footer-image {
		text-align: left;
		.addresses {
			float: left;
			margin-left: 4vw;
			.left {
				float: left;
				width: 350px
			}
			.right {
				float: right;
				width: 247px;
			}
		}
		.links {
			float: right;
			margin-right: 8vw;
			line-height: 3;
		}
		.left, 
		.right {
			.address-line, 
			.directions {
				display: block;
			}
		}
	}

	#block-block-2 {
		border-bottom: none;
	}			
}

@include breakpoint (1145px) {
	#block-block-1 {
		display: none;
	}	
	#block-superfish-1 {
		display: block;
	}
}

@include breakpoint ($desk) {
	#block-panels-mini-footer-image {
		margin-top: 0px;
		height: 477px;
	}
	.panel-2col .panel-col-first {
		margin-left: 8% !important;
	}
	.panel-2col .panel-col-last {
		margin-right: 8%;
	}	
}

@include breakpoint (1600px) {
	#block-panels-mini-footer-image {
		.panel-2col .panel-col-first {
			width: 70%; 
		}
	}
}
