@import 'init';

//Variables
$yellow: #f7cf57;
$gray: #aaaaaa;
$light-gray: #eaeaea;
$aqua: #92c6ce;
$menu-tan: #fcf3d9;
$yellow2: #f3d476;
$dark-gray: #707070;
$blue-font: #1d7db3;
$red-font: #9e0b0f;
$museo: "museo-sans";
$gio: "gioviale";
$mr-eaves-mod: "mr-eaves-xl-modern";
$mr-eaves-sans: "mr-eaves-xl-sans-narrow";
$slab: "museo-slab";
$tab: 770px;
$tab-l: 1024px;
$desk: 1240px;

/*-----------------------------------Landing Pages Styling ------------------------*/

.node-7 {
	.product-block .outdoor-icon {
		max-width: 400px;
		min-width: 214px;
		width: 95%;
	}
	.holder,
	.holder-2 {
		display: none;
	}		
}

#block-block-12,
#block-block-13,
#block-block-14,
#block-block-15,
#block-block-16,
#block-block-17,
#block-block-18 {
	background-color: $gray;
	color: #fff;
	text-align: right;
	height: 275px;

	h1 {
		text-transform: uppercase;
		margin-right: 30px;
		font-family: $museo;
		font-weight: 100;
		font-size: 3em;
		margin-top: 0;
		padding-top: .5em;
		letter-spacing: 3px;
		span {
			font-weight: 900;
		}
	}
	h2 {
		font-family: $museo;
		color: #fff;
		font-size: 2em;
		font-weight: 100;
		margin-right: 30px;
		span {
			font-weight: 100;
		}
	}	
	.contact-info {
		color: #fff;
		text-align: right;
		margin-right: 30px;
		padding-top: 20px;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;		
		h3 {
			line-height: 2rem;
			margin: 0;
		}
		.phone {
			font-weight: 300;
			img {
				width: 30px;
			}
		}
		.email {
			padding-left: 20px;
			img {
			 	width: 50px;
			 }
		}
		a {
			color: #fff;
			font-weight: 300;
		}
	}
}

.commercial-playset {
	background-image: url(/sites/default/files/families-images/recycled-commercial.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 250px;
	overflow: hidden;
	border-radius: 0px 0px 10px 10px;
	display: block;
}

.residential-playset {
	background-image: url(/sites/default/files/families-images/recycled-residential-playset.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 250px;
	overflow: hidden;
	border-radius: 0px 0px 10px 10px;
	display: block;	
}

.deluxe-playset {
	background-image: url(/sites/default/files/families-images/deluxe-wooden-playset.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 250px;
	overflow: hidden;
	border-radius: 0px 0px 10px 10px;	
	display: block;	
}

.economy-playset {
	background-image: url(/sites/default/files/families-images/DIY-swing.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 250px;
	overflow: hidden;
	border-radius: 0px 0px 10px 10px;
	display: block;		
}


/*-----------------------Tablet Styles---------------------------------*/
@include breakpoint ($tab) {
	#block-views-playset-banner-block {
		width: 50vw;
		float: right;
		min-height: 317px;
		max-width: 50%;
		overflow: hidden;
		.view-playset-banner {
			.field-content img {
				float: right;
				max-width: 50vw;
				min-width: 736px;
			}
		}
	}
	#block-block-12, 
	#block-block-13,
	#block-block-14,
	#block-block-15,
	#block-block-16,
	#block-block-17, 
	#block-block-18 {
		width: 50vw;
		max-width: 50%;
		overflow: hidden;		
		float: left;
		height: 317px;
		padding-top: .5em;
		h1 {
			font-size: 3.5em;
		}
	}
	.node-7 {
		.product-block { 
			.holder {
				display: block;
				@include breakpoint (1022px) {
					display: none;
				}
				@include breakpoint (1303px) {
					display: block;
				}
				@include breakpoint (1695px) {
					display: none;
				}
			}
		}	
	}	
}


/*------------------------Tablet Landscape Styles ---------------------*/

@include breakpoint ($tab-l) {
	#block-block-12, 
	#block-block-13, 
	#block-block-14,
	#block-block-15,
	#block-block-16,
	#block-block-17, 
	#block-block-18 {
		h1 {
			font-size: 4em;
			line-height: 3.7rem;
		}
		.contact-info {

		}
	}	
	.seating,
	.seating-w {
		.commercial-playset,
		.deluxe-playset {
			min-height: 308px;
			border-radius: 0px 10px 10px 0px;
		}
	}
	.tables,
	.tables-w {
		.residential-playset,
		.economy-playset {
			min-height: 308px;
			border-radius: 0px 10px 10px 0px;
		}
	}
	.node-7 {	
		.product-block {
			.recycled {
				.outdoor-icon {
				 	float: none;
				 	margin: 0 auto 47px;
				}
			} 
			.description {
				float: none;
				width: 100%;
			}
			.wooden {
				.outdoor-icon {
				 	float: none;
				 	margin: 0px auto 47px;
				}
			}	
		}	
	}

	#block-block-32 {
		h1 {
			font-size: 4em;
		}

	}	
	.seating {
		.commercial-playset,
		.deluxe-playset {
			min-height: 430px;
			border-radius: 0px 10px 10px 0px;
		}
	}
	.tables {
		.residential-playset,
		.economy-playset {
			min-height: 430px;
			border-radius: 0px 10px 10px 0px;
		}
	}	
}

/*-----------------------------Desktop Styles----------------------------------*/
@include breakpoint ($desk) {
	.seating {
		.commercial-playset,
		.deluxe-playset {
			min-height: 375px;
			border-radius: 0px 10px 10px 0px;
		}
	}
	.tables {
		.residential-playset,
		.economy-playset {
			min-height: 375px;
			border-radius: 0px 10px 10px 0px;
		}
	}
}

@include breakpoint (1700px) {
	.holder-2 {
		display: none;
	}
	.seating {
		.commercial-playset,
		.deluxe-playset {
			min-height: 315px;
			border-radius: 0px 10px 10px 0px;
		}
	}
	.tables {
		.residential-playset,
		.economy-playset {
			min-height: 315px;
			border-radius: 0px 10px 10px 0px;
		}
	}
}
