* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #003353;
}

a:active {
  color: #c00;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1,
.header__site-name {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

/*------------Styles --------------*/
h1 {
  font-family: "museo-slab";
}

h2 {
  font-family: "museo-slab";
}

h3 {
  font-family: "mr-eaves-xl-modern";
}

.heading {
  text-align: center;
}

.slogan {
  font-weight: 700;
  color: #1d7db3;
  font-size: 1.6em;
  line-height: 1;
  margin: 0;
}

.items {
  font-weight: 100;
  color: #1d7db3;
  font-size: .92em;
  line-height: 1;
  margin: 0;
}

.page-node-1 .contact-info {
  color: #9e0b0f;
  text-align: center;
  margin-top: 1.5em;
  display: block;
}

.page-node-1 .contact-info h3 {
  line-height: 2rem;
  margin: 0;
}

.page-node-1 .contact-info .phone {
  font-weight: 300;
}

.page-node-1 .contact-info .phone img {
  width: 30px;
}

.page-node-1 .contact-info .email img {
  width: 50px;
}

.page-node-1 .contact-info a {
  color: #9e0b0f;
  font-weight: 300;
}

#block-views-front-slideshow-block {
  max-height: 210px;
  min-height: 150px;
  overflow: hidden;
}

#block-views-front-slideshow-block .view-front-slideshow {
  width: 100%;
  float: right;
}

#block-views-front-slideshow-block .view-front-slideshow .views-slideshow-cycle-main-frame {
  width: 100% !important;
  height: auto;
}

#block-views-front-slideshow-block .view-front-slideshow .views-slideshow-cycle-main-frame-row {
  width: 100% !important;
  height: auto;
}

#block-views-front-slideshow-block .view-front-slideshow .field-content {
  max-width: 100%;
  width: 100%;
}

#block-views-front-slideshow-block .view-front-slideshow .field-content img {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  min-width: 590px;
}

.flex-icon:nth-of-type(1) {
  order: 1;
}

.flex-icon:nth-of-type(2) {
  order: 2;
}

.flex-icon:nth-of-type(3) {
  order: 4;
}

.flex-icon:nth-of-type(4) {
  order: 3;
}

.flex-icon:nth-of-type(5) {
  order: 5;
}

.flex-icon:nth-of-type(6) {
  order: 6;
}

.product-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  flex-direction: row;
  flex-flow: row wrap;
  margin-top: 1.7em;
  margin-left: auto;
  margin-right: auto;
}

.product-icons a {
  text-align: center;
}

.product-icons a:visited {
  color: #1d7db3;
}

.circle {
  border-radius: 50%;
  width: 50%;
  padding-bottom: 50%;
  position: relative;
  text-align: center;
  display: inline-block;
}

.circle img {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.product-link {
  font-size: 1em;
  font-weight: bold;
  color: #1d7db3;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
}

#outdoor {
  width: 25vw;
  min-width: 147px;
}

#outdoor .circle {
  background-color: #f7cf57;
}

#outdoor img {
  width: 62%;
  margin: 18% auto;
}

#playsets {
  min-width: 147px;
  width: 25vw;
}

#playsets .circle {
  background-color: #aaaaaa;
}

#playsets img {
  width: 62%;
  margin: 28% auto;
}

#displays {
  min-width: 147px;
  width: 25vw;
}

#displays .circle {
  background-color: #92c6ce;
}

#displays img {
  width: 72%;
  margin: 8% auto;
}

#structures {
  min-width: 147px;
  width: 25vw;
}

#structures .circle {
  background-color: #92c6ce;
}

#structures img {
  width: 81%;
  margin: 20% auto;
}

#buyers-guide {
  min-width: 147px;
  width: 25vw;
}

#buyers-guide .circle {
  background-color: #aaaaaa;
}

#buyers-guide img {
  width: 65%;
  margin: 6% auto;
}

#reviews {
  min-width: 147px;
  width: 25vw;
}

#reviews .circle {
  background-color: #f7cf57;
}

#reviews img {
  width: 62%;
  margin: 18% 2% 0% 24%;
}

.body {
  font-family: "museo-sans";
}

.body h2 {
  font-weight: 700;
  color: #1d7db3;
  font-family: "museo-sans";
  font-size: 1.3em;
}

.body p {
  font-weight: 300;
  color: #aaaaaa;
}

.body p span {
  color: #9e0b0f;
  font-style: italic;
  font-weight: 700;
}

.body a {
  color: #707070;
  font-weight: bold;
}

.social-media {
  clear: both;
  font-family: "museo-sans";
  color: #9e0b0f;
  margin: 0 auto;
  width: 86vw;
  text-align: center;
}

.social-media p {
  margin: 13px;
}

.social-media .gray-box {
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 55vw;
  margin: 0 auto;
  min-width: 233px;
  border-radius: 10px;
}

.social-media .gray-box a {
  text-transform: uppercase;
  width: 210px;
  font-family: "museo-sans";
  color: #9e0b0f;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 1em;
}

.social-media .gray-box a img {
  width: 50px;
  margin: 1em;
}

.social-media .gray-box a:first-child {
  color: #3b5998;
  padding-left: 0;
}

/*------------------------------------ Tablet Styles ----------------------------*/
@media (min-width: 800px) {
  #block-views-front-slideshow-block {
    margin-top: 0px;
  }
  .flex-heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .heading {
    text-align: left;
    float: left;
  }
  .heading .slogan {
    font-size: 1;
  }
  .heading .items {
    font-size: 1.25em;
  }
  .page-node-1 .contact-info {
    float: right;
    text-align: right;
    margin-top: 0;
  }
  .page-node-1 .contact-info h3 {
    font-size: 1.5rem;
    line-height: 1;
  }
  .product-icons {
    clear: both;
    padding-top: 1em;
  }
  .flex-icon:nth-of-type(1) {
    order: 1;
  }
  .flex-icon:nth-of-type(2) {
    order: 2;
  }
  .flex-icon:nth-of-type(3) {
    order: 3;
  }
  .flex-icon:nth-of-type(4) {
    order: 4;
  }
  .flex-icon:nth-of-type(5) {
    order: 5;
  }
  .flex-icon:nth-of-type(6) {
    order: 6;
  }
  #buyers-guide .circle {
    background-color: #f7cf57;
  }
  #reviews .circle {
    background-color: #aaaaaa;
  }
  .social-media {
    clear: both;
  }
  .social-media .gray-box {
    width: 75vw;
    justify-content: space-around;
  }
}

@media (min-width: 1024px) {
  .product-link br {
    display: none;
  }
}

/*---------------------------------------Desktop Styles ------------------------------*/
@media (min-width: 1240px) {
  .narrow {
    width: 75vw;
  }
  .circle {
    width: 35%;
    padding-bottom: 35%;
  }
  .product-link {
    line-height: 1.5;
    font-size: 1.4em;
  }
  .flex-heading {
    margin-top: 0px;
  }
  .social-media {
    width: 79vw;
  }
  .social-media .gray-box {
    width: 51vw;
    justify-content: space-around;
  }
}

/*-----------------------------------Landing Pages Styling ------------------------*/
#block-views-outdoor-living-banner-block,
#block-views-playset-banner-block,
#block-views-structure-banner-block,
#block-views-structure-banner-block-1,
#block-views-structure-banner-block-2,
#block-views-structure-banner-block-3,
#block-views-structure-banner-block-4,
#block-views-structure-banner-block-5 {
  height: 240px;
  overflow: hidden;
}

#block-views-outdoor-living-banner-block .view-outdoor-living-banner,
#block-views-outdoor-living-banner-block .view-structure-banner,
#block-views-outdoor-living-banner-block .view-playset-banner,
#block-views-playset-banner-block .view-outdoor-living-banner,
#block-views-playset-banner-block .view-structure-banner,
#block-views-playset-banner-block .view-playset-banner,
#block-views-structure-banner-block .view-outdoor-living-banner,
#block-views-structure-banner-block .view-structure-banner,
#block-views-structure-banner-block .view-playset-banner,
#block-views-structure-banner-block-1 .view-outdoor-living-banner,
#block-views-structure-banner-block-1 .view-structure-banner,
#block-views-structure-banner-block-1 .view-playset-banner,
#block-views-structure-banner-block-2 .view-outdoor-living-banner,
#block-views-structure-banner-block-2 .view-structure-banner,
#block-views-structure-banner-block-2 .view-playset-banner,
#block-views-structure-banner-block-3 .view-outdoor-living-banner,
#block-views-structure-banner-block-3 .view-structure-banner,
#block-views-structure-banner-block-3 .view-playset-banner,
#block-views-structure-banner-block-4 .view-outdoor-living-banner,
#block-views-structure-banner-block-4 .view-structure-banner,
#block-views-structure-banner-block-4 .view-playset-banner,
#block-views-structure-banner-block-5 .view-outdoor-living-banner,
#block-views-structure-banner-block-5 .view-structure-banner,
#block-views-structure-banner-block-5 .view-playset-banner {
  width: 100%;
  float: right;
}

#block-views-outdoor-living-banner-block .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-outdoor-living-banner-block .view-playset-banner .views-slideshow-cycle-main-frame,
#block-views-outdoor-living-banner-block .view-structure-banner-banner .views-slideshow-cycle-main-frame,
#block-views-playset-banner-block .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-playset-banner-block .view-playset-banner .views-slideshow-cycle-main-frame,
#block-views-playset-banner-block .view-structure-banner-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block .view-playset-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block .view-structure-banner-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-1 .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-1 .view-playset-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-1 .view-structure-banner-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-2 .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-2 .view-playset-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-2 .view-structure-banner-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-3 .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-3 .view-playset-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-3 .view-structure-banner-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-4 .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-4 .view-playset-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-4 .view-structure-banner-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-5 .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-5 .view-playset-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-5 .view-structure-banner-banner .views-slideshow-cycle-main-frame {
  width: 100% !important;
  height: auto;
}

#block-views-outdoor-living-banner-block .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-outdoor-living-banner-block .view-playset-banner .views-slideshow-cycle-main-frame-row,
#block-views-outdoor-living-banner-block .view-structure-banner .views-slideshow-cycle-main-frame-row,
#block-views-playset-banner-block .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-playset-banner-block .view-playset-banner .views-slideshow-cycle-main-frame-row,
#block-views-playset-banner-block .view-structure-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block .view-playset-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block .view-structure-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-1 .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-1 .view-playset-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-1 .view-structure-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-2 .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-2 .view-playset-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-2 .view-structure-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-3 .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-3 .view-playset-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-3 .view-structure-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-4 .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-4 .view-playset-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-4 .view-structure-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-5 .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-5 .view-playset-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-5 .view-structure-banner .views-slideshow-cycle-main-frame-row {
  width: 100% !important;
  height: auto;
}

#block-views-outdoor-living-banner-block .view-outdoor-living-banner .field-content,
#block-views-outdoor-living-banner-block .view-playset-banner .field-content,
#block-views-outdoor-living-banner-block .view-structure-banner .field-content,
#block-views-playset-banner-block .view-outdoor-living-banner .field-content,
#block-views-playset-banner-block .view-playset-banner .field-content,
#block-views-playset-banner-block .view-structure-banner .field-content,
#block-views-structure-banner-block .view-outdoor-living-banner .field-content,
#block-views-structure-banner-block .view-playset-banner .field-content,
#block-views-structure-banner-block .view-structure-banner .field-content,
#block-views-structure-banner-block-1 .view-outdoor-living-banner .field-content,
#block-views-structure-banner-block-1 .view-playset-banner .field-content,
#block-views-structure-banner-block-1 .view-structure-banner .field-content,
#block-views-structure-banner-block-2 .view-outdoor-living-banner .field-content,
#block-views-structure-banner-block-2 .view-playset-banner .field-content,
#block-views-structure-banner-block-2 .view-structure-banner .field-content,
#block-views-structure-banner-block-3 .view-outdoor-living-banner .field-content,
#block-views-structure-banner-block-3 .view-playset-banner .field-content,
#block-views-structure-banner-block-3 .view-structure-banner .field-content,
#block-views-structure-banner-block-4 .view-outdoor-living-banner .field-content,
#block-views-structure-banner-block-4 .view-playset-banner .field-content,
#block-views-structure-banner-block-4 .view-structure-banner .field-content,
#block-views-structure-banner-block-5 .view-outdoor-living-banner .field-content,
#block-views-structure-banner-block-5 .view-playset-banner .field-content,
#block-views-structure-banner-block-5 .view-structure-banner .field-content {
  max-width: 100%;
  width: 100%;
}

#block-views-outdoor-living-banner-block .view-outdoor-living-banner .field-content img,
#block-views-outdoor-living-banner-block .view-playset-banner .field-content img,
#block-views-outdoor-living-banner-block .view-structure-banner .field-content img,
#block-views-playset-banner-block .view-outdoor-living-banner .field-content img,
#block-views-playset-banner-block .view-playset-banner .field-content img,
#block-views-playset-banner-block .view-structure-banner .field-content img,
#block-views-structure-banner-block .view-outdoor-living-banner .field-content img,
#block-views-structure-banner-block .view-playset-banner .field-content img,
#block-views-structure-banner-block .view-structure-banner .field-content img,
#block-views-structure-banner-block-1 .view-outdoor-living-banner .field-content img,
#block-views-structure-banner-block-1 .view-playset-banner .field-content img,
#block-views-structure-banner-block-1 .view-structure-banner .field-content img,
#block-views-structure-banner-block-2 .view-outdoor-living-banner .field-content img,
#block-views-structure-banner-block-2 .view-playset-banner .field-content img,
#block-views-structure-banner-block-2 .view-structure-banner .field-content img,
#block-views-structure-banner-block-3 .view-outdoor-living-banner .field-content img,
#block-views-structure-banner-block-3 .view-playset-banner .field-content img,
#block-views-structure-banner-block-3 .view-structure-banner .field-content img,
#block-views-structure-banner-block-4 .view-outdoor-living-banner .field-content img,
#block-views-structure-banner-block-4 .view-playset-banner .field-content img,
#block-views-structure-banner-block-4 .view-structure-banner .field-content img,
#block-views-structure-banner-block-5 .view-outdoor-living-banner .field-content img,
#block-views-structure-banner-block-5 .view-playset-banner .field-content img,
#block-views-structure-banner-block-5 .view-structure-banner .field-content img {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  min-width: 635px;
  margin-top: -19px;
}

#block-block-4,
#block-block-5,
#block-block-9,
#block-block-10,
#block-block-11,
#block-block-19,
#block-block-20 {
  background-color: #f7cf57;
  color: #fff;
  text-align: right;
  height: 289px;
}

#block-block-4 h1,
#block-block-5 h1,
#block-block-9 h1,
#block-block-10 h1,
#block-block-11 h1,
#block-block-19 h1,
#block-block-20 h1 {
  text-transform: uppercase;
  margin-right: 30px;
  font-family: "museo-sans";
  font-weight: 100;
  font-size: 3em;
  padding-top: .5em;
  margin-top: 0;
  letter-spacing: 3px;
}

#block-block-4 h1 span,
#block-block-5 h1 span,
#block-block-9 h1 span,
#block-block-10 h1 span,
#block-block-11 h1 span,
#block-block-19 h1 span,
#block-block-20 h1 span {
  font-weight: 900;
}

#block-block-4 h2,
#block-block-5 h2,
#block-block-9 h2,
#block-block-10 h2,
#block-block-11 h2,
#block-block-19 h2,
#block-block-20 h2 {
  font-family: "museo-sans";
  color: #fff;
  font-size: 2em;
  font-weight: 100;
  margin-right: 30px;
}

#block-block-4 h2 span,
#block-block-5 h2 span,
#block-block-9 h2 span,
#block-block-10 h2 span,
#block-block-11 h2 span,
#block-block-19 h2 span,
#block-block-20 h2 span {
  font-weight: 100;
}

#block-block-4 .contact-info,
#block-block-5 .contact-info,
#block-block-9 .contact-info,
#block-block-10 .contact-info,
#block-block-11 .contact-info,
#block-block-19 .contact-info,
#block-block-20 .contact-info {
  color: #fff;
  text-align: right;
  margin-right: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

#block-block-4 .contact-info h3,
#block-block-5 .contact-info h3,
#block-block-9 .contact-info h3,
#block-block-10 .contact-info h3,
#block-block-11 .contact-info h3,
#block-block-19 .contact-info h3,
#block-block-20 .contact-info h3 {
  line-height: 2rem;
  margin: 0;
}

#block-block-4 .contact-info .phone,
#block-block-5 .contact-info .phone,
#block-block-9 .contact-info .phone,
#block-block-10 .contact-info .phone,
#block-block-11 .contact-info .phone,
#block-block-19 .contact-info .phone,
#block-block-20 .contact-info .phone {
  font-weight: 300;
}

#block-block-4 .contact-info .phone img,
#block-block-5 .contact-info .phone img,
#block-block-9 .contact-info .phone img,
#block-block-10 .contact-info .phone img,
#block-block-11 .contact-info .phone img,
#block-block-19 .contact-info .phone img,
#block-block-20 .contact-info .phone img {
  width: 30px;
}

#block-block-4 .contact-info .email,
#block-block-5 .contact-info .email,
#block-block-9 .contact-info .email,
#block-block-10 .contact-info .email,
#block-block-11 .contact-info .email,
#block-block-19 .contact-info .email,
#block-block-20 .contact-info .email {
  padding-left: 20px;
}

#block-block-4 .contact-info .email img,
#block-block-5 .contact-info .email img,
#block-block-9 .contact-info .email img,
#block-block-10 .contact-info .email img,
#block-block-11 .contact-info .email img,
#block-block-19 .contact-info .email img,
#block-block-20 .contact-info .email img {
  width: 50px;
}

#block-block-4 .contact-info a,
#block-block-5 .contact-info a,
#block-block-9 .contact-info a,
#block-block-10 .contact-info a,
#block-block-11 .contact-info a,
#block-block-19 .contact-info a,
#block-block-20 .contact-info a {
  color: #fff;
  font-weight: 300;
}

.product-block h1 {
  font-family: "museo-sans";
  font-weight: 100;
  font-size: 2.5em;
  margin-bottom: 0px;
}

.product-block h1 span {
  font-weight: 900;
}

.product-block .outdoor-icon {
  width: 235px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.product-block .view-products {
  font-family: "museo-sans";
  text-transform: uppercase;
  font-weight: 900;
}

.recycled {
  width: 90vw;
  margin: 30px 0px;
  background-color: #ebf3dc;
  border-radius: 10px;
  padding: 1px 2em;
  color: #536a27;
}

.recycled a {
  color: #536a27;
}

.wooden {
  width: 90vw;
  margin: 30px auto;
  background-color: #dbd1c3;
  border-radius: 10px;
  padding: 1px 2em;
  color: #5e4d36;
}

.wooden a {
  color: #5e4d36;
}

.page-title {
  color: #536a27;
  font-family: "museo-sans";
  font-weight: 900;
  text-align: center;
  font-size: 3em;
}

.page-title span {
  font-weight: 100;
}

.text {
  padding: 0px 13px;
}

.page-title-w {
  color: #5e4d36;
  font-family: "museo-sans";
  font-weight: 900;
  text-align: center;
  font-size: 3em;
}

.page-title-w span {
  font-weight: 100;
}

.seating {
  background-color: #ebf3dc;
  width: 90vw;
  margin: 0 auto;
  border-radius: 10px;
}

.seating a {
  color: #536a27;
}

.seating h1 {
  text-align: left;
  font-family: "museo-sans";
  font-size: 2.5em;
  padding-top: 13px;
}

.seating h1 span {
  font-weight: 100;
}

.seating .text {
  color: #536a27;
}

.seating .text .view-products {
  color: #536a27;
  text-align: right;
  text-transform: uppercase;
  font-weight: 900;
}

.seating .seating-photo {
  background-image: url(/sites/default/files/families-images/recycled-chairs.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  display: block;
}

.tables {
  background-color: #ebf3dc;
  width: 90vw;
  margin: 0 auto;
  border-radius: 10px;
}

.tables a {
  color: #536a27;
}

.tables .text {
  color: #536a27;
}

.tables .text h1 {
  color: #536a27;
  font-family: "museo-sans";
  font-size: 2.5em;
  padding-top: 13px;
}

.tables .text h1 span {
  font-weight: 100;
}

.tables .text .view-products {
  color: #536a27;
  text-align: right;
  text-transform: uppercase;
  font-weight: 900;
}

.tables .tables-photo {
  background-image: url(/sites/default/files/families-images/reycled-outdoor-dining.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 250px;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  display: block;
}

.seating-w,
.tables-w {
  background-color: #dbd1c3;
  margin: 0 auto;
  border-radius: 10px;
}

.seating-w .text,
.tables-w .text {
  color: #5e4d36;
}

.seating-w .text a,
.tables-w .text a {
  color: #5e4d36;
}

.seating-w .text h1,
.tables-w .text h1 {
  color: #5e4d36;
  font-family: "museo-sans";
  font-size: 2.5em;
  padding-top: 13px;
}

.seating-w .text h1 span,
.tables-w .text h1 span {
  font-weight: 100;
}

.seating-w .text .view-products,
.tables-w .text .view-products {
  color: #5e4d36;
  text-align: right;
  text-transform: uppercase;
  font-weight: 900;
}

.seating-w .tables-photo,
.tables-w .tables-photo {
  background-image: url(/sites/default/files/families-images/wooden-outdoor-dining.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  display: block;
}

.seating-w .seating-photo,
.tables-w .seating-photo {
  background-image: url(/sites/default/files/families-images/JW-adirondack.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  display: block;
}

.view.view-accessories.view-id-accessories.view-display-id-block_1 .view-content,
.view.view-casual-seating.view-id-casual_seating.view-display-id-block_1 .view-content,
.view.view-accessories.view-id-accessories.view-display-id-page .view-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, 305px);
  justify-content: center;
}

@media (min-width: 1240px) {
  .view.view-accessories.view-id-accessories.view-display-id-block_1 .view-content,
  .view.view-casual-seating.view-id-casual_seating.view-display-id-block_1 .view-content,
  .view.view-accessories.view-id-accessories.view-display-id-page .view-content {
    grid-template-columns: repeat(auto-fill, 380px);
  }
}

.view.view-accessories.view-id-accessories.view-display-id-block_1 .view-filters,
.view.view-casual-seating.view-id-casual_seating.view-display-id-block_1 .view-filters,
.view.view-accessories.view-id-accessories.view-display-id-page .view-filters {
  width: 237px;
  margin: auto;
}

.view.view-accessories.view-id-accessories.view-display-id-block_1 .view-filters label,
.view.view-casual-seating.view-id-casual_seating.view-display-id-block_1 .view-filters label,
.view.view-accessories.view-id-accessories.view-display-id-page .view-filters label {
  color: #536a27;
  font-weight: 600;
  font-family: "museo-sans";
}

.view.view-accessories.view-id-accessories.view-display-id-block_1 .view-filters .form-submit,
.view.view-casual-seating.view-id-casual_seating.view-display-id-block_1 .view-filters .form-submit,
.view.view-accessories.view-id-accessories.view-display-id-page .view-filters .form-submit {
  margin-top: 1.4em;
  background-color: #ebf3dc;
  color: #536a27;
  border-radius: 5px;
  padding: 3px 15px;
  font-weight: 600;
  font-family: "museo-sans";
}

.view-accessories,
.view-casual-seating,
.view.view-accessories.view-id-accessories.view-display-id-page {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  min-width: 280px;
}

.view-accessories .views-row,
.view-casual-seating .views-row,
.view.view-accessories.view-id-accessories.view-display-id-page .views-row {
  width: 252px;
  height: 252px;
  margin: 2em auto;
  border-radius: 10px;
  position: relative;
}

.view-accessories .views-field-title,
.view-casual-seating .views-field-title,
.view.view-accessories.view-id-accessories.view-display-id-page .views-field-title {
  padding: 20px 0 0 20px;
  font-family: "museo-sans";
  font-weight: 700;
}

.view-accessories .views-field-title a,
.view-casual-seating .views-field-title a,
.view.view-accessories.view-id-accessories.view-display-id-page .views-field-title a {
  font-size: 1.2em;
}

.view-accessories .views-field-body,
.view-casual-seating .views-field-body,
.view.view-accessories.view-id-accessories.view-display-id-page .views-field-body {
  width: 100%;
  padding: 10px 0 10px 20px;
}

.view-accessories .views-field-body a,
.view-casual-seating .views-field-body a,
.view.view-accessories.view-id-accessories.view-display-id-page .views-field-body a {
  font-family: "museo-sans";
}

.view-accessories .views-field-field-thumbnail,
.view-casual-seating .views-field-field-thumbnail,
.view.view-accessories.view-id-accessories.view-display-id-page .views-field-field-thumbnail {
  text-align: center;
  height: 245px;
  overflow: hidden;
  padding-top: 8px;
}

.view-accessories .views-field-field-thumbnail img,
.view-casual-seating .views-field-field-thumbnail img,
.view.view-accessories.view-id-accessories.view-display-id-page .views-field-field-thumbnail img {
  width: auto;
  max-height: 177px;
}

.view-accessories .views-field-field-image,
.view-casual-seating .views-field-field-image,
.view.view-accessories.view-id-accessories.view-display-id-page .views-field-field-image {
  text-align: center;
  height: 245px;
  overflow: hidden;
  padding-top: 8px;
}

.view-accessories .views-field-field-image img,
.view-casual-seating .views-field-field-image img,
.view.view-accessories.view-id-accessories.view-display-id-page .views-field-field-image img {
  width: auto;
  max-height: 177px;
}

.view-accessories .views-field-field-field-price2,
.view-casual-seating .views-field-field-field-price2,
.view.view-accessories.view-id-accessories.view-display-id-page .views-field-field-field-price2 {
  text-align: right;
}

.view.view-accessories.view-id-accessories.view-display-id-page {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  min-width: 280px;
}

.view.view-accessories.view-id-accessories.view-display-id-page .acc-message {
  text-align: center;
  color: #9e0b0f;
}

.view.view-accessories.view-id-accessories.view-display-id-page .views-exposed-form {
  width: 330px;
  margin: 0 auto;
}

.view.view-accessories.view-id-accessories.view-display-id-page .view-empty {
  text-align: center;
}

.field-name-field-product-name {
  font-family: "museo-sans";
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1.2;
}

.field-name-field-price {
  font-family: "museo-sans";
  font-size: 1.3em;
}

.field-name-field-table {
  text-align: center;
}

.page-taxonomy-term-1 .view-casual-seating .views-row,
.page-taxonomy-term-1 .view-accessories .views-row,
.page-taxonomy-term-2 .view-casual-seating .views-row,
.page-taxonomy-term-2 .view-accessories .views-row,
.page-taxonomy-term-6 .view-casual-seating .views-row,
.page-taxonomy-term-6 .view-accessories .views-row,
.page-taxonomy-term-15 .view-casual-seating .views-row,
.page-taxonomy-term-15 .view-accessories .views-row,
.page-taxonomy-term-20 .view-casual-seating .views-row,
.page-taxonomy-term-20 .view-accessories .views-row,
.page-taxonomy-term-21 .view-casual-seating .views-row,
.page-taxonomy-term-21 .view-accessories .views-row,
.page-taxonomy-term-22 .view-casual-seating .views-row,
.page-taxonomy-term-22 .view-accessories .views-row,
.page-taxonomy-term-27 .view-casual-seating .views-row,
.page-taxonomy-term-27 .view-accessories .views-row,
.page-taxonomy-term-25 .view-casual-seating .views-row,
.page-taxonomy-term-25 .view-accessories .views-row,
.page-taxonomy-term-31 .view-casual-seating .views-row,
.page-taxonomy-term-31 .view-accessories .views-row,
.page-taxonomy-term-33 .view-casual-seating .views-row,
.page-taxonomy-term-33 .view-accessories .views-row,
.page-taxonomy-term-34 .view-casual-seating .views-row,
.page-taxonomy-term-34 .view-accessories .views-row,
.page-taxonomy-term-36 .view-casual-seating .views-row,
.page-taxonomy-term-36 .view-accessories .views-row {
  background-color: #ebf3dc;
}

.page-taxonomy-term-1 .view-casual-seating .views-field-title,
.page-taxonomy-term-1 .view-accessories .views-field-title,
.page-taxonomy-term-2 .view-casual-seating .views-field-title,
.page-taxonomy-term-2 .view-accessories .views-field-title,
.page-taxonomy-term-6 .view-casual-seating .views-field-title,
.page-taxonomy-term-6 .view-accessories .views-field-title,
.page-taxonomy-term-15 .view-casual-seating .views-field-title,
.page-taxonomy-term-15 .view-accessories .views-field-title,
.page-taxonomy-term-20 .view-casual-seating .views-field-title,
.page-taxonomy-term-20 .view-accessories .views-field-title,
.page-taxonomy-term-21 .view-casual-seating .views-field-title,
.page-taxonomy-term-21 .view-accessories .views-field-title,
.page-taxonomy-term-22 .view-casual-seating .views-field-title,
.page-taxonomy-term-22 .view-accessories .views-field-title,
.page-taxonomy-term-27 .view-casual-seating .views-field-title,
.page-taxonomy-term-27 .view-accessories .views-field-title,
.page-taxonomy-term-25 .view-casual-seating .views-field-title,
.page-taxonomy-term-25 .view-accessories .views-field-title,
.page-taxonomy-term-31 .view-casual-seating .views-field-title,
.page-taxonomy-term-31 .view-accessories .views-field-title,
.page-taxonomy-term-33 .view-casual-seating .views-field-title,
.page-taxonomy-term-33 .view-accessories .views-field-title,
.page-taxonomy-term-34 .view-casual-seating .views-field-title,
.page-taxonomy-term-34 .view-accessories .views-field-title,
.page-taxonomy-term-36 .view-casual-seating .views-field-title,
.page-taxonomy-term-36 .view-accessories .views-field-title {
  padding: 20px 0 0 20px;
}

.page-taxonomy-term-1 .view-casual-seating .views-field-title a,
.page-taxonomy-term-1 .view-accessories .views-field-title a,
.page-taxonomy-term-2 .view-casual-seating .views-field-title a,
.page-taxonomy-term-2 .view-accessories .views-field-title a,
.page-taxonomy-term-6 .view-casual-seating .views-field-title a,
.page-taxonomy-term-6 .view-accessories .views-field-title a,
.page-taxonomy-term-15 .view-casual-seating .views-field-title a,
.page-taxonomy-term-15 .view-accessories .views-field-title a,
.page-taxonomy-term-20 .view-casual-seating .views-field-title a,
.page-taxonomy-term-20 .view-accessories .views-field-title a,
.page-taxonomy-term-21 .view-casual-seating .views-field-title a,
.page-taxonomy-term-21 .view-accessories .views-field-title a,
.page-taxonomy-term-22 .view-casual-seating .views-field-title a,
.page-taxonomy-term-22 .view-accessories .views-field-title a,
.page-taxonomy-term-27 .view-casual-seating .views-field-title a,
.page-taxonomy-term-27 .view-accessories .views-field-title a,
.page-taxonomy-term-25 .view-casual-seating .views-field-title a,
.page-taxonomy-term-25 .view-accessories .views-field-title a,
.page-taxonomy-term-31 .view-casual-seating .views-field-title a,
.page-taxonomy-term-31 .view-accessories .views-field-title a,
.page-taxonomy-term-33 .view-casual-seating .views-field-title a,
.page-taxonomy-term-33 .view-accessories .views-field-title a,
.page-taxonomy-term-34 .view-casual-seating .views-field-title a,
.page-taxonomy-term-34 .view-accessories .views-field-title a,
.page-taxonomy-term-36 .view-casual-seating .views-field-title a,
.page-taxonomy-term-36 .view-accessories .views-field-title a {
  font-size: 1.2em;
  color: #536a27;
  font-family: "museo-sans";
  font-weight: 700;
}

@media (min-width: 1240px) {
  .page-taxonomy-term-1 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-1 .view-accessories .views-field-title a,
  .page-taxonomy-term-2 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-2 .view-accessories .views-field-title a,
  .page-taxonomy-term-6 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-6 .view-accessories .views-field-title a,
  .page-taxonomy-term-15 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-15 .view-accessories .views-field-title a,
  .page-taxonomy-term-20 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-20 .view-accessories .views-field-title a,
  .page-taxonomy-term-21 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-21 .view-accessories .views-field-title a,
  .page-taxonomy-term-22 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-22 .view-accessories .views-field-title a,
  .page-taxonomy-term-27 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-27 .view-accessories .views-field-title a,
  .page-taxonomy-term-25 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-25 .view-accessories .views-field-title a,
  .page-taxonomy-term-31 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-31 .view-accessories .views-field-title a,
  .page-taxonomy-term-33 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-33 .view-accessories .views-field-title a,
  .page-taxonomy-term-34 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-34 .view-accessories .views-field-title a,
  .page-taxonomy-term-36 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-36 .view-accessories .views-field-title a {
    font-size: 1.5em;
  }
}

.page-taxonomy-term-1 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-1 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-2 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-2 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-6 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-6 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-15 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-15 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-20 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-20 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-21 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-21 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-22 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-22 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-27 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-27 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-25 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-25 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-31 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-31 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-33 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-33 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-34 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-34 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-36 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-36 .view-accessories .views-field-title-1 a {
  color: #536a27;
}

.page-taxonomy-term-1 .view-casual-seating .views-field-body,
.page-taxonomy-term-1 .view-accessories .views-field-body,
.page-taxonomy-term-2 .view-casual-seating .views-field-body,
.page-taxonomy-term-2 .view-accessories .views-field-body,
.page-taxonomy-term-6 .view-casual-seating .views-field-body,
.page-taxonomy-term-6 .view-accessories .views-field-body,
.page-taxonomy-term-15 .view-casual-seating .views-field-body,
.page-taxonomy-term-15 .view-accessories .views-field-body,
.page-taxonomy-term-20 .view-casual-seating .views-field-body,
.page-taxonomy-term-20 .view-accessories .views-field-body,
.page-taxonomy-term-21 .view-casual-seating .views-field-body,
.page-taxonomy-term-21 .view-accessories .views-field-body,
.page-taxonomy-term-22 .view-casual-seating .views-field-body,
.page-taxonomy-term-22 .view-accessories .views-field-body,
.page-taxonomy-term-27 .view-casual-seating .views-field-body,
.page-taxonomy-term-27 .view-accessories .views-field-body,
.page-taxonomy-term-25 .view-casual-seating .views-field-body,
.page-taxonomy-term-25 .view-accessories .views-field-body,
.page-taxonomy-term-31 .view-casual-seating .views-field-body,
.page-taxonomy-term-31 .view-accessories .views-field-body,
.page-taxonomy-term-33 .view-casual-seating .views-field-body,
.page-taxonomy-term-33 .view-accessories .views-field-body,
.page-taxonomy-term-34 .view-casual-seating .views-field-body,
.page-taxonomy-term-34 .view-accessories .views-field-body,
.page-taxonomy-term-36 .view-casual-seating .views-field-body,
.page-taxonomy-term-36 .view-accessories .views-field-body {
  color: #536a27;
  font-family: "museo-sans";
  font-weight: 300;
}

.page-taxonomy-term-1 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-1 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-1 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-1 .view-accessories .views-field-field-image,
.page-taxonomy-term-2 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-2 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-2 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-2 .view-accessories .views-field-field-image,
.page-taxonomy-term-6 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-6 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-6 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-6 .view-accessories .views-field-field-image,
.page-taxonomy-term-15 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-15 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-15 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-15 .view-accessories .views-field-field-image,
.page-taxonomy-term-20 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-20 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-20 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-20 .view-accessories .views-field-field-image,
.page-taxonomy-term-21 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-21 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-21 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-21 .view-accessories .views-field-field-image,
.page-taxonomy-term-22 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-22 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-22 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-22 .view-accessories .views-field-field-image,
.page-taxonomy-term-27 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-27 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-27 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-27 .view-accessories .views-field-field-image,
.page-taxonomy-term-25 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-25 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-25 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-25 .view-accessories .views-field-field-image,
.page-taxonomy-term-31 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-31 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-31 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-31 .view-accessories .views-field-field-image,
.page-taxonomy-term-33 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-33 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-33 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-33 .view-accessories .views-field-field-image,
.page-taxonomy-term-34 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-34 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-34 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-34 .view-accessories .views-field-field-image,
.page-taxonomy-term-36 .view-casual-seating .views-field-field-thumbnail,
.page-taxonomy-term-36 .view-casual-seating .views-field-field-image,
.page-taxonomy-term-36 .view-accessories .views-field-field-thumbnail,
.page-taxonomy-term-36 .view-accessories .views-field-field-image {
  text-align: center;
  overflow: hidden;
  width: 100%;
}

.page-taxonomy-term-1 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-1 .view-accessories .views-field-field-price2,
.page-taxonomy-term-2 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-2 .view-accessories .views-field-field-price2,
.page-taxonomy-term-6 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-6 .view-accessories .views-field-field-price2,
.page-taxonomy-term-15 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-15 .view-accessories .views-field-field-price2,
.page-taxonomy-term-20 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-20 .view-accessories .views-field-field-price2,
.page-taxonomy-term-21 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-21 .view-accessories .views-field-field-price2,
.page-taxonomy-term-22 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-22 .view-accessories .views-field-field-price2,
.page-taxonomy-term-27 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-27 .view-accessories .views-field-field-price2,
.page-taxonomy-term-25 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-25 .view-accessories .views-field-field-price2,
.page-taxonomy-term-31 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-31 .view-accessories .views-field-field-price2,
.page-taxonomy-term-33 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-33 .view-accessories .views-field-field-price2,
.page-taxonomy-term-34 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-34 .view-accessories .views-field-field-price2,
.page-taxonomy-term-36 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-36 .view-accessories .views-field-field-price2 {
  background-color: #536a27;
  color: #ebf3dc;
  text-align: right;
  font-family: "museo-sans";
  position: absolute;
  width: 100%;
  left: 0;
  padding: 10px;
  bottom: 0;
  border-radius: 0 0 10px 10px;
  font-weight: 900;
}

.page-taxonomy-term-1 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-1 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-2 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-2 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-6 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-6 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-15 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-15 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-20 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-20 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-21 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-21 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-22 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-22 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-27 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-27 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-25 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-25 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-31 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-31 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-33 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-33 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-34 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-34 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-36 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-36 .view-accessories .views-field-field-price2 a {
  color: #ebf3dc;
  font-family: "museo-sans";
  padding-left: 3px;
}

.page-taxonomy-term-3 .view-accessories .views-row,
.page-taxonomy-term-3 .view-casual-seating .views-row,
.page-taxonomy-term-4 .view-accessories .views-row,
.page-taxonomy-term-4 .view-casual-seating .views-row,
.page-taxonomy-term-7 .view-accessories .views-row,
.page-taxonomy-term-7 .view-casual-seating .views-row,
.page-taxonomy-term-8 .view-accessories .views-row,
.page-taxonomy-term-8 .view-casual-seating .views-row,
.page-taxonomy-term-10 .view-accessories .views-row,
.page-taxonomy-term-10 .view-casual-seating .views-row,
.page-taxonomy-term-23 .view-accessories .views-row,
.page-taxonomy-term-23 .view-casual-seating .views-row,
.page-taxonomy-term-24 .view-accessories .views-row,
.page-taxonomy-term-24 .view-casual-seating .views-row,
.page-taxonomy-term-26 .view-accessories .views-row,
.page-taxonomy-term-26 .view-casual-seating .views-row,
.page-taxonomy-term-28 .view-accessories .views-row,
.page-taxonomy-term-28 .view-casual-seating .views-row,
.page-taxonomy-term-30 .view-accessories .views-row,
.page-taxonomy-term-30 .view-casual-seating .views-row,
.page-taxonomy-term-32 .view-accessories .views-row,
.page-taxonomy-term-32 .view-casual-seating .views-row,
.page-taxonomy-term-35 .view-accessories .views-row,
.page-taxonomy-term-35 .view-casual-seating .views-row {
  background-color: #dbd1c3;
}

.page-taxonomy-term-3 .view-accessories .views-field-title,
.page-taxonomy-term-3 .view-casual-seating .views-field-title,
.page-taxonomy-term-4 .view-accessories .views-field-title,
.page-taxonomy-term-4 .view-casual-seating .views-field-title,
.page-taxonomy-term-7 .view-accessories .views-field-title,
.page-taxonomy-term-7 .view-casual-seating .views-field-title,
.page-taxonomy-term-8 .view-accessories .views-field-title,
.page-taxonomy-term-8 .view-casual-seating .views-field-title,
.page-taxonomy-term-10 .view-accessories .views-field-title,
.page-taxonomy-term-10 .view-casual-seating .views-field-title,
.page-taxonomy-term-23 .view-accessories .views-field-title,
.page-taxonomy-term-23 .view-casual-seating .views-field-title,
.page-taxonomy-term-24 .view-accessories .views-field-title,
.page-taxonomy-term-24 .view-casual-seating .views-field-title,
.page-taxonomy-term-26 .view-accessories .views-field-title,
.page-taxonomy-term-26 .view-casual-seating .views-field-title,
.page-taxonomy-term-28 .view-accessories .views-field-title,
.page-taxonomy-term-28 .view-casual-seating .views-field-title,
.page-taxonomy-term-30 .view-accessories .views-field-title,
.page-taxonomy-term-30 .view-casual-seating .views-field-title,
.page-taxonomy-term-32 .view-accessories .views-field-title,
.page-taxonomy-term-32 .view-casual-seating .views-field-title,
.page-taxonomy-term-35 .view-accessories .views-field-title,
.page-taxonomy-term-35 .view-casual-seating .views-field-title {
  padding: 20px 0 0 20px;
}

.page-taxonomy-term-3 .view-accessories .views-field-title a,
.page-taxonomy-term-3 .view-casual-seating .views-field-title a,
.page-taxonomy-term-4 .view-accessories .views-field-title a,
.page-taxonomy-term-4 .view-casual-seating .views-field-title a,
.page-taxonomy-term-7 .view-accessories .views-field-title a,
.page-taxonomy-term-7 .view-casual-seating .views-field-title a,
.page-taxonomy-term-8 .view-accessories .views-field-title a,
.page-taxonomy-term-8 .view-casual-seating .views-field-title a,
.page-taxonomy-term-10 .view-accessories .views-field-title a,
.page-taxonomy-term-10 .view-casual-seating .views-field-title a,
.page-taxonomy-term-23 .view-accessories .views-field-title a,
.page-taxonomy-term-23 .view-casual-seating .views-field-title a,
.page-taxonomy-term-24 .view-accessories .views-field-title a,
.page-taxonomy-term-24 .view-casual-seating .views-field-title a,
.page-taxonomy-term-26 .view-accessories .views-field-title a,
.page-taxonomy-term-26 .view-casual-seating .views-field-title a,
.page-taxonomy-term-28 .view-accessories .views-field-title a,
.page-taxonomy-term-28 .view-casual-seating .views-field-title a,
.page-taxonomy-term-30 .view-accessories .views-field-title a,
.page-taxonomy-term-30 .view-casual-seating .views-field-title a,
.page-taxonomy-term-32 .view-accessories .views-field-title a,
.page-taxonomy-term-32 .view-casual-seating .views-field-title a,
.page-taxonomy-term-35 .view-accessories .views-field-title a,
.page-taxonomy-term-35 .view-casual-seating .views-field-title a {
  font-size: 1.2em;
  color: #5e4d36;
  font-family: "museo-sans";
}

@media (min-width: 1240px) {
  .page-taxonomy-term-3 .view-accessories .views-field-title a,
  .page-taxonomy-term-3 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-4 .view-accessories .views-field-title a,
  .page-taxonomy-term-4 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-7 .view-accessories .views-field-title a,
  .page-taxonomy-term-7 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-8 .view-accessories .views-field-title a,
  .page-taxonomy-term-8 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-10 .view-accessories .views-field-title a,
  .page-taxonomy-term-10 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-23 .view-accessories .views-field-title a,
  .page-taxonomy-term-23 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-24 .view-accessories .views-field-title a,
  .page-taxonomy-term-24 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-26 .view-accessories .views-field-title a,
  .page-taxonomy-term-26 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-28 .view-accessories .views-field-title a,
  .page-taxonomy-term-28 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-30 .view-accessories .views-field-title a,
  .page-taxonomy-term-30 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-32 .view-accessories .views-field-title a,
  .page-taxonomy-term-32 .view-casual-seating .views-field-title a,
  .page-taxonomy-term-35 .view-accessories .views-field-title a,
  .page-taxonomy-term-35 .view-casual-seating .views-field-title a {
    font-size: 1.5em;
  }
}

.page-taxonomy-term-3 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-3 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-4 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-4 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-7 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-7 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-8 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-8 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-10 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-10 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-23 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-23 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-24 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-24 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-26 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-26 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-28 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-28 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-30 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-30 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-32 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-32 .view-casual-seating .views-field-title-1 a,
.page-taxonomy-term-35 .view-accessories .views-field-title-1 a,
.page-taxonomy-term-35 .view-casual-seating .views-field-title-1 a {
  color: #5e4d36;
}

.page-taxonomy-term-3 .view-accessories .views-field-body,
.page-taxonomy-term-3 .view-casual-seating .views-field-body,
.page-taxonomy-term-4 .view-accessories .views-field-body,
.page-taxonomy-term-4 .view-casual-seating .views-field-body,
.page-taxonomy-term-7 .view-accessories .views-field-body,
.page-taxonomy-term-7 .view-casual-seating .views-field-body,
.page-taxonomy-term-8 .view-accessories .views-field-body,
.page-taxonomy-term-8 .view-casual-seating .views-field-body,
.page-taxonomy-term-10 .view-accessories .views-field-body,
.page-taxonomy-term-10 .view-casual-seating .views-field-body,
.page-taxonomy-term-23 .view-accessories .views-field-body,
.page-taxonomy-term-23 .view-casual-seating .views-field-body,
.page-taxonomy-term-24 .view-accessories .views-field-body,
.page-taxonomy-term-24 .view-casual-seating .views-field-body,
.page-taxonomy-term-26 .view-accessories .views-field-body,
.page-taxonomy-term-26 .view-casual-seating .views-field-body,
.page-taxonomy-term-28 .view-accessories .views-field-body,
.page-taxonomy-term-28 .view-casual-seating .views-field-body,
.page-taxonomy-term-30 .view-accessories .views-field-body,
.page-taxonomy-term-30 .view-casual-seating .views-field-body,
.page-taxonomy-term-32 .view-accessories .views-field-body,
.page-taxonomy-term-32 .view-casual-seating .views-field-body,
.page-taxonomy-term-35 .view-accessories .views-field-body,
.page-taxonomy-term-35 .view-casual-seating .views-field-body {
  color: #5e4d36;
  font-family: "museo-sans";
  font-weight: 300;
}

.page-taxonomy-term-3 .view-accessories .views-field-field-price2,
.page-taxonomy-term-3 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-4 .view-accessories .views-field-field-price2,
.page-taxonomy-term-4 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-7 .view-accessories .views-field-field-price2,
.page-taxonomy-term-7 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-8 .view-accessories .views-field-field-price2,
.page-taxonomy-term-8 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-10 .view-accessories .views-field-field-price2,
.page-taxonomy-term-10 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-23 .view-accessories .views-field-field-price2,
.page-taxonomy-term-23 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-24 .view-accessories .views-field-field-price2,
.page-taxonomy-term-24 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-26 .view-accessories .views-field-field-price2,
.page-taxonomy-term-26 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-28 .view-accessories .views-field-field-price2,
.page-taxonomy-term-28 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-30 .view-accessories .views-field-field-price2,
.page-taxonomy-term-30 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-32 .view-accessories .views-field-field-price2,
.page-taxonomy-term-32 .view-casual-seating .views-field-field-price2,
.page-taxonomy-term-35 .view-accessories .views-field-field-price2,
.page-taxonomy-term-35 .view-casual-seating .views-field-field-price2 {
  background-color: #5e4d36;
  color: #dbd1c3;
  text-align: right;
  font-family: "museo-sans";
  position: absolute;
  width: 100%;
  left: 0;
  padding: 10px;
  bottom: 0;
  font-weight: 900;
  border-radius: 0 0 10px 10px;
}

.page-taxonomy-term-3 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-3 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-4 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-4 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-7 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-7 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-8 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-8 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-10 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-10 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-23 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-23 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-24 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-24 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-26 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-26 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-28 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-28 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-30 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-30 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-32 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-32 .view-casual-seating .views-field-field-price2 a,
.page-taxonomy-term-35 .view-accessories .views-field-field-price2 a,
.page-taxonomy-term-35 .view-casual-seating .views-field-field-price2 a {
  color: #dbd1c3;
  font-family: "museo-sans";
  padding-left: 3px;
}

#taxonomy-term-12 .page-title {
  color: #5e4d36;
}

.page-taxonomy-term-25 .page-title {
  color: #92c6ce;
}

.node-type-accessories .page-title {
  display: none;
}

.node-type-accessories .views-field-field-image img {
  width: auto;
}

.view.view-accessories.view-id-accessories.view-display-id-page .views-row {
  color: white;
  background-color: #ebf3dc;
}

.view.view-accessories.view-id-accessories.view-display-id-page .views-field-body {
  color: #536a27;
}

.view.view-accessories.view-id-accessories.view-display-id-page .views-field-title a {
  font-size: 1.5em;
  color: #536a27;
}

.view.view-accessories.view-id-accessories.view-display-id-page .views-field-title-1 a {
  color: #536a27;
}

.view.view-accessories.view-id-accessories.view-display-id-page .views-field-field-image {
  text-align: center;
}

.view.view-accessories.view-id-accessories.view-display-id-page .views-field-field-price2 {
  background-color: #536a27;
  color: #ebf3dc;
  text-align: right;
  font-family: "museo-sans";
  position: absolute;
  width: 100%;
  left: 0;
  padding: 10px;
  bottom: 0;
  border-radius: 0 0 10px 10px;
  font-weight: 900;
}

.view.view-accessories.view-id-accessories.view-display-id-page .views-field-field-price2 a {
  color: #ebf3dc;
  font-family: "museo-sans";
  padding-left: 3px;
}

#block-block-7,
#block-block-21,
#block-block-22,
#block-block-23,
#block-block-24,
#block-block-25,
#block-block-26,
#block-block-27,
#block-block-28 {
  width: 267px;
  margin: 0 auto;
}

#block-block-7 a,
#block-block-21 a,
#block-block-22 a,
#block-block-23 a,
#block-block-24 a,
#block-block-25 a,
#block-block-26 a,
#block-block-27 a,
#block-block-28 a {
  display: flex;
  align-items: center;
  width: 310px;
  flex-wrap: wrap;
  color: #536a27;
}

#block-block-7 img,
#block-block-21 img,
#block-block-22 img,
#block-block-23 img,
#block-block-24 img,
#block-block-25 img,
#block-block-26 img,
#block-block-27 img,
#block-block-28 img {
  width: 45px;
  margin: 0 18px;
}

#block-views-casual-seating-block-2,
#block-views-accessories-block-2 {
  display: none;
}

@media (min-width: 1240px) {
  #block-views-casual-seating-block-2,
  #block-views-accessories-block-2 {
    display: inline-block;
  }
}

#block-views-casual-seating-block-3 {
  display: block;
}

@media (min-width: 1162px) {
  #block-views-casual-seating-block-3 {
    display: none;
  }
}

#block-views-casual-seating-block-2,
#block-views-casual-seating-block-3,
#block-views-accessories-block-2 {
  margin: 0 auto;
  width: 260px;
  color: #536a27;
  background-color: #ebf3dc;
  padding: 0 10px 10px 10px;
  border-radius: 10px;
  margin-top: 20px;
  text-align: left;
}

#block-views-casual-seating-block-2 .view-header,
#block-views-casual-seating-block-3 .view-header,
#block-views-accessories-block-2 .view-header {
  padding-left: 10px;
  padding-top: 10px;
  width: 200px;
  text-align: center;
}

#block-views-casual-seating-block-2 .view-header h3,
#block-views-casual-seating-block-3 .view-header h3,
#block-views-accessories-block-2 .view-header h3 {
  font-size: 1.5em;
  margin: 0;
  font-weight: bold;
}

#block-views-casual-seating-block-2 .view-header a,
#block-views-casual-seating-block-3 .view-header a,
#block-views-accessories-block-2 .view-header a {
  font-weight: bold;
}

#block-views-casual-seating-block-2 .view-content,
#block-views-casual-seating-block-3 .view-content,
#block-views-accessories-block-2 .view-content {
  padding-left: 1.2em;
  width: 252px;
}

#block-views-casual-seating-block-2 .view-content a,
#block-views-casual-seating-block-3 .view-content a,
#block-views-accessories-block-2 .view-content a {
  font-size: 14px;
  font-weight: 500;
}

#block-views-casual-seating-block-2 a,
#block-views-casual-seating-block-3 a,
#block-views-accessories-block-2 a {
  color: #536a27;
  font-weight: 500;
}

.node-type-products .layout-3col__full,
.node-type-accessories .layout-3col__full {
  max-width: 1500px;
  margin: auto;
  float: none;
  width: 90%;
  text-align: center;
}

.node-products,
.node-accessories {
  padding: 0 2em;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}

.node-products .field-name-field-price,
.node-products .field-name-field-price2,
.node-accessories .field-name-field-price,
.node-accessories .field-name-field-price2 {
  font-weight: bold;
  color: #9e0b0f;
  padding: 14px 0px;
}

.gallery-frame {
  width: 97%;
  margin: 0 auto;
}

a.next-slide.slide-button {
  background-image: url(/sites/default/files/families-images/Arrow-right.png);
  color: transparent;
  width: 57px;
  height: 80px;
  display: block;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

a.prev-slide.slide-button {
  background-image: url(/sites/default/files/families-images/Arrow-left.png);
  color: transparent;
  width: 57px;
  height: 80px;
  display: block;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.arrow.back {
  position: absolute;
  left: 6px;
  top: 6px;
  background-image: url(/sites/default/files/families-images/Arrow-left.png);
  color: transparent;
  width: 17px;
  height: 80px;
  display: block;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.arrow.forward {
  position: absolute;
  right: 6px;
  top: 6px;
  background-image: url(/sites/default/files/families-images/Arrow-right.png);
  color: transparent;
  width: 17px;
  height: 80px;
  display: block;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.galleryformatter .view-full {
  position: relative;
  color: #536a27;
}

.galleryformatter .slide-button {
  top: 43% !important;
}

.gallery-slides {
  height: 375px !important;
  width: 100% !important;
}

.gallery-slides .panel-overlay {
  display: none;
}

.gallery-slide a {
  float: right;
}

.gallery-slide img {
  display: block;
  margin: auto;
  padding: 12px;
}

.gallery-thumbs {
  padding: 0 40px;
  width: 100% !important;
  height: 90px;
  margin: 20px auto !important;
}

.gallery-thumbs .wrapper {
  background: 0 0;
  padding: 0 17px;
  height: 90px;
}

.gallery-thumbs img {
  height: 90px;
  width: auto;
  border: 1px solid #eaeaea;
}

#mini-panel-product_information_form {
  width: 275px;
  padding: 10px 27px;
  margin: 2em auto;
  background-color: #ebf3dc;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #aaaaaa;
}

#mini-panel-product_information_form .form-item {
  margin: 1rem 0;
}

#mini-panel-product_information_form .form-number {
  margin-bottom: 0;
  font-size: 2.3em;
  color: #e5b211;
  text-align: center;
  line-height: 1.25rem;
}

#mini-panel-product_information_form .send-line {
  margin-top: 6px;
}

#mini-panel-product_information_form .webform-component--website-url {
  display: none;
}

#mini-panel-product_information_form .form-actions {
  float: right;
}

#mini-panel-product_information_form .form-actions .webform-submit {
  border: none;
  background-color: transparent;
  color: #9e0b0f;
}

#mini-panel-product_information_form .form-options {
  color: #536a27;
}

thead {
  font-size: 75%;
}

tbody {
  font-size: 75%;
}

.wooden-box,
.recycled-box {
  margin: 0 auto;
}

.wooden-box .why-text,
.recycled-box .why-text {
  width: 100%;
}

.wooden-box .why-text h1,
.recycled-box .why-text h1 {
  color: #1d7db3;
}

.wooden-box .why-text h2,
.recycled-box .why-text h2 {
  color: #9e0b0f;
}

.wooden-box .why-text img,
.recycled-box .why-text img {
  display: none;
}

.wooden-box .why-photos,
.recycled-box .why-photos {
  width: 160px;
  margin: 0 auto;
}

.wooden-box .why-photos img,
.recycled-box .why-photos img {
  border-radius: 50%;
  max-width: 150px;
}

.wooden-box .why-photos img {
  border-radius: 50%;
}

/*---------------------------------- Tablet Stlyes -----------------------------*/
@media (min-width: 770px) {
  #block-views-outdoor-living-banner-block,
  #block-views-structure-banner-block {
    width: 50vw;
    float: right;
    min-height: 317px;
    max-width: 50%;
    overflow: hidden;
    /*.view-outdoor-living-banner*/
  }
  #block-views-outdoor-living-banner-block .field-content img,
  #block-views-structure-banner-block .field-content img {
    float: right;
    max-width: 50vw;
    min-width: 736px;
    margin-top: -65px;
  }
  #block-block-4,
  #block-block-5,
  #block-block-9,
  #block-block-10,
  #block-block-11,
  #block-block-19,
  #block-block-20 {
    width: 50vw;
    max-width: 50%;
    height: 317px;
    float: left;
    overflow: hidden;
  }
  #block-block-4 h1,
  #block-block-5 h1,
  #block-block-9 h1,
  #block-block-10 h1,
  #block-block-11 h1,
  #block-block-19 h1,
  #block-block-20 h1 {
    font-size: 3.5em;
  }
  .product-block {
    display: flex;
    position: relative;
  }
  .product-block h1 {
    font-size: 3.5em;
  }
  .recycled {
    width: 39vw;
    float: left;
  }
  .recycled .outdoor-icon {
    width: 245px;
  }
  .wooden {
    width: 39vw;
    float: right;
  }
  .wooden .outdoor-icon {
    width: 245px;
  }
  .body {
    clear: both;
  }
  .seating,
  .tables,
  .seating-w,
  .tables-w .fabric {
    width: 80vw;
    clear: both;
  }
  .view-accessories .views-row,
  .view-casual-seating .views-row,
  .view.view-accessories.view-id-accessories.view-display-id-page .views-row {
    margin: 2em 1em;
    border-radius: 10px;
    width: 283px;
    height: 260px;
  }
  .view.view-accessories.view-id-accessories.view-display-id-page .views-field-field-image {
    float: none;
  }
  .field-name-field-product-name {
    font-family: "museo-sans";
    font-size: 2.7em;
    font-weight: bold;
    line-height: 1.2;
  }
  .field-name-field-price,
  .field-name-field-price2 {
    font-family: "museo-sans";
    font-size: 2em;
    font-weight: bold;
  }
  .node-products,
  .node-accessories {
    margin: 20px auto 0;
    max-width: 50%;
    min-width: 544px;
  }
  .wooden-box,
  .recycled-box {
    margin: 0 auto;
  }
  .wooden-box .why-text,
  .recycled-box .why-text {
    float: left;
    clear: left;
    width: 70%;
  }
  .wooden-box .why-text img,
  .recycled-box .why-text img {
    display: block;
    width: 20%;
    float: left;
    margin-top: 2em;
  }
  .wooden-box .why-text ul,
  .recycled-box .why-text ul {
    float: right;
    width: 78%;
    padding: 0 3em;
  }
  .wooden-box .why-photos,
  .recycled-box .why-photos {
    float: right;
    margin-top: 5em;
    width: 180px;
  }
}

/*-----------------------------------Tablet Landscape Styles ---------------------------------*/
@media (min-width: 1024px) {
  #block-block-4 h1,
  #block-block-5 h1,
  #block-block-9 h1,
  #block-block-10 h1,
  #block-block-11 h1,
  #block-block-19 h1,
  #block-block-20 h1 {
    font-size: 4em;
    line-height: 3.7rem;
  }
  #block-block-4 .contact-info h3,
  #block-block-5 .contact-info h3,
  #block-block-9 .contact-info h3,
  #block-block-10 .contact-info h3,
  #block-block-11 .contact-info h3,
  #block-block-19 .contact-info h3,
  #block-block-20 .contact-info h3 {
    line-height: 2rem;
    margin: 0;
  }
  #block-block-4 .contact-info .phone,
  #block-block-5 .contact-info .phone,
  #block-block-9 .contact-info .phone,
  #block-block-10 .contact-info .phone,
  #block-block-11 .contact-info .phone,
  #block-block-19 .contact-info .phone,
  #block-block-20 .contact-info .phone {
    font-weight: 300;
  }
  #block-block-4 .contact-info .phone img,
  #block-block-5 .contact-info .phone img,
  #block-block-9 .contact-info .phone img,
  #block-block-10 .contact-info .phone img,
  #block-block-11 .contact-info .phone img,
  #block-block-19 .contact-info .phone img,
  #block-block-20 .contact-info .phone img {
    width: 30px;
  }
  #block-block-4 .contact-info .email img,
  #block-block-5 .contact-info .email img,
  #block-block-9 .contact-info .email img,
  #block-block-10 .contact-info .email img,
  #block-block-11 .contact-info .email img,
  #block-block-19 .contact-info .email img,
  #block-block-20 .contact-info .email img {
    width: 50px;
  }
  #block-block-4 .contact-info a,
  #block-block-5 .contact-info a,
  #block-block-9 .contact-info a,
  #block-block-10 .contact-info a,
  #block-block-11 .contact-info a,
  #block-block-19 .contact-info a,
  #block-block-20 .contact-info a {
    font-weight: 300;
  }
  .recycled {
    position: relative;
    width: 39vw;
  }
  .recycled .view-products {
    position: absolute;
    bottom: 19px;
    left: 32px;
  }
  .wooden {
    position: relative;
    width: 39vw;
  }
  .wooden .view-products {
    position: absolute;
    bottom: 19px;
    left: 32px;
  }
  .seating,
  .tables,
  .seating-w,
  .tables-w {
    clear: both;
    margin-top: 2em;
  }
  .seating .text,
  .tables .text,
  .seating-w .text,
  .tables-w .text {
    width: 36%;
    float: left;
  }
  .seating .text p:last-child,
  .tables .text p:last-child,
  .seating-w .text p:last-child,
  .tables-w .text p:last-child {
    text-align: right;
    margin-right: 24px;
  }
  .seating .seating-photo {
    min-height: 350px;
    border-radius: 0px 10px 10px 0px;
  }
  .seating-w .seating-photo {
    min-height: 320px;
    border-radius: 0px 10px 10px 0px;
    background-position: center bottom;
  }
  .tables .tables-photo {
    min-height: 350px;
    border-radius: 0px 10px 10px 0px;
  }
  .tables-w .tables-photo {
    min-height: 320px;
    border-radius: 0px 10px 10px 0px;
    background-position: center bottom;
  }
  .wooden-box,
  .recycled-box {
    margin: 0 auto;
  }
  .wooden-box .why-text,
  .recycled-box .why-text {
    float: left;
    clear: left;
    width: 78%;
  }
  .wooden-box .why-text img,
  .recycled-box .why-text img {
    width: 98px;
    float: left;
    margin-top: 2em;
  }
  .wooden-box .why-text ul,
  .recycled-box .why-text ul {
    float: left;
    width: 78%;
  }
  .wooden-box .why-photos,
  .recycled-box .why-photos {
    float: right;
    margin-top: 5em;
  }
  #block-block-7,
  #block-block-21,
  #block-block-22,
  #block-block-23,
  #block-block-24,
  #block-block-25,
  #block-block-26,
  #block-block-27,
  #block-block-28 {
    float: right;
  }
  #block-block-7 a,
  #block-block-21 a,
  #block-block-22 a,
  #block-block-23 a,
  #block-block-24 a,
  #block-block-25 a,
  #block-block-26 a,
  #block-block-27 a,
  #block-block-28 a {
    display: flex;
    align-items: center;
    width: 323px;
    flex-wrap: wrap;
    color: #536a27;
  }
  #block-block-7 img,
  #block-block-21 img,
  #block-block-22 img,
  #block-block-23 img,
  #block-block-24 img,
  #block-block-25 img,
  #block-block-26 img,
  #block-block-27 img,
  #block-block-28 img {
    width: 45px;
    margin: 0 18px;
  }
  .node-products,
  .node-accessories {
    display: inline-block;
  }
  #block-panels-mini-product-information-form {
    width: 10px;
    float: right;
    clear: right;
  }
  #block-panels-mini-product-information-form #mini-panel-product_information_form {
    float: right;
  }
  #block-views-casual-seating-block-2,
  #block-views-casual-seating-block-3,
  #block-views-accessories-block-2 {
    float: left;
  }
  .node-type-products .layout-3col__full,
  .node-type-accessories .layout-3col__full {
    width: 90%;
  }
}

/*------------------------------ Desktop Styles --------------------*/
@media (min-width: 1240px) {
  .product-block h1 {
    line-height: 1;
  }
  .product-block .description {
    float: left;
    width: 50%;
    margin-bottom: 20px;
  }
  .product-block .outdoor-icon {
    float: right;
    width: 213px;
    margin-right: -38px;
  }
  .product-block .view-products {
    clear: both;
    position: absolute;
    bottom: 42px;
  }
  .product-block .description {
    float: left;
    width: 50%;
  }
  .product-block .outdoor-icon {
    float: right;
    width: 239px;
    margin-right: -31px;
  }
  .product-block .view-products {
    clear: both;
    position: absolute;
    bottom: 19px;
  }
  .seating .seating-photo {
    min-height: 359px;
  }
}

@media (min-width: 1240px) and (min-width: 1312px) {
  .seating .seating-photo {
    min-height: 300px;
  }
}

@media (min-width: 1240px) {
  .tables .tables-photo {
    min-height: 359px;
  }
}

@media (min-width: 1240px) and (min-width: 1312px) {
  .tables .tables-photo {
    min-height: 300px;
  }
}

@media (min-width: 1240px) and (min-width: 1312px) {
  .seating-w .seating-photo {
    min-height: 270px;
    background-position: center;
  }
}

@media (min-width: 1240px) and (min-width: 1312px) {
  .tables-w .tables-photo {
    min-height: 270px;
    background-position: center;
  }
}

@media (min-width: 1240px) {
  .view-casual-seating .views-row,
  .view-accessories .views-row {
    width: 335px;
    height: 310px;
  }
  .view-casual-seating .views-field-field-thumbnail img,
  .view-casual-seating .views-field-field-image img,
  .view-accessories .views-field-field-thumbnail img,
  .view-accessories .views-field-field-image img {
    max-height: 230px;
  }
}

/*------------------------------ A breakpoint for wider screens --------------------*/
@media (min-width: 1539px) {
  .product-block .description {
    float: left;
    width: 52%;
  }
  .product-block .outdoor-icon {
    float: right;
    width: 255px;
    margin-right: -2px;
    margin-top: -10px;
    margin-bottom: 0px;
  }
  .product-block .view-products {
    clear: both;
    position: absolute;
    bottom: 12px;
  }
}

/*-----------------------------------Landing Pages Styling ------------------------*/
.node-7 .product-block .outdoor-icon {
  max-width: 400px;
  min-width: 214px;
  width: 95%;
}

.node-7 .holder,
.node-7 .holder-2 {
  display: none;
}

#block-block-12,
#block-block-13,
#block-block-14,
#block-block-15,
#block-block-16,
#block-block-17,
#block-block-18 {
  background-color: #aaaaaa;
  color: #fff;
  text-align: right;
  height: 275px;
}

#block-block-12 h1,
#block-block-13 h1,
#block-block-14 h1,
#block-block-15 h1,
#block-block-16 h1,
#block-block-17 h1,
#block-block-18 h1 {
  text-transform: uppercase;
  margin-right: 30px;
  font-family: "museo-sans";
  font-weight: 100;
  font-size: 3em;
  margin-top: 0;
  padding-top: .5em;
  letter-spacing: 3px;
}

#block-block-12 h1 span,
#block-block-13 h1 span,
#block-block-14 h1 span,
#block-block-15 h1 span,
#block-block-16 h1 span,
#block-block-17 h1 span,
#block-block-18 h1 span {
  font-weight: 900;
}

#block-block-12 h2,
#block-block-13 h2,
#block-block-14 h2,
#block-block-15 h2,
#block-block-16 h2,
#block-block-17 h2,
#block-block-18 h2 {
  font-family: "museo-sans";
  color: #fff;
  font-size: 2em;
  font-weight: 100;
  margin-right: 30px;
}

#block-block-12 h2 span,
#block-block-13 h2 span,
#block-block-14 h2 span,
#block-block-15 h2 span,
#block-block-16 h2 span,
#block-block-17 h2 span,
#block-block-18 h2 span {
  font-weight: 100;
}

#block-block-12 .contact-info,
#block-block-13 .contact-info,
#block-block-14 .contact-info,
#block-block-15 .contact-info,
#block-block-16 .contact-info,
#block-block-17 .contact-info,
#block-block-18 .contact-info {
  color: #fff;
  text-align: right;
  margin-right: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

#block-block-12 .contact-info h3,
#block-block-13 .contact-info h3,
#block-block-14 .contact-info h3,
#block-block-15 .contact-info h3,
#block-block-16 .contact-info h3,
#block-block-17 .contact-info h3,
#block-block-18 .contact-info h3 {
  line-height: 2rem;
  margin: 0;
}

#block-block-12 .contact-info .phone,
#block-block-13 .contact-info .phone,
#block-block-14 .contact-info .phone,
#block-block-15 .contact-info .phone,
#block-block-16 .contact-info .phone,
#block-block-17 .contact-info .phone,
#block-block-18 .contact-info .phone {
  font-weight: 300;
}

#block-block-12 .contact-info .phone img,
#block-block-13 .contact-info .phone img,
#block-block-14 .contact-info .phone img,
#block-block-15 .contact-info .phone img,
#block-block-16 .contact-info .phone img,
#block-block-17 .contact-info .phone img,
#block-block-18 .contact-info .phone img {
  width: 30px;
}

#block-block-12 .contact-info .email,
#block-block-13 .contact-info .email,
#block-block-14 .contact-info .email,
#block-block-15 .contact-info .email,
#block-block-16 .contact-info .email,
#block-block-17 .contact-info .email,
#block-block-18 .contact-info .email {
  padding-left: 20px;
}

#block-block-12 .contact-info .email img,
#block-block-13 .contact-info .email img,
#block-block-14 .contact-info .email img,
#block-block-15 .contact-info .email img,
#block-block-16 .contact-info .email img,
#block-block-17 .contact-info .email img,
#block-block-18 .contact-info .email img {
  width: 50px;
}

#block-block-12 .contact-info a,
#block-block-13 .contact-info a,
#block-block-14 .contact-info a,
#block-block-15 .contact-info a,
#block-block-16 .contact-info a,
#block-block-17 .contact-info a,
#block-block-18 .contact-info a {
  color: #fff;
  font-weight: 300;
}

.commercial-playset {
  background-image: url(/sites/default/files/families-images/recycled-commercial.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  display: block;
}

.residential-playset {
  background-image: url(/sites/default/files/families-images/recycled-residential-playset.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  display: block;
}

.deluxe-playset {
  background-image: url(/sites/default/files/families-images/deluxe-wooden-playset.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  display: block;
}

.economy-playset {
  background-image: url(/sites/default/files/families-images/DIY-swing.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  overflow: hidden;
  border-radius: 0px 0px 10px 10px;
  display: block;
}

/*-----------------------Tablet Styles---------------------------------*/
@media (min-width: 770px) {
  #block-views-playset-banner-block {
    width: 50vw;
    float: right;
    min-height: 317px;
    max-width: 50%;
    overflow: hidden;
  }
  #block-views-playset-banner-block .view-playset-banner .field-content img {
    float: right;
    max-width: 50vw;
    min-width: 736px;
  }
  #block-block-12,
  #block-block-13,
  #block-block-14,
  #block-block-15,
  #block-block-16,
  #block-block-17,
  #block-block-18 {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: left;
    height: 317px;
    padding-top: .5em;
  }
  #block-block-12 h1,
  #block-block-13 h1,
  #block-block-14 h1,
  #block-block-15 h1,
  #block-block-16 h1,
  #block-block-17 h1,
  #block-block-18 h1 {
    font-size: 3.5em;
  }
  .node-7 .product-block .holder {
    display: block;
  }
}

@media (min-width: 770px) and (min-width: 1022px) {
  .node-7 .product-block .holder {
    display: none;
  }
}

@media (min-width: 770px) and (min-width: 1303px) {
  .node-7 .product-block .holder {
    display: block;
  }
}

@media (min-width: 770px) and (min-width: 1695px) {
  .node-7 .product-block .holder {
    display: none;
  }
}

/*------------------------Tablet Landscape Styles ---------------------*/
@media (min-width: 1024px) {
  #block-block-12 h1,
  #block-block-13 h1,
  #block-block-14 h1,
  #block-block-15 h1,
  #block-block-16 h1,
  #block-block-17 h1,
  #block-block-18 h1 {
    font-size: 4em;
    line-height: 3.7rem;
  }
  .seating .commercial-playset,
  .seating .deluxe-playset,
  .seating-w .commercial-playset,
  .seating-w .deluxe-playset {
    min-height: 308px;
    border-radius: 0px 10px 10px 0px;
  }
  .tables .residential-playset,
  .tables .economy-playset,
  .tables-w .residential-playset,
  .tables-w .economy-playset {
    min-height: 308px;
    border-radius: 0px 10px 10px 0px;
  }
  .node-7 .product-block .recycled .outdoor-icon {
    float: none;
    margin: 0 auto 47px;
  }
  .node-7 .product-block .description {
    float: none;
    width: 100%;
  }
  .node-7 .product-block .wooden .outdoor-icon {
    float: none;
    margin: 0px auto 47px;
  }
  #block-block-32 h1 {
    font-size: 4em;
  }
  .seating .commercial-playset,
  .seating .deluxe-playset {
    min-height: 430px;
    border-radius: 0px 10px 10px 0px;
  }
  .tables .residential-playset,
  .tables .economy-playset {
    min-height: 430px;
    border-radius: 0px 10px 10px 0px;
  }
}

/*-----------------------------Desktop Styles----------------------------------*/
@media (min-width: 1240px) {
  .seating .commercial-playset,
  .seating .deluxe-playset {
    min-height: 375px;
    border-radius: 0px 10px 10px 0px;
  }
  .tables .residential-playset,
  .tables .economy-playset {
    min-height: 375px;
    border-radius: 0px 10px 10px 0px;
  }
}

@media (min-width: 1700px) {
  .holder-2 {
    display: none;
  }
  .seating .commercial-playset,
  .seating .deluxe-playset {
    min-height: 315px;
    border-radius: 0px 10px 10px 0px;
  }
  .tables .residential-playset,
  .tables .economy-playset {
    min-height: 315px;
    border-radius: 0px 10px 10px 0px;
  }
}

#block-block-29,
#block-block-30,
#block-block-31,
#block-block-44,
#block-block-45 {
  background-color: #92c6ce;
  color: #fff;
  text-align: right;
  height: 296px;
  padding-top: 2em;
}

#block-block-29 h1,
#block-block-30 h1,
#block-block-31 h1,
#block-block-44 h1,
#block-block-45 h1 {
  text-transform: uppercase;
  margin-right: 20px;
  font-family: "museo-sans";
  font-weight: 100;
  font-size: 2.5em;
  margin-top: 0;
  letter-spacing: 3px;
}

#block-block-29 h1 span,
#block-block-30 h1 span,
#block-block-31 h1 span,
#block-block-44 h1 span,
#block-block-45 h1 span {
  font-weight: 900;
}

#block-block-29 h2,
#block-block-30 h2,
#block-block-31 h2,
#block-block-44 h2,
#block-block-45 h2 {
  font-family: "museo-sans";
  color: #fff;
  font-size: 2em;
  font-weight: 100;
  margin-right: 30px;
}

#block-block-29 h2 span,
#block-block-30 h2 span,
#block-block-31 h2 span,
#block-block-44 h2 span,
#block-block-45 h2 span {
  font-weight: 100;
}

#block-block-29 .contact-info,
#block-block-30 .contact-info,
#block-block-31 .contact-info,
#block-block-44 .contact-info,
#block-block-45 .contact-info {
  color: #fff;
  text-align: right;
  margin-right: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

#block-block-29 .contact-info h3,
#block-block-30 .contact-info h3,
#block-block-31 .contact-info h3,
#block-block-44 .contact-info h3,
#block-block-45 .contact-info h3 {
  line-height: 2rem;
  margin: 0;
}

#block-block-29 .contact-info .phone,
#block-block-30 .contact-info .phone,
#block-block-31 .contact-info .phone,
#block-block-44 .contact-info .phone,
#block-block-45 .contact-info .phone {
  font-weight: 300;
}

#block-block-29 .contact-info .phone img,
#block-block-30 .contact-info .phone img,
#block-block-31 .contact-info .phone img,
#block-block-44 .contact-info .phone img,
#block-block-45 .contact-info .phone img {
  width: 30px;
}

#block-block-29 .contact-info .email,
#block-block-30 .contact-info .email,
#block-block-31 .contact-info .email,
#block-block-44 .contact-info .email,
#block-block-45 .contact-info .email {
  padding-left: 20px;
}

#block-block-29 .contact-info .email img,
#block-block-30 .contact-info .email img,
#block-block-31 .contact-info .email img,
#block-block-44 .contact-info .email img,
#block-block-45 .contact-info .email img {
  width: 50px;
}

#block-block-29 .contact-info a,
#block-block-30 .contact-info a,
#block-block-31 .contact-info a,
#block-block-44 .contact-info a,
#block-block-45 .contact-info a {
  color: #fff;
  font-weight: 300;
}

#block-block-43 {
  text-align: left;
  clear: both;
  max-width: 300px;
  margin: 0 auto;
  background-color: #ebf3dc;
  border-radius: 10px;
  color: #536a27;
  padding: 1px 35px;
}

#block-block-43 h2 {
  text-align: center;
  color: #536a27;
}

#block-block-43 a {
  color: #536a27;
}

.page-node-46 .product-block h1 {
  font-size: 3em;
}

.page-node-46 .product-block .outdoor-icon {
  max-width: 400px;
  min-width: 220px;
  width: 60%;
}

.page-node-46 .product-block .wooden img {
  transform: scaleX(-1);
}

/*----------------------------Tablet Styles----------------------------*/
@media (min-width: 770px) {
  #block-block-29,
  #block-block-30,
  #block-block-31,
  #block-block-44,
  #block-block-45 {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: left;
    height: 317px;
  }
  #block-block-29 h1,
  #block-block-30 h1,
  #block-block-31 h1,
  #block-block-44 h1,
  #block-block-45 h1 {
    font-size: 3.5em;
    margin-right: 30px;
  }
  .node-46 .recycled .description {
    float: none;
    width: 100%;
    min-height: 111px;
  }
  .node-46 .recycled .outdoor-icon {
    margin: 0 auto;
    float: none;
    width: 85%;
  }
  .node-46 .wooden .description {
    float: none;
    width: 100%;
    min-height: 119px;
  }
  .node-46 .wooden .outdoor-icon {
    margin: 0 auto;
    float: none;
  }
}

/*--------------------------Landscape Tablet Styles-------------------*/
@media (min-width: 1024px) {
  #block-block-29 h1,
  #block-block-30 h1,
  #block-block-31 h1,
  #block-block-44 h1,
  #block-block-45 h1 {
    font-size: 4em;
    line-height: 3.7rem;
  }
  #block-block-43 {
    float: none;
    margin: 0 auto;
  }
}

/*---------------------------Desktop Styles-------------------------------*/
@media (min-width: 1240px) {
  .page-node-164 .narrow {
    max-width: 1200px;
  }
  .node-46 .product-block h1 {
    font-size: 3.5em;
  }
  .node-46 .recycled .description {
    float: left;
    width: 45%;
  }
  .node-46 .recycled .outdoor-icon {
    width: 45%;
    float: right;
    max-width: 251px;
  }
  .node-46 .wooden .description {
    float: left;
    width: 45%;
  }
  .node-46 .wooden .outdoor-icon {
    width: 39%;
    float: right;
  }
}

/*----------------------------shed selector styles -----------------*/
.page-node-151 #all-content {
  text-align: left;
  padding: 0 2%;
}

.page-node-151 #all-content #shedContainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 72%;
  height: 0;
  overflow: hidden;
}

.page-node-151 #all-content #shedContainer .shed-image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.page-node-151 #all-content #shedContainer .shed-image img {
  width: 95%;
  margin: 10px;
}

.page-node-151 #all-content .sub-info {
  width: 90%;
  margin: 0 auto;
}

.page-node-151 #all-content .sub-info #optionLinks a {
  color: #9e0b0f;
}

.page-node-151 #all-content .sub-info #optionLinks p {
  margin: .5rem;
}

.page-node-151 #all-content #a1 p,
.page-node-151 #all-content #a2 p,
.page-node-151 #all-content #a3 p,
.page-node-151 #all-content #a4 p {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 10%;
  max-width: 85px;
  min-width: 77px;
  margin: .5em;
  margin: 0 22px;
}

@media (min-width: 770px) {
  .page-node-151 #all-content #shedContainer {
    position: relative;
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 53%;
    height: 0;
    overflow: hidden;
  }
  .page-node-151 #all-content #a1 p,
  .page-node-151 #all-content #a2 p,
  .page-node-151 #all-content #a3 p,
  .page-node-151 #all-content #a4 p {
    text-align: center;
  }
}

@media (min-width: 1240px) {
  .page-node-151 #all-content #shedContainer {
    position: relative;
    position: relative;
    width: 60%;
    margin: 0 auto;
    padding-bottom: 43.2%;
    height: 0;
    overflow: hidden;
  }
}

.page-taxonomy-term-15 .page-title {
  display: none;
}

#block-block-32 {
  background-color: #92c6ce;
  color: #fff;
  text-align: right;
  height: 250px;
  margin-top: -6px;
}

#block-block-32 h1 {
  text-transform: uppercase;
  margin-right: 30px;
  font-family: "museo-sans";
  font-weight: 100;
  font-size: 3em;
  padding-top: .3em;
  margin-top: 0;
  letter-spacing: 3px;
}

#block-block-32 h1 span {
  font-weight: 900;
}

#block-block-32 h2 {
  font-family: "museo-sans";
  color: #fff;
  font-size: 2em;
  font-weight: 100;
  margin-right: 30px;
}

#block-block-32 h2 span {
  font-weight: 100;
}

#block-block-32 .contact-info {
  color: #fff;
  text-align: right;
  margin-right: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

#block-block-32 .contact-info h3 {
  line-height: 2rem;
  margin: 0;
}

#block-block-32 .contact-info .phone {
  font-weight: 300;
}

#block-block-32 .contact-info .phone img {
  width: 30px;
}

#block-block-32 .contact-info .email {
  padding-left: 20px;
}

#block-block-32 .contact-info .email img {
  width: 50px;
}

#block-block-32 .contact-info a {
  color: #fff;
  font-weight: 300;
}

.sheds-photo {
  background-image: url(/sites/default/files/families-images/shed.jpg);
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  height: 250px;
  border-radius: 0px 0px 10px 10px;
  display: block;
}

/*-------------------Tablet Styles ------------------------------------*/
@media (min-width: 770px) {
  #block-block-32 {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: left;
    height: 317px;
    padding-top: 1.5em;
    margin-top: 0px;
  }
  #block-block-32 h1 {
    font-size: 3.5em;
  }
  .display-photo {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: right;
    min-height: 278px;
  }
}

/*--------------------------Landscape Tablet Styles ------------------------*/
@media (min-width: 1024px) {
  .sheds-photo {
    border-radius: 0px 10px 10px 0px;
    min-height: 308px;
  }
}

/*----------------------Desktop Styles---------------------------*/
/*--------------------------Contact Us Page---------------------*/
.page-node-16 .narrow {
  text-align: center;
}

.page-node-16 .narrow .location {
  width: 75%;
  max-width: 495px;
  min-width: 300px;
  max-height: 245px;
  margin: 20px;
  box-shadow: 0 0 6px #aaaaaa;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
}

.page-node-16 .narrow .location h2 {
  font-size: 1.13em;
  color: white !important;
  background-color: #1d7db3;
  margin-top: 0px;
  padding-top: 10px;
  height: 55px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.page-node-16 .narrow .location p {
  padding: 0 .7em;
}

.page-node-16 .narrow .location .maps {
  background-color: #f3d476;
  color: #9e0b0f;
  border: 2px solid white;
  box-shadow: 0 0 5px #707070;
  text-decoration: none;
  padding: 8px;
}

.page-node-16 .narrow .location .tele {
  text-decoration: none;
  color: #9e0b0f;
  font-size: 1.5em;
}

.page-node-16 #mini-panel-contact_us_form a {
  font-weight: 600;
  text-decoration: underline;
}

@media (min-width: 770px) {
  .page-node-16 #block-panels-mini-contact-us-form {
    width: 75%;
    margin: auto;
  }
  .page-node-16 .narrow {
    width: 45%;
    float: right;
  }
}

#block-block-38 {
  background-color: #f7cf57;
  color: #fff;
  text-align: right;
  height: 289px;
  margin-top: -6px;
}

#block-block-38 h1 {
  text-transform: uppercase;
  margin-right: 30px;
  font-family: "museo-sans";
  font-weight: 100;
  font-size: 3em;
  padding-top: .5em;
  margin-top: 0;
  letter-spacing: 3px;
}

#block-block-38 h1 span {
  font-weight: 900;
}

#block-block-38 h2 {
  font-family: "museo-sans";
  color: #fff;
  font-size: 2em;
  font-weight: 100;
  margin-right: 30px;
}

#block-block-38 h2 span {
  font-weight: 100;
}

#block-block-38 .contact-info {
  color: #fff;
  text-align: right;
  margin-right: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

#block-block-38 .contact-info h3 {
  line-height: 2rem;
  margin: 0;
}

#block-block-38 .contact-info .phone {
  font-weight: 300;
}

#block-block-38 .contact-info .phone img {
  width: 30px;
}

#block-block-38 .contact-info .email {
  padding-left: 20px;
}

#block-block-38 .contact-info .email img {
  width: 50px;
}

#block-block-38 .contact-info a {
  color: #fff;
  font-weight: 300;
}

.contact-photo {
  background-image: url(/sites/default/files/families-images/workstation-336369.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 240px;
}

.page-node-16 .page-title {
  color: #1d7db3;
}

.page-node-16 .narrow h2 {
  color: #9e0b0f;
  text-align: center;
}

#block-block-42 {
  clear: both;
}

#block-block-42 .locate {
  text-align: center;
}

#block-block-42 .locate-head {
  color: #9e0b0f;
}

#block-block-42 .location {
  width: 75%;
  max-width: 495px;
  max-height: 245px;
  margin: 20px 40px;
  box-shadow: 0 0 6px #aaaaaa;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
}

#block-block-42 .location h2 {
  font-size: 1.13em;
  color: white !important;
  background-color: #1d7db3;
  margin-top: 0px;
  padding-top: 10px;
  height: 55px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#block-block-42 .location p {
  padding: 0 .7em;
}

#block-block-42 .location .maps {
  background-color: #f3d476;
  color: #9e0b0f;
  border: 2px solid white;
  box-shadow: 0 0 5px #707070;
  text-decoration: none;
  padding: 8px;
}

#block-block-42 .location .tele {
  text-decoration: none;
  color: #9e0b0f;
  font-size: 1.5em;
}

#block-block-42 #info-box {
  background-color: #eaeaea;
  width: 80%;
  color: #9e0b0f;
  margin: 2em auto -6px auto;
  padding: 1em 2em;
  text-align: left;
  border-radius: 10px;
}

.pane-webform-client-block-52 {
  width: 80%;
  max-width: 553px;
  padding: 0 2em;
  text-align: center;
  margin: 0 auto;
}

.pane-webform-client-block-52 .pane__title {
  color: #1d7db3;
}

.pane-webform-client-block-52 .resizable-textarea {
  max-width: 553px;
  margin: 0 auto;
}

.pane-webform-client-block-52 .form-item.webform-component.webform-component-select.webform-component--please-contact-me label {
  font-size: 14px;
  font-weight: 300;
  color: #1d7db3;
}

.pane-webform-client-block-52 .form-item.webform-component.webform-component-select.webform-component--please-contact-me select#edit-submitted-please-contact-me {
  color: #1d7db3;
  font-size: 14px;
}

.pane-webform-client-block-52 input.webform-submit.button-primary.form-submit {
  background-color: #f7cf57;
  color: #9e0b0f;
  border: 2px solid white;
  box-shadow: 0 0 5px #707070;
  text-decoration: none;
  padding: 1px 20px;
}

@media (min-width: 770px) {
  .pane-webform-client-block-52 {
    width: 45%;
    max-width: 553px;
    min-width: 300px;
    padding: 0 2em;
    text-align: center;
    float: left;
  }
  .pane-webform-client-block-52 .pane__title {
    color: #1d7db3;
  }
  .form-item.webform-component.webform-component-select.webform-component--please-contact-me {
    float: left;
    margin-top: -10px;
    text-align: left;
  }
  input.webform-submit.button-primary.form-submit {
    float: right;
    padding-top: 8px;
  }
  #block-block-38 {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: left;
    height: 317px;
    padding-top: 2em;
    margin-top: 0px;
  }
  #block-block-38 h1 {
    font-size: 3.5em;
  }
  .contact-photo {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: right;
    height: 261px;
  }
}

/*-------------------------About Us Page---------------------*/
#block-block-35 {
  background-color: #aaaaaa;
  color: #fff;
  text-align: right;
  height: 289px;
  margin-top: -6px;
}

#block-block-35 h1 {
  text-transform: uppercase;
  margin-right: 30px;
  font-family: "museo-sans";
  font-weight: 100;
  font-size: 3em;
  padding-top: .5em;
  margin-top: 0;
  letter-spacing: 3px;
}

#block-block-35 h1 span {
  font-weight: 900;
}

#block-block-35 h2 {
  font-family: "museo-sans";
  color: #fff;
  font-size: 2em;
  font-weight: bold;
  margin-right: 30px;
}

#block-block-35 h2 span {
  font-weight: 100;
}

#block-block-35 .contact-info {
  color: #fff;
  text-align: right;
  margin-right: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

#block-block-35 .contact-info h3 {
  line-height: 2rem;
  margin: 0;
}

#block-block-35 .contact-info .phone {
  font-weight: 300;
}

#block-block-35 .contact-info .phone img {
  width: 30px;
}

#block-block-35 .contact-info .email {
  padding-left: 20px;
}

#block-block-35 .contact-info .email img {
  width: 50px;
}

#block-block-35 .contact-info a {
  color: #fff;
  font-weight: 300;
}

#block-views-structure-banner-block-1,
#block-views-structure-banner-block-2,
#block-views-structure-banner-block-3,
#block-views-structure-banner-block-4,
#block-views-structure-banner-block-5 {
  height: 240px;
  overflow: hidden;
}

#block-views-structure-banner-block-1 .view-outdoor-living-banner,
#block-views-structure-banner-block-2 .view-outdoor-living-banner,
#block-views-structure-banner-block-3 .view-outdoor-living-banner,
#block-views-structure-banner-block-4 .view-outdoor-living-banner,
#block-views-structure-banner-block-5 .view-outdoor-living-banner {
  width: 100%;
  float: right;
}

#block-views-structure-banner-block-1 .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-2 .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-3 .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-4 .view-outdoor-living-banner .views-slideshow-cycle-main-frame,
#block-views-structure-banner-block-5 .view-outdoor-living-banner .views-slideshow-cycle-main-frame {
  width: 100% !important;
  height: auto;
}

#block-views-structure-banner-block-1 .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-2 .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-3 .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-4 .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row,
#block-views-structure-banner-block-5 .view-outdoor-living-banner .views-slideshow-cycle-main-frame-row {
  width: 100% !important;
  height: auto;
}

#block-views-structure-banner-block-1 .view-outdoor-living-banner .field-content,
#block-views-structure-banner-block-2 .view-outdoor-living-banner .field-content,
#block-views-structure-banner-block-3 .view-outdoor-living-banner .field-content,
#block-views-structure-banner-block-4 .view-outdoor-living-banner .field-content,
#block-views-structure-banner-block-5 .view-outdoor-living-banner .field-content {
  max-width: 100%;
  width: 100%;
}

#block-views-structure-banner-block-1 .view-outdoor-living-banner .field-content img,
#block-views-structure-banner-block-2 .view-outdoor-living-banner .field-content img,
#block-views-structure-banner-block-3 .view-outdoor-living-banner .field-content img,
#block-views-structure-banner-block-4 .view-outdoor-living-banner .field-content img,
#block-views-structure-banner-block-5 .view-outdoor-living-banner .field-content img {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  min-width: 590px;
  margin-top: -65px;
}

.page-node-15 .page-title {
  color: #1d7db3;
}

.page-node-15 .info-box {
  text-align: center;
}

.page-node-15 .info-box .about-heading-1 {
  color: #1d7db3;
  letter-spacing: 4px;
  font-size: 2rem;
  margin-bottom: 0;
}

@media (min-width: 600px) {
  .page-node-15 .info-box .about-heading-1 {
    letter-spacing: 9px;
    font-size: 3rem;
  }
}

.page-node-15 .info-box .about-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.page-node-15 .info-box ul {
  list-style: none;
  text-align: left;
  padding-left: 0em;
  min-width: 300px;
}

@media (min-width: 548px) {
  .page-node-15 .info-box ul {
    padding: 0 4em;
  }
}

.page-node-15 .info-box ul span {
  font-weight: bold;
  color: #1d7db3;
}

.page-node-15 .info-box .left {
  height: 175px;
  width: 175px;
  border-radius: 50%;
  border: solid 4px #eaeaea;
}

.page-node-15 .about-heading,
.page-node-15 .acro {
  color: #9e0b0f;
  text-align: center;
  font-size: 1.75rem;
}

.page-node-15 .about-family {
  margin: 0 auto;
  display: block;
  border: solid 4px #eaeaea;
  width: 35vw;
}

.page-node-15 .do {
  border-top: 10px dotted #92c6ce;
  clear: both;
}

.page-node-15 .about-left,
.page-node-15 .about-right {
  list-style: none;
  text-align: center;
  padding: 0;
}

.page-node-15 .about-left h3,
.page-node-15 .about-right h3 {
  font-family: "mr-eaves-xl-modern";
  font-size: 2rem;
}

.page-node-15 .about-left p,
.page-node-15 .about-right p {
  color: #1d7db3;
  font-size: 20px;
}

@media (min-width: 770px) {
  .page-node-15 .about-left {
    width: 45%;
    float: left;
  }
  .page-node-15 .about-right {
    width: 45%;
    float: right;
  }
}

.page-node-15 .photo-label {
  text-align: center;
}

.page-node-15 .about-end {
  color: #1d7db3;
}

.page-node-15 .signature {
  font-family: "gioviale";
  font-weight: 600;
  text-align: right;
  font-size: 30px;
}

@media (min-width: 770px) {
  #block-views-structure-banner-block-1,
  #block-views-structure-banner-block-2,
  #block-views-structure-banner-block-3,
  #block-views-structure-banner-block-4,
  #block-views-structure-banner-block-5 {
    width: 50vw;
    float: right;
    min-height: 317px;
    max-width: 50%;
    overflow: hidden;
  }
  #block-views-structure-banner-block-1 .field-content img,
  #block-views-structure-banner-block-2 .field-content img,
  #block-views-structure-banner-block-3 .field-content img,
  #block-views-structure-banner-block-4 .field-content img,
  #block-views-structure-banner-block-5 .field-content img {
    float: right;
    max-width: 50vw;
    min-width: 550px;
  }
  #block-block-35 {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: left;
    height: 317px;
    padding-top: 2em;
    margin-top: 0px;
  }
  #block-block-35 h1 {
    font-size: 3.5em;
  }
  .about-photo {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: right;
    height: 261px;
  }
}

/*--------------------Buyer's Guide Page----------------------*/
#block-block-40 {
  background-color: #f7cf57;
  color: #fff;
  text-align: right;
  height: 289px;
  margin-top: -6px;
}

#block-block-40 h1 {
  text-transform: uppercase;
  margin-right: 30px;
  font-family: "museo-sans";
  font-weight: 100;
  font-size: 3em;
  padding-top: .5em;
  margin-top: 0;
  letter-spacing: 3px;
}

#block-block-40 h1 span {
  font-weight: 900;
}

#block-block-40 h2 {
  font-family: "museo-sans";
  color: #fff;
  font-size: 2em;
  font-weight: 100;
  margin-right: 30px;
}

#block-block-40 h2 span {
  font-weight: 100;
}

#block-block-40 .contact-info {
  color: #fff;
  text-align: right;
  margin-right: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

#block-block-40 .contact-info h3 {
  line-height: 2rem;
  margin: 0;
}

#block-block-40 .contact-info .phone {
  font-weight: 300;
}

#block-block-40 .contact-info .phone img {
  width: 30px;
}

#block-block-40 .contact-info .email {
  padding-left: 20px;
}

#block-block-40 .contact-info .email img {
  width: 50px;
}

#block-block-40 .contact-info a {
  color: #fff;
  font-weight: 300;
}

.guide-photo {
  background-image: url(/sites/default/files/families-images/workstation-336369.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 240px;
}

.page-node-14 .buyers {
  text-align: center;
  font-weight: bold;
}

.page-node-14 .narrow h2 {
  color: #1d7db3;
}

.page-node-14 .narrow h2 a {
  color: #1d7db3;
  text-decoration: underline;
}

iframe {
  height: 430px;
}

@media (min-width: 770px) {
  #block-block-40 {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: left;
    height: 317px;
    padding-top: 2em;
    margin-top: 0px;
  }
  #block-block-40 h1 {
    font-size: 3.5em;
  }
  .guide-photo {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: right;
    height: 261px;
  }
}

/*------------------Blog and Taste and See Page -----------------------------*/
#block-block-37 {
  background-color: #92c6ce;
  color: #fff;
  text-align: right;
  height: 289px;
  margin-top: -6px;
}

#block-block-37 h1 {
  text-transform: uppercase;
  margin-right: 30px;
  font-family: "museo-sans";
  font-weight: 100;
  font-size: 3em;
  padding-top: .5em;
  margin-top: 0;
  letter-spacing: 3px;
}

#block-block-37 h1 span {
  font-weight: 900;
}

#block-block-37 h2 {
  font-family: "museo-sans";
  color: #fff;
  font-size: 2em;
  font-weight: 100;
  margin-right: 30px;
}

#block-block-37 h2 span {
  font-weight: 100;
}

#block-block-37 .contact-info {
  color: #fff;
  text-align: right;
  margin-right: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

#block-block-37 .contact-info h3 {
  line-height: 2rem;
  margin: 0;
}

#block-block-37 .contact-info .phone {
  font-weight: 300;
}

#block-block-37 .contact-info .phone img {
  width: 30px;
}

#block-block-37 .contact-info .email {
  padding-left: 20px;
}

#block-block-37 .contact-info .email img {
  width: 50px;
}

#block-block-37 .contact-info a {
  color: #fff;
  font-weight: 300;
}

.blog-photo {
  background-image: url(/sites/default/files/families-images/workstation-336369.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 240px;
}

.page-blog .page-title {
  display: none;
}

.page-blog .view-display-id-block_1 {
  margin-top: 0px;
}

.section-blog .page-title,
.node-type-article .page-title {
  color: #1d7db3;
}

.section-blog .node-article,
.node-type-article .node-article {
  width: 70vw;
  margin: 0 auto;
}

.section-blog .node-article .field-name-body p:first-child,
.node-type-article .node-article .field-name-body p:first-child {
  margin-top: 0px;
}

.section-blog .view-display-id-block_1,
.node-type-article .view-display-id-block_1 {
  background-color: #fcf3d9;
  border: 2px solid #f7cf57;
  width: 240px;
  border-radius: 10px;
  margin: 0 auto;
  padding: 0 1em 1em;
  text-align: left;
  display: none;
}

.section-blog .view-display-id-block_1 .view-header,
.node-type-article .view-display-id-block_1 .view-header {
  color: #9e0b0f;
}

.section-blog .view-display-id-block_1 .view-header p,
.node-type-article .view-display-id-block_1 .view-header p {
  font-family: "mr-eaves-xl-sans-narrow";
  font-weight: bold;
  font-size: 26px;
}

.section-blog .view-display-id-block_1 .view-content a,
.node-type-article .view-display-id-block_1 .view-content a {
  color: #1d7db3;
}

@media (min-width: 1024px) {
  .section-blog .view-display-id-block_1,
  .node-type-article .view-display-id-block_1 {
    display: block;
  }
}

.section-blog .view-blog.view-display-id-page,
.node-type-article .view-blog.view-display-id-page {
  width: 70vw;
  margin: 0 auto;
}

.section-blog .view-blog.view-display-id-page .views-row,
.node-type-article .view-blog.view-display-id-page .views-row {
  border-bottom: 2px dashed #eaeaea;
  padding-top: 30px;
}

.section-blog .view-blog.view-display-id-page .views-row a,
.node-type-article .view-blog.view-display-id-page .views-row a {
  color: #1d7db3;
  font-size: 25px;
  font-family: "museo-sans";
  font-weight: bold;
}

.section-blog .view-blog.view-display-id-page .views-row:first-child,
.node-type-article .view-blog.view-display-id-page .views-row:first-child {
  padding-top: 0px;
}

@media (min-width: 770px) {
  .section-blog #block-block-37,
  .node-type-article #block-block-37 {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: left;
    height: 317px;
    padding-top: 2em;
    margin-top: 0px;
  }
  .section-blog #block-block-37 h1,
  .node-type-article #block-block-37 h1 {
    font-size: 3.5em;
  }
  .section-blog .blog-photo,
  .node-type-article .blog-photo {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: right;
    height: 261px;
  }
}

@media (min-width: 1024px) {
  .section-blog #block-views-blog-block-1,
  .section-blog .view-blog.view-display-id-block_1,
  .node-type-article #block-views-blog-block-1,
  .node-type-article .view-blog.view-display-id-block_1 {
    float: left;
    margin-left: 5%;
  }
  .section-blog .view-blog.view-display-id-page,
  .section-blog .node-article,
  .node-type-article .view-blog.view-display-id-page,
  .node-type-article .node-article {
    width: 62%;
    float: right;
    margin-right: 7vw;
    text-align: left;
  }
}

#block-block-48 {
  background-color: #9f0602;
  color: #fff;
  text-align: right;
  height: 289px;
  margin-top: -6px;
}

#block-block-48 h1 {
  text-transform: uppercase;
  margin-right: 30px;
  font-family: "museo-sans";
  font-weight: 100;
  font-size: 3em;
  padding-top: .5em;
  margin-top: 0;
  letter-spacing: 3px;
}

#block-block-48 h1 span {
  font-weight: 900;
}

#block-block-48 h2 {
  font-family: "museo-sans";
  color: #fff;
  font-size: 2em;
  font-weight: 100;
  margin-right: 30px;
}

#block-block-48 h2 span {
  font-weight: 100;
}

#block-block-48 .contact-info {
  color: #fff;
  text-align: right;
  margin-right: 30px;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

#block-block-48 .contact-info h3 {
  line-height: 2rem;
  margin: 0;
}

#block-block-48 .contact-info .phone {
  font-weight: 300;
}

#block-block-48 .contact-info .phone img {
  width: 30px;
}

#block-block-48 .contact-info .email {
  padding-left: 20px;
}

#block-block-48 .contact-info .email img {
  width: 50px;
}

#block-block-48 .contact-info a {
  color: #fff;
  font-weight: 300;
}

.page-taste-and-see .page-title {
  display: none;
}

.page-taste-and-see .view-display-id-block_1 {
  margin-top: 0px;
}

.section-taste-and-see .page-title,
.node-type-taste-and-see-post .page-title {
  color: #1d7db3;
}

.section-taste-and-see .node-taste-and-see-post,
.node-type-taste-and-see-post .node-taste-and-see-post {
  width: 70vw;
  margin: 0 auto;
}

.section-taste-and-see .node-taste-and-see-post .field-name-body p:first-child,
.node-type-taste-and-see-post .node-taste-and-see-post .field-name-body p:first-child {
  margin-top: 0px;
}

.section-taste-and-see .field-name-field-image .field-items,
.node-type-taste-and-see-post .field-name-field-image .field-items {
  display: flex;
  flex-wrap: wrap;
}

.section-taste-and-see .field-name-field-image .field-item,
.node-type-taste-and-see-post .field-name-field-image .field-item {
  max-width: 400px;
  min-width: 300px;
  width: 30%;
}

.section-taste-and-see .view-display-id-block_1,
.node-type-taste-and-see-post .view-display-id-block_1 {
  background-color: #fcf3d9;
  border: 2px solid #f7cf57;
  width: 240px;
  border-radius: 10px;
  margin: 0 auto;
  padding: 0 1em 1em;
  text-align: left;
  display: none;
}

.section-taste-and-see .view-display-id-block_1 .view-header,
.node-type-taste-and-see-post .view-display-id-block_1 .view-header {
  color: #9e0b0f;
}

.section-taste-and-see .view-display-id-block_1 .view-header p,
.node-type-taste-and-see-post .view-display-id-block_1 .view-header p {
  font-family: "mr-eaves-xl-sans-narrow";
  font-weight: bold;
  font-size: 26px;
}

.section-taste-and-see .view-display-id-block_1 .view-content a,
.node-type-taste-and-see-post .view-display-id-block_1 .view-content a {
  color: #1d7db3;
}

@media (min-width: 1024px) {
  .section-taste-and-see .view-display-id-block_1,
  .node-type-taste-and-see-post .view-display-id-block_1 {
    display: block;
  }
}

.section-taste-and-see .view-taste-and-see.view-display-id-page,
.node-type-taste-and-see-post .view-taste-and-see.view-display-id-page {
  width: 70vw;
  margin: 0 auto;
}

.section-taste-and-see .view-taste-and-see.view-display-id-page .view-header img,
.node-type-taste-and-see-post .view-taste-and-see.view-display-id-page .view-header img {
  max-width: 350px;
  margin: 0 auto;
}

.section-taste-and-see .view-taste-and-see.view-display-id-page .views-row,
.node-type-taste-and-see-post .view-taste-and-see.view-display-id-page .views-row {
  border-bottom: 2px dashed #eaeaea;
  padding-top: 30px;
}

.section-taste-and-see .view-taste-and-see.view-display-id-page .views-row a,
.node-type-taste-and-see-post .view-taste-and-see.view-display-id-page .views-row a {
  color: #1d7db3;
  font-size: 25px;
  font-family: "museo-sans";
  font-weight: bold;
}

.section-taste-and-see .view-taste-and-see.view-display-id-page .views-row:first-child,
.node-type-taste-and-see-post .view-taste-and-see.view-display-id-page .views-row:first-child {
  padding-top: 0px;
}

@media (min-width: 770px) {
  .section-taste-and-see #block-block-48,
  .node-type-taste-and-see-post #block-block-48 {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: left;
    height: 317px;
    padding-top: 2em;
    margin-top: 0px;
  }
  .section-taste-and-see .blog-photo,
  .node-type-taste-and-see-post .blog-photo {
    width: 50vw;
    max-width: 50%;
    overflow: hidden;
    float: right;
    height: 261px;
  }
}

@media (min-width: 1024px) {
  .section-taste-and-see #block-block-48 h1,
  .node-type-taste-and-see-post #block-block-48 h1 {
    font-size: 3.5em;
  }
  .section-taste-and-see #block-views-blog-block-1,
  .section-taste-and-see .view-taste-and-see.view-display-id-block_1,
  .node-type-taste-and-see-post #block-views-blog-block-1,
  .node-type-taste-and-see-post .view-taste-and-see.view-display-id-block_1 {
    float: left;
    margin-left: 5%;
  }
  .section-taste-and-see .view-taste-and-see.view-display-id-page,
  .section-taste-and-see .node-taste-and-see-post,
  .node-type-taste-and-see-post .view-taste-and-see.view-display-id-page,
  .node-type-taste-and-see-post .node-taste-and-see-post {
    width: 62%;
    float: right;
    margin-right: 7vw;
    text-align: left;
  }
}

/*----------------- Delivery Page -------------------------*/
.page-node-48 .page-title {
  color: #1d7db3;
}

.page-node-48 .narrow h2,
.page-node-48 .narrow p,
.page-node-48 .narrow a {
  color: #1d7db3;
}

.page-node-48 .map-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  background-color: #eaeaea;
  border-radius: 10px;
  margin: 20px;
  padding: 0px 0px 25px;
}

.page-node-48 .map-flex .directions {
  width: 51%;
  min-width: 300px;
  max-width: 400px;
}

.page-node-48 .map-flex iframe {
  border: 3px solid white;
  height: 300px;
}

.page-node-48 .map-flex img {
  border: solid 4px #fff;
}

.page-node-48 .map-flex p {
  margin-top: 8px;
  margin-bottom: 0px;
}

.page-node-48 .map-flex .left {
  float: left;
  margin-left: 46px;
}

.page-node-48 .map-flex .right {
  float: right;
  margin-right: 46px;
}

.page-node-48 .map-flex .country {
  clear: both;
}

.page-node-48 .map-flex .hidden {
  display: none;
}

.page-node-48 .delivery-icons {
  width: 100%;
  text-align: center;
  color: #1d7db3;
  margin: 0 auto;
}

.page-node-48 .delivery-icons #outdoor,
.page-node-48 .delivery-icons #playsets,
.page-node-48 .delivery-icons #structures {
  margin: 0 auto;
}

.page-node-48 .flex-icon {
  padding-top: 1.5em;
}

.page-node-48 .flex-icon h2 {
  font-size: 1.4em;
  line-height: 1.5;
  margin-top: .5rem;
}

.page-node-48 .flex-icon h3 {
  font-size: 1.1em;
  line-height: 1.5;
  margin-top: .5rem;
}

.page-node-48 .circle {
  width: 70%;
  padding-bottom: 70%;
}

@media (min-width: 1024px) {
  .page-node-48 .circle {
    width: 45%;
    padding-bottom: 45%;
  }
}

.page-node-48 .map-tool {
  color: #9e0b0f;
  text-align: center;
  font-size: 1.5rem;
}

@media (min-width: 1024px) {
  .page-node-48 #block-block-45 h1 {
    font-size: 2.7em;
  }
  .page-node-48 .delivery-icons {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .page-node-48 .flex-icon {
    display: flex;
    flex-direction: column;
    width: 33%;
    vertical-align: top;
    padding: 0px 10px;
  }
}

@media (min-width: 900px) {
  .page-node-48 #block-block-45 h1 {
    font-size: 3.5em;
  }
}

/*------------------------------Mulch and Border Page -------------------------------*/
#block-block-6 {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.simpleTabsContent {
  width: 500px;
}

#rubber_calculator_box {
  background-color: #ebf3dc;
  padding: 0 1em;
  border-radius: 10px;
  width: 100%;
  min-width: 285px;
}

#rubber_calculator_box h2 {
  color: #536a27;
  text-align: center;
  padding-top: 20px;
}

#calculator_box {
  background-color: #dbd1c3;
  padding: 0 1em;
  border-radius: 10px;
  width: 100%;
  min-width: 285px;
}

#calculator_box h2 {
  color: #5e4d36;
  text-align: center;
  padding-top: 20px;
}

.mulch-photos {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
}

.mulch-photos .mulch-flex {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 1em;
}

/*-----------------------------Reviews Page-------------------------------*/
.review-first {
  text-align: center;
}

.review-first a {
  font-weight: 600;
  text-decoration: underline;
}

.review-text {
  max-width: 34em !important;
}

.reviews-links {
  text-align: center;
}

.google-rev {
  width: 100px;
  vertical-align: middle;
  margin-bottom: 5px;
}

.facebook-rev {
  width: 35px;
}

.field-name-field-review {
  width: 70vw;
  margin: 0 auto;
}

.field-collection-container {
  border-bottom: none;
}

.field-collection-view {
  border: 1px solid #eaeaea;
  padding: 1em;
  margin: 1em;
}

.field-name-field-review-text {
  clear: both;
  padding: 1em 0;
  font-weight: 300;
}

.field-name-field-name-and-place {
  font-weight: bold;
  color: #aaaaaa;
}

/*--------------------------------Shed Options Page--------------------*/
.page-node-171 .narrow {
  text-align: center;
}

.page-node-171 .page-title {
  color: #92c6ce;
}

.page-node-171 .options-head {
  color: #1d7db3;
}

.page-node-171 .options-title {
  color: #9e0b0f;
  font-weight: bold;
  font-family: "mr-eaves-xl-sans-narrow";
  text-align: center;
  font-size: 2rem;
  padding: .5em 3em;
  width: 100%;
  margin: 0 auto;
  border: 2px solid #f7cf57;
  background-color: #fcf3d9;
  line-height: 1.2;
}

.page-node-171 .options-flex-1,
.page-node-171 .options-flex-2,
.page-node-171 .options-flex-3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2em;
  justify-content: space-around;
  align-items: baseline;
}

.page-node-171 .options-flex-2 .flex-items,
.page-node-171 .options-flex-3 .flex-items {
  width: 15%;
  min-width: 190px;
}

.page-node-171 .options-flex-2 img,
.page-node-171 .options-flex-3 img {
  width: 150px;
}

.page-node-171 .options-flex-2 .enlarge,
.page-node-171 .options-flex-3 .enlarge {
  transition: all 1s ease-in-out .2s;
}

.page-node-171 .options-flex-2 .enlarge:hover,
.page-node-171 .options-flex-3 .enlarge:hover {
  transform: scale(1.5);
}

.page-node-171 .flex-items {
  width: 30%;
  min-width: 300px;
}

.page-node-171 .flex-items img {
  border: 3px solid white;
  box-shadow: 1px 1px 6px #707070;
}

/*---------------------------------Construction Specs Page-------------------------*/
.page-node-164 .page-title {
  color: #707070;
}

.page-node-164 .specs-head {
  color: #9e0b0f;
  text-align: center;
}

.page-node-164 .big-specs,
.page-node-164 .specs-flex {
  clear: both;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2em;
  justify-content: space-around;
  align-items: baseline;
}

.page-node-164 .flex-items {
  width: 30%;
  min-width: 300px;
}

.page-node-164 .flex-items img {
  border: 3px solid white;
  box-shadow: 1px 1px 6px #707070;
}

.page-node-164 .small-pict {
  width: 20%;
  margin: 30px 3px;
  min-width: 100px;
  border: 2px solid white;
  box-shadow: 1px 1px 6px #707070;
  transition: all 1s ease-in-out .2s;
}

.page-node-164 .small-pict:hover {
  transform: scale(1.5);
}

/*---------------------------------Construction Specs Page-------------------------*/
.page-node-163 .page-title {
  color: #1d7db3;
}

.page-node-163 .right {
  float: right;
}

.page-node-163 .reasons-head {
  color: #9e0b0f;
  text-align: center;
}

.page-node-163 h3 {
  font-family: "mr-eaves-xl-modern";
  font-size: 20px;
}

/*---------------------------------Site Prep Page-------------------------*/
.page-node-172 .page-title {
  color: #1d7db3;
}

.page-node-172 .prep {
  color: #707070;
}

/*------------------------------Poly Colors Page--------------------------*/
.page-title-2 {
  color: #536a27;
  font-family: "museo-sans";
  font-weight: 900;
  font-size: 3em;
}

.page-title-2 span {
  font-weight: 100;
}

.warnty {
  display: none;
}

@media (min-width: 770px) {
  .warnty {
    display: block;
    float: right;
  }
}

.center {
  text-align: center;
  font-size: 1.2rem;
}

.colors {
  font-family: "gioviale";
  color: #1d7db3;
  font-size: 3rem;
  text-align: center;
}

.colors-2 {
  font-family: "museo-sans";
  color: #9e0b0f;
  font-size: 1.5rem;
}

.color-flex {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  margin: auto;
  width: 90%;
  min-width: 300px;
}

.color-flex .poly-color {
  width: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
}

.fabric {
  background-color: #ebf3dc;
  width: 85%;
  margin: 0 auto;
}

.fabric a {
  color: #536a27;
}

.fabric .text {
  color: #536a27;
}

.fabric .text p:last-child {
  text-align: center;
}

.fabric .text p:last-child .view-fabrics {
  color: #ebf3dc;
  background-color: #536a27;
  text-align: center;
  padding: 7px 18px;
}

.fabric .fabrics-photo {
  background-image: url(/sites/default/files/families-images/fabric-color-options.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 250px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .fabric .text {
    float: left;
    width: 40%;
  }
}

/*------------------------------Designer Page--------------------------*/
.page-node-176 iframe {
  height: 600px;
  display: block;
  margin: 0 auto;
}

/*--------------------------Admin page ------------------------------*/
#user-login {
  margin: 0 auto;
  width: 80%;
  max-width: 500px;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1165px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.clearfix:before,
.header:before,
.tabs:before {
  content: '';
  display: table;
}

.clearfix:after,
.header:after,
.tabs:after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Turn
 */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Turn Right
 */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*---------------------- Universal, Header, Menu and Footer stlying in the this file ---------------*/
div.contextual-links-wrapper {
  z-index: 500;
}

.pager {
  display: none;
}

strong {
  font-weight: bold;
}

p {
  font-family: "museo-sans";
  font-weight: 300;
}

.feed-icon {
  display: none;
}

#mmenu_right {
  background-color: #fcf3d9;
  color: #9e0b0f;
}

#mmenu_right .icon-list2 {
  display: none;
}

.mm-list > li > a, .mm-list > li > span {
  padding: 0;
}

.mm-menu .mm-list > li > a.mm-subclose {
  background: transparent;
  color: #9e0b0f;
  padding-bottom: 10px;
}

.mm-list a.mm-subclose:before {
  content: '';
  display: inline-block;
  border-top: 2px solid #9e0b0f !important;
  border-left: 2px solid #9e0b0f !important;
  color: #9e0b0f;
  margin-bottom: 0px;
  font-weight: bold;
  bottom: 41%;
}

.mmenu-nav.mm-basic > .mm-header {
  color: #1d7db3;
  background: #fcf3d9 !important;
  border-color: transparent;
  font-style: italic;
  font-size: 1.35em;
  font-weight: bold;
}

.mmenu-nav.mm-basic > .mm-footer {
  color: #1d7db3;
  background-color: #fcf3d9;
  font-style: italic;
  font-weight: bold;
}

.mmenu-block-wrap {
  border-bottom: none;
}

.mm-footer {
  border-top: none;
  font-weight: bold;
}

.mmenu-nav.mm-basic {
  text-shadow: none;
  text-transform: uppercase;
}

.mmenu-nav.mm-basic .mm-subopen {
  border-left: 2px solid #f3d476;
}

.mmenu-nav.mm-basic .mm-subopen:after,
.mmenu-nav.mm-basic .mmenu-nav.mm-basic .mm-subclose:after {
  border-color: #9e0b0f !important;
}

.mmenu-nav.mm-basic > .mm-list li {
  border-bottom: 2px solid #f3d476;
  border-top: none;
}

.mmenu-nav.mm-basic > .mm-list li:first-child {
  border-top: solid 2px #f3d476;
}

a {
  text-decoration: none;
}

.layout-center {
  padding: 0;
  max-width: 100vw;
}

.page-node-1 .layout-3col__full {
  padding-right: 0px;
}

@media (min-width: 555px) {
  .page-node-1 .layout-3col__full {
    margin-top: -38px;
  }
}

@media (min-width: 1024px) {
  .page-node-1 .layout-3col__full {
    margin-top: 0px;
  }
}

.layout-3col__full {
  padding-right: 0px;
}

@media (min-width: 555px) {
  .layout-3col__full {
    margin-top: -48px;
  }
}

@media (min-width: 1024px) {
  .layout-3col__full {
    margin-top: -40px;
  }
}

.layout-3col {
  margin-right: 0px;
}

.breadcrumb {
  font-weight: 100;
  padding: 10px 20px 0px;
  margin-left: 7vw;
  color: #536a27;
  clear: both;
}

.narrow {
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
}

.body {
  margin: 0 auto;
}

#block-block-1 {
  margin-top: -24px;
  position: relative;
  z-index: 5;
}

#right-mmenu-trigger {
  float: right;
  z-index: 5;
  margin-right: 1.5em;
  margin-top: -30px;
}

#right-mmenu-trigger .hamburger-box {
  width: 56px !important;
  height: 43px !important;
  background-color: #fcf3d9;
  padding-left: 8px;
  border-radius: 8px;
  padding-bottom: 48px;
  z-index: 5;
}

#right-mmenu-trigger .hamburger-box .hamburger-inner,
#right-mmenu-trigger .hamburger-box .hamburger-inner:before,
#right-mmenu-trigger .hamburger-box .hamburger-inner:after {
  background-color: #f3d476;
  z-index: 12;
}

#block-block-2 {
  background-image: url(/sites/default/files/families-images/header-background.jpg);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  border-bottom: 4px solid #f7cf57;
  max-height: 230px;
  z-index: 4;
}

#block-block-2 img {
  max-width: 368px;
  margin-top: -9px;
  width: 57vw;
}

#block-block-2 p {
  margin: 0;
}

#block-superfish-1 {
  background-color: #fcf3d9;
  border-top: 2px solid #f7cf57;
  border-bottom: 2px solid #f7cf57;
  height: 3em;
  width: 100vw;
  display: none;
}

#block-superfish-1 .sf-sub-indicator {
  color: #9e0b0f;
  position: relative;
  margin: -19px auto 0 auto;
  right: 0;
}

#block-superfish-1 .menu {
  width: 1140px;
  margin: 13px auto;
}

#block-superfish-1 li {
  list-style: none;
}

#block-superfish-1 li a {
  color: #9e0b0f;
  text-transform: uppercase;
  padding: 0 1em;
  font-size: 20px;
  font-family: "mr-eaves-xl-sans-narrow";
  font-weight: bold;
}

#block-superfish-1 .sf-depth-2 {
  background-color: #f3d476;
}

#block-superfish-1 .sf-depth-2 a {
  border-top: solid 2px #fcf3d9;
  padding: 20px 8px;
}

#block-superfish-1 .sf-menu.sf-horizontal.sf-shadow ul, #block-superfish-1 .sf-menu.sf-vertical.sf-shadow ul, #block-superfish-1 .sf-menu.sf-navbar.sf-shadow ul ul {
  margin-top: -10px;
}

.block-easy-breadcrumb {
  margin-left: 10vw;
  font-weight: 100;
  font-size: 14px;
  font-family: "museo-sans";
  color: #aaaaaa;
  clear: both;
  z-index: 15;
}

.page-node-1 .block-easy-breadcrumb {
  display: none;
}

#menu-765-1 a {
  font-family: "house-script" !important;
  font-weight: 400 !important;
  padding-top: 2px !important;
}

li.mmenu-mm-list-mlid-765 a {
  font-family: "house-script" !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}

/*------------------------Webform Styles-----------------------------*/
/*------------------------ Random Sayings Styles -------------------*/
#block-views-random-sayings-block {
  width: 100vw;
  clear: both;
  padding-top: 3em;
}

.views-field-field-saying {
  width: 100vw;
  text-align: center;
  font-family: "gioviale";
  color: #9e0b0f;
  font-size: 2em;
  padding: 0 1em;
}

/*--------------------Footer-----------------------------------*/
.slogan-bar {
  display: none;
  margin-top: 3em;
}

@media (min-width: 1240px) {
  .slogan-bar {
    display: block;
    background-color: #f3d476;
    color: #fff;
    font-family: "museo-sans";
    font-style: italic;
    height: 90px;
    padding-top: 1.5em;
    text-align: center;
  }
  .slogan-bar h2 {
    border: 2px solid #fff;
    margin: 0 auto;
    max-width: 1210px;
    width: 100vw;
  }
}

#block-panels-mini-footer-image {
  background-image: url(/sites/default/files/families-images/footer-main-image.jpg);
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: cover;
  height: 1175px;
  margin-top: 2em;
}

@media (min-width: 486px) {
  #block-panels-mini-footer-image {
    height: 795px;
  }
}

#block-panels-mini-footer-image h1 {
  font-size: 1.7rem;
}

#block-panels-mini-footer-image h2 {
  font-size: 1.25rem;
}

#block-panels-mini-footer-image p {
  margin: .5rem 0;
}

#block-panels-mini-footer-image .panel-2col .panel-col-first,
#block-panels-mini-footer-image .panel-2col .panel-col-last {
  width: 100%;
  float: none;
  margin: auto;
}

#block-panels-mini-footer-image .panel-2col .panel-col-first {
  max-width: 687px;
}

#block-panels-mini-footer-image .panel-2col .panel-col-last {
  max-width: 366px;
  min-width: 300px;
}

#block-panels-mini-footer-image .fb_iframe_widget {
  height: 405px !important;
}

#block-panels-mini-footer-image span {
  height: 405px !important;
}

#block-panels-mini-footer-image iframe {
  height: 405px !important;
  width: 310px !important;
}

@media (min-width: 350px) {
  #block-panels-mini-footer-image iframe {
    width: 340px !important;
  }
}

#block-panels-mini-footer-image {
  text-align: center;
}

#block-panels-mini-footer-image h1 {
  color: #1d7db3;
  font-weight: 700;
  font-family: "museo-sans";
  padding-top: 1.5em;
  margin-top: 0;
  margin-bottom: 0px;
  line-height: 2rem;
  text-transform: uppercase;
}

#block-panels-mini-footer-image .left,
#block-panels-mini-footer-image .right {
  font-weight: 300;
  color: #9e0b0f;
  padding: 0 7px;
}

#block-panels-mini-footer-image .left a,
#block-panels-mini-footer-image .right a {
  color: #9e0b0f;
  font-weight: 700;
}

#block-panels-mini-footer-image .left h2,
#block-panels-mini-footer-image .right h2 {
  font-family: "museo-sans";
  text-transform: uppercase;
}

#block-panels-mini-footer-image .left .phone,
#block-panels-mini-footer-image .left .directions,
#block-panels-mini-footer-image .right .phone,
#block-panels-mini-footer-image .right .directions {
  font-weight: 700;
}

#block-panels-mini-footer-image .left .address-line,
#block-panels-mini-footer-image .right .address-line {
  display: none;
}

#block-panels-mini-footer-image .links a {
  text-transform: uppercase;
  color: #9e0b0f;
  font-size: 1.3rem;
  font-weight: 600;
}

#block-block-3 .footer {
  background-color: #707070;
  color: #fff;
  height: 68px;
  padding-top: 13px;
  text-align: center;
}

@media (min-width: 424px) {
  #block-block-3 .footer {
    height: 53px;
  }
}

#block-block-3 .footer p {
  margin: 0;
}

#block-block-3 .footer a {
  color: #fff;
}

/*---------------------------------------- Tablet styles --------------------------------*/
@media (min-width: 770px) {
  .narrow {
    width: 80vw;
  }
  #block-block-2 {
    text-align: center;
  }
  #block-block-2 img {
    margin-top: -21px;
  }
}

@media (min-width: 1024px) {
  #block-panels-mini-footer-image {
    text-align: left;
    height: 602px;
    background-size: contain;
  }
  #block-panels-mini-footer-image .addresses {
    float: left;
    margin-left: 4vw;
  }
  #block-panels-mini-footer-image .addresses .left {
    float: left;
    width: 350px;
  }
  #block-panels-mini-footer-image .addresses .right {
    float: right;
    width: 247px;
  }
  #block-panels-mini-footer-image .links {
    float: right;
    margin-right: 4vw;
    line-height: 3;
  }
  #block-panels-mini-footer-image .left .address-line,
  #block-panels-mini-footer-image .left .directions,
  #block-panels-mini-footer-image .right .address-line,
  #block-panels-mini-footer-image .right .directions {
    display: block;
  }
  #block-panels-mini-footer-image .panel-2col .panel-col-first {
    float: left;
    width: 64%;
    max-width: 710px;
  }
  #block-panels-mini-footer-image .panel-2col .panel-col-last {
    float: right;
    width: 30%;
    margin-top: 2em;
    margin-right: 5%;
  }
}

/*----------------------------------------- Tablet Landscape Styles --------------------------------*/
@media (min-width: 1024px) {
  .narrow {
    width: 80vw;
  }
  .footer-background {
    height: 418px;
    background-size: contain;
  }
  #block-panels-mini-footer-image {
    text-align: left;
  }
  #block-panels-mini-footer-image .addresses {
    float: left;
    margin-left: 4vw;
  }
  #block-panels-mini-footer-image .addresses .left {
    float: left;
    width: 350px;
  }
  #block-panels-mini-footer-image .addresses .right {
    float: right;
    width: 247px;
  }
  #block-panels-mini-footer-image .links {
    float: right;
    margin-right: 8vw;
    line-height: 3;
  }
  #block-panels-mini-footer-image .left .address-line,
  #block-panels-mini-footer-image .left .directions,
  #block-panels-mini-footer-image .right .address-line,
  #block-panels-mini-footer-image .right .directions {
    display: block;
  }
  #block-block-2 {
    border-bottom: none;
  }
}

@media (min-width: 1145px) {
  #block-block-1 {
    display: none;
  }
  #block-superfish-1 {
    display: block;
  }
}

@media (min-width: 1240px) {
  #block-panels-mini-footer-image {
    margin-top: 0px;
    height: 477px;
  }
  .panel-2col .panel-col-first {
    margin-left: 8% !important;
  }
  .panel-2col .panel-col-last {
    margin-right: 8%;
  }
}

@media (min-width: 1600px) {
  #block-panels-mini-footer-image .panel-2col .panel-col-first {
    width: 70%;
  }
}
