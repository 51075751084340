@import 'init';

//Variables
$yellow: #f7cf57;
$gray: #aaaaaa;
$light-gray: #eaeaea;
$aqua: #92c6ce;
$menu-tan: #fcf3d9;
$yellow2: #f3d476;
$dark-gray: #707070;
$blue-font: #1d7db3;
$red-font: #9e0b0f;
$recycled-back: #ebf3dc;
$recycled-text: #536a27;
$wooden-back: #dbd1c3;
$wooden-text: #5e4d36;
$museo: "museo-sans";
$gio: "gioviale";
$mr-eaves-mod: "mr-eaves-xl-modern";
$mr-eaves-sans: "mr-eaves-xl-sans-narrow";
$slab: "museo-slab";
$tab: 770px;
$tab-l: 1024px;
$desk: 1240px;


.page-taxonomy-term-15 {
	.page-title {
		display: none;
	}
}

#block-block-32 {
	background-color: $aqua;
	color: #fff;
	text-align: right;
	height: 250px;
	margin-top: -6px;
	h1 {
		text-transform: uppercase;
		margin-right: 30px;
		font-family: $museo;
		font-weight: 100;
		font-size: 3em;
		padding-top: .3em;
		margin-top: 0;
		letter-spacing: 3px;
		span {
			font-weight: 900;
		}
	}
	h2 {
		font-family: $museo;
		color: #fff;
		font-size: 2em;
		font-weight: 100;
		margin-right: 30px;
		span {
			font-weight: 100;
		}
	}	
	.contact-info {
		color: #fff;
		text-align: right;
		margin-right: 30px;
		padding-top: 20px;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;		
		h3 {
			line-height: 2rem;
			margin: 0;
		}
		.phone {
			font-weight: 300;
			img {
				width: 30px;
			}
		}
		.email {
			padding-left: 20px;
			img {
			 	width: 50px;
			 }
		}
		a {
			color: #fff;
			font-weight: 300;
		}
	}
}

.sheds-photo {
	background-image: url(/sites/default/files/families-images/shed.jpg);
	background-position: center center;
	background-size: cover;
	overflow: hidden;
	height: 250px;
	border-radius: 0px 0px 10px 10px;
	display: block;
}




/*-------------------Tablet Styles ------------------------------------*/
@include breakpoint ($tab) {
	#block-block-32 {
		width: 50vw;
		max-width: 50%;
		overflow: hidden;		
		float: left;
		height: 317px;
		padding-top: 1.5em;
		margin-top: 0px;
		h1 {
			font-size: 3.5em;
		}
	}
	.display-photo {
		width: 50vw;
		max-width: 50%;
		overflow: hidden;		
		float: right;
		min-height: 278px;
	}
}	
/*--------------------------Landscape Tablet Styles ------------------------*/
@include breakpoint ($tab-l) {
	.sheds-photo {
	    border-radius: 0px 10px 10px 0px;
	    min-height: 308px;
	}
}


/*----------------------Desktop Styles---------------------------*/
