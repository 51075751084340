@import 'init';

//Variables
$yellow: #f7cf57;
$gray: #aaaaaa;
$light-gray: #eaeaea;
$aqua: #92c6ce;
$menu-tan: #fcf3d9;
$yellow2: #f3d476;
$dark-gray: #707070;
$blue-font: #1d7db3;
$red-font: #9e0b0f;
$museo: "museo-sans";
$gio: "gioviale";
$mr-eaves-mod: "mr-eaves-xl-modern";
$mr-eaves-sans: "mr-eaves-xl-sans-narrow";
$slab: "museo-slab";
$tab: 800px;
$tab-l: 1024px;
$desk: 1240px;

/*------------Styles --------------*/
h1 {
	font-family: $slab;
	
}

h2 {
	font-family: $slab;
}

h3 {
	font-family: $mr-eaves-mod;
}

.heading {
	 text-align: center;
}


.slogan {
	font-weight: 700;
	color: $blue-font;
	font-size: 1.6em;
	line-height: 1;
	margin: 0;
}

.items {
	font-weight: 100;
	color: $blue-font;
	font-size: .92em;
	line-height: 1;
	margin: 0;
}
.page-node-1 {
	.contact-info {
		color: $red-font;
		text-align: center;
		margin-top:	1.5em;
		display: block;
		h3 {
			line-height: 2rem;
			margin: 0;
		}
		.phone {
			font-weight: 300;
			img {
				width: 30px;
			}
		}
		.email {
			img {
			 	width: 50px;
			 }
		}
		a {
			color: $red-font;
			font-weight: 300;
		}
	}
}

#block-views-front-slideshow-block {
	max-height: 210px;
	min-height: 150px;
	overflow: hidden;
	.view-front-slideshow {
		width: 100%;
		float: right;
	}
	.view-front-slideshow .views-slideshow-cycle-main-frame {
		width: 100% !important;
		height: auto;
	}
	.view-front-slideshow .views-slideshow-cycle-main-frame-row {
		width: 100% !important;
		height: auto;
	}
	.view-front-slideshow .field-content {
		max-width: 100%;
		width: 100%;
	}
	.view-front-slideshow .field-content img {
		max-width: 100%;
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
		min-width: 590px;
	}
}

.flex-icon:nth-of-type(1) { order: 1; }
.flex-icon:nth-of-type(2) { order: 2; }
.flex-icon:nth-of-type(3) { order: 4; }
.flex-icon:nth-of-type(4) { order: 3; }
.flex-icon:nth-of-type(5) { order: 5; }
.flex-icon:nth-of-type(6) { order: 6; }

.product-icons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
	flex-direction: row;
	flex-flow: row wrap;
	margin-top: 1.7em;
	margin-left: auto;
	margin-right: auto;
	a {
		text-align: center;
	}
	a:visited {
		color: $blue-font;
	}
}

.circle {
	border-radius: 50%;
	//max-height: 350px;
	//max-width: 350px;
	//min-height: 120px;
	//min-width: 120px;
	width: 50%;
	padding-bottom: 50%;
	position: relative;
	text-align: center;
	display: inline-block;
	img {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		position: absolute;
	}
}

.product-link {
	font-size: 1em;
	font-weight: bold;
	color: $blue-font;
	line-height: 1;
	margin: 0;
	text-transform: uppercase;		
}

#outdoor {
	width: 25vw;
	min-width: 147px;
	.circle {
		background-color: $yellow;
	}
	img {
		width: 62%;
		margin: 18% auto;
	}	
}

#playsets {
	min-width: 147px;
	width: 25vw;	
	.circle {
		background-color: $gray;
	}
	img {
		width: 62%;
		margin: 28% auto;
	}	
}

#displays {
	min-width: 147px;
	width: 25vw;	
	.circle {
		background-color: $aqua;
	}
	img {
		width: 72%;
		margin: 8% auto;
	}	
}

#structures {
	min-width: 147px;
	width: 25vw;
	.circle {
		background-color: $aqua;
	}
	img {
		width: 81%;
		margin: 20% auto;
	}	
}

#buyers-guide {
	min-width: 147px;
	width: 25vw;	
	.circle {
		background-color: $gray;
	}
	img {
		width: 65%;
		margin: 6% auto;
	}	
}

#reviews {
	min-width: 147px;
	width: 25vw;
	 .circle {
		background-color: $yellow;
	}
	img {
		width: 62%;
		margin: 18% 2% 0% 24%;
	}	
}

.body {
	font-family: $museo;
	h2 {
		font-weight: 700;
		color: $blue-font;
		font-family: $museo;
		font-size: 1.3em;
	}
	p {
		font-weight: 300;
		color:	$gray;
		span {
			color: $red-font;
			font-style: italic;
			font-weight: 700;
		}
	}
	a {
		color: $dark-gray;
		font-weight: bold;
	}
}

.social-media {
	clear:both;
	font-family: $museo;
	color: $red-font;	
	margin: 0 auto;
	width: 86vw;
	text-align: center;
	p {
		margin: 13px;
	}
	.gray-box {
		background-color: $light-gray;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 55vw;
		margin: 0 auto;
		min-width: 233px;
		border-radius: 10px;
		a {
			text-transform: uppercase;
			width: 210px;
			font-family: $museo;
			color: $red-font;
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding-left: 1em;
			img {
				width: 50px;
				margin: 1em;
			}
		}
		a:first-child {
			color: #3b5998;
			padding-left: 0;
		}
	}
}

/*------------------------------------ Tablet Styles ----------------------------*/
@include breakpoint (555px) {
	#block-views-front-slideshow-block {
		//margin-top: -48px;
	}
}
@include breakpoint ($tab) {
	#block-views-front-slideshow-block {
		margin-top: 0px;
	}
		
	.flex-heading {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	
	.heading {
		text-align: left;
		float: left;
		.slogan {
			font-size: 1;
		}
		.items {
			font-size: 1.25em;
		}				
	}
	.page-node-1 {	
		.contact-info {
			float: right;
			text-align: right;
			margin-top: 0;
			h3 {
				font-size: 1.5rem;
				line-height: 1;
			}
		}
	}
	.product-icons {
		clear: both;
		padding-top: 1em;
	}
	.flex-icon:nth-of-type(1) { order: 1; }
	.flex-icon:nth-of-type(2) { order: 2; }
	.flex-icon:nth-of-type(3) { order: 3; }
	.flex-icon:nth-of-type(4) { order: 4; }
	.flex-icon:nth-of-type(5) { order: 5; }
	.flex-icon:nth-of-type(6) { order: 6; }
	#buyers-guide{
		.circle {
			background-color: $yellow;
		}
	}
	#reviews {
		.circle {
			background-color: $gray;
		}
	}
	.social-media {
		clear: both;
		.gray-box {
			width: 75vw;
			justify-content: space-around;
		}
	}
}

@include breakpoint ($tab-l) {
	.product-link br{
		display: none;
	}
}
/*---------------------------------------Desktop Styles ------------------------------*/
@include breakpoint ($desk) {	
	.narrow {
		width: 75vw;
	}
	.circle {
		width: 35%;
		padding-bottom: 35%;
	}
	.product-link {
		line-height: 1.5;
		font-size: 1.4em;
	}
	.flex-heading {
		margin-top: 0px;
	}
	.social-media {
		width: 79vw;
		.gray-box {
			width: 51vw;
			justify-content: space-around;
		}
	}	
}