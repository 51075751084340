@import 'init';

//Variables
$yellow: #f7cf57;
$gray: #aaaaaa;
$light-gray: #eaeaea;
$aqua: #92c6ce;
$menu-tan: #fcf3d9;
$yellow2: #f3d476;
$dark-gray: #707070;
$blue-font: #1d7db3;
$red-font: #9e0b0f;
$recycled-back: #ebf3dc;
$recycled-text: #536a27;
$wooden-back: #dbd1c3;
$wooden-text: #5e4d36;
$museo: "museo-sans";
$gio: "gioviale";
$mr-eaves-mod: "mr-eaves-xl-modern";
$mr-eaves-sans: "mr-eaves-xl-sans-narrow";
$slab: "museo-slab";
$tab: 770px;
$tab-l: 1024px;
$desk: 1240px;

/*--------------------------Contact Us Page---------------------*/
.page-node-16 {
	.narrow {
		text-align: center;
		.location {
			width: 75%;
			max-width: 495px;
			min-width: 300px;
			max-height: 245px;
			margin: 20px;
			box-shadow: 0 0 6px $gray;
			display: inline-block;
			border-radius: 10px;
			text-align: center;
			h2 {
				font-size: 1.13em;
				color: white !important;
				background-color:$blue-font;
				margin-top: 0px;
				padding-top: 10px;
				height: 55px;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}
			p {
				padding: 0 .7em;
			}
			.maps {
				background-color: $yellow2;
				color: $red-font;
				border: 2px solid white;
				box-shadow: 0 0 5px $dark-gray;
				text-decoration: none;
				padding: 8px;
				
			}
			.tele {
				text-decoration: none;
				color: $red-font;
				font-size: 1.5em
			}	
		}
	}
	#mini-panel-contact_us_form {
		a {
			font-weight: 600;
			text-decoration: underline;
		}
	}
	@include breakpoint ($tab) {
		#block-panels-mini-contact-us-form {
		    width: 75%;
		    margin: auto;
		}
		.narrow {
			width: 45%;
			float: right;
		}
	}
}

#block-block-38{
	background-color: $yellow;
	color: #fff;
	text-align: right;
	height: 289px;
	margin-top: -6px;
	h1 {
		text-transform: uppercase;
		margin-right: 30px;
		font-family: $museo;
		font-weight: 100;
		font-size: 3em;
		padding-top: .5em;
		margin-top: 0;
		letter-spacing: 3px;
		span {
			font-weight: 900;
		}
	}
	h2 {
		font-family: $museo;
		color: #fff;
		font-size: 2em;
		font-weight: 100;
		margin-right: 30px;
		span {
			font-weight: 100;
		}
	}	
	.contact-info {
		color: #fff;
		text-align: right;
		margin-right: 30px;
		padding-top: 20px;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		h3 {
			line-height: 2rem;
			margin: 0;
		}
		.phone {
			font-weight: 300;
			img {
				width: 30px;
			}
		}
		.email {
			padding-left: 20px;
			img {
			 	width: 50px;
			 }
		}
		a {
			color: #fff;
			font-weight: 300;
		}
	}	
}

.contact-photo {
	background-image: url(/sites/default/files/families-images/workstation-336369.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 240px;
}


.page-node-16 {
	.page-title {
		color: $blue-font;
	}
	.narrow h2 {
		color: $red-font;
		text-align: center;
	}
}
#block-block-42 {
	clear: both;
	.locate {
		text-align: center;
	}
	
	.locate-head {
		color:$red-font;
	}
	
	.location {
		width: 75%;
		max-width: 495px;
		max-height: 245px;
		margin: 20px 40px;
		box-shadow: 0 0 6px $gray;
		display: inline-block;
		border-radius: 10px;
		text-align: center;
		h2 {
			font-size: 1.13em;
			color: white !important;
			background-color:$blue-font;
			margin-top: 0px;
			padding-top: 10px;
			height: 55px;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
		p {
			padding: 0 .7em;
		}
		.maps {
			background-color: $yellow2;
			color: $red-font;
			border: 2px solid white;
			box-shadow: 0 0 5px $dark-gray;
			text-decoration: none;
			padding: 8px;
			
		}
		.tele {
			text-decoration: none;
			color: $red-font;
			font-size: 1.5em
		}
	}
	
	#info-box {
		background-color: $light-gray;
		width: 80%;
		color: $red-font;
		margin: 2em auto -6px auto;
		padding: 1em 2em;
		text-align: left;
		border-radius: 10px;
	}
}

.pane-webform-client-block-52 {
	width: 80%;
	max-width: 553px;
	padding: 0 2em;
	text-align: center;
	margin: 0 auto;
	.pane__title {
		color: $blue-font;
	}
	.resizable-textarea {
		max-width: 553px;
		margin: 0 auto;
	}
	.form-item.webform-component.webform-component-select.webform-component--please-contact-me {
		label {
			font-size: 14px;
			font-weight: 300;
			color: $blue-font;
		}
		select#edit-submitted-please-contact-me {
			color: $blue-font;
			font-size: 14px;
		}
	}
	input.webform-submit.button-primary.form-submit {
		background-color: $yellow;
		color: $red-font;
		border: 2px solid white;
		box-shadow: 0 0 5px $dark-gray;
		text-decoration: none;
		padding: 1px 20px;
	}
}
@include breakpoint ($tab) {
	.pane-webform-client-block-52 {
		width: 45%;
		max-width: 553px;
		min-width: 300px;
		padding: 0 2em;
		text-align: center;
		float: left;
		.pane__title {
			color: $blue-font;
		}	
	}	
	.form-item.webform-component.webform-component-select.webform-component--please-contact-me {
		float: left;
		margin-top: -10px;
		text-align: left;
	}
	input.webform-submit.button-primary.form-submit {	
		float: right;	
		padding-top: 8px;
	}
	#block-block-38 {
		width: 50vw;
		max-width: 50%;
		overflow: hidden;		
		float: left;
		height: 317px;
		padding-top: 2em;
		margin-top: 0px;
		h1 {
			font-size: 3.5em;
		}
	}
	.contact-photo {
		width: 50vw;
		max-width: 50%;
		overflow: hidden;		
		float: right;
		height: 261px;
	}		
}

/*-------------------------About Us Page---------------------*/

#block-block-35 {
	background-color: $gray;
	color: #fff;
	text-align: right;
	height: 289px;
	margin-top: -6px;
	h1 {
		text-transform: uppercase;
		margin-right: 30px;
		font-family: $museo;
		font-weight: 100;
		font-size: 3em;
		padding-top: .5em;
		margin-top: 0;
		letter-spacing: 3px;
		span {
			font-weight: 900;
		}
	}
	h2 {
		font-family: $museo;
		color: #fff;
		font-size: 2em;
		font-weight: bold;
		margin-right: 30px;
		span {
			font-weight: 100;
		}
	}	
	.contact-info {
		color: #fff;
		text-align: right;
		margin-right: 30px;
		padding-top: 20px;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;		
		h3 {
			line-height: 2rem;
			margin: 0;
		}
		.phone {
			font-weight: 300;
			img {
				width: 30px;
			}
		}
		.email {
			padding-left: 20px;
			img {
			 	width: 50px;
			 }
		}
		a {
			color: #fff;
			font-weight: 300;
		}
	}	
}

#block-views-structure-banner-block-1,
#block-views-structure-banner-block-2,
#block-views-structure-banner-block-3,
#block-views-structure-banner-block-4,
#block-views-structure-banner-block-5 {
	height: 240px;
	overflow: hidden;
	.view-outdoor-living-banner {
		width: 100%;
		float: right;
	}
	.view-outdoor-living-banner .views-slideshow-cycle-main-frame {
		width: 100% !important;
		height: auto;
	}
	.view-outdoor-living-banner .views-slideshow-cycle-main-frame-row {
		width: 100% !important;
		height: auto;
	}
	.view-outdoor-living-banner .field-content {
		max-width: 100%;
		width: 100%;
	}
	.view-outdoor-living-banner .field-content img {
		max-width: 100%;
		width: 100%;
		height: auto;
		margin: 0;
		padding: 0;
		min-width: 590px;
		margin-top: -65px;
	}
}


.page-node-15 {
	.page-title {
		color: $blue-font;
	}
	.info-box {
		text-align: center;
		.about-heading-1 {
			color: #1d7db3;
			letter-spacing: 4px;
			font-size: 2rem;
			margin-bottom: 0;
			@include breakpoint (600px) {
				letter-spacing: 9px;
				font-size: 3rem;
			}
		}
		.about-flex {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
		}
		
		ul {
			list-style: none;		
			text-align: left;
			padding-left: 0em;
			min-width: 300px;
			@include breakpoint (548px){
				padding: 0 4em;
			}
			
			span {
				font-weight: bold;
				color: $blue-font;
			}
		}
		.left {
			height: 175px;
			width: 175px;
			border-radius: 50%;
			border: solid 4px $light-gray			
		}
	}
	.about-heading,
	.acro {
		color: $red-font;
		text-align: center;
		font-size: 1.75rem;
	}
	.about-family {
		margin: 0 auto;
		display: block;
		border: solid 4px $light-gray;
		width: 35vw;
	}
	.do {
		border-top: 10px dotted $aqua;
		clear: both;
	}
	.about-left,
	.about-right {
		list-style: none;
		text-align: center;
		padding: 0;
		h3 {
			font-family: $mr-eaves-mod;
			font-size: 2rem;
		}
		p {
			color: $blue-font;
			font-size: 20px;
		}
	}
	@include breakpoint ($tab) {
		.about-left {
			width: 45%;
			float: left;
		}
		.about-right {
			width: 45%;
			float: right;
		}
	}
	.photo-label {
		text-align: center;
	}
	.about-end {

		color: $blue-font;
	}
	.signature {
		font-family: $gio;
		font-weight: 600;
		text-align: right;
		font-size: 30px;
	}
	
}

@include breakpoint ($tab) {
	#block-views-structure-banner-block-1,	
	#block-views-structure-banner-block-2,
	#block-views-structure-banner-block-3, 
	#block-views-structure-banner-block-4,
	#block-views-structure-banner-block-5 {
		width: 50vw;
		float: right;
		min-height: 317px;
		max-width: 50%;
		overflow: hidden;
		.field-content img {
			float: right;
			max-width: 50vw;
			min-width: 550px;
		}
	}		
	#block-block-35 {
		width: 50vw;
		max-width: 50%;
		overflow: hidden;		
		float: left;
		height: 317px;
		padding-top: 2em;
		margin-top: 0px;
		h1 {
			font-size: 3.5em;
		}
	}
	.about-photo {
		width: 50vw;
		max-width: 50%;
		overflow: hidden;		
		float: right;
		height: 261px;
	}	
}
	
/*--------------------Buyer's Guide Page----------------------*/
#block-block-40 {
	background-color: $yellow;
	color: #fff;
	text-align: right;
	height: 289px;
	margin-top: -6px;
	h1 {
		text-transform: uppercase;
		margin-right: 30px;
		font-family: $museo;
		font-weight: 100;
		font-size: 3em;
		padding-top: .5em;
		margin-top: 0;
		letter-spacing: 3px;
		span {
			font-weight: 900;
		}
	}
	h2 {
		font-family: $museo;
		color: #fff;
		font-size: 2em;
		font-weight: 100;
		margin-right: 30px;
		span {
			font-weight: 100;
		}
	}	
	.contact-info {
		color: #fff;
		text-align: right;
		margin-right: 30px;
		padding-top: 20px;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;		
		h3 {
			line-height: 2rem;
			margin: 0;
		}
		.phone {
			font-weight: 300;
			img {
				width: 30px;
			}
		}
		.email {
			padding-left: 20px;
			img {
			 	width: 50px;
			 }
		}
		a {
			color: #fff;
			font-weight: 300;
		}
	}	
}

.guide-photo {
	background-image: url(/sites/default/files/families-images/workstation-336369.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 240px;	
}

.page-node-14 {
	.buyers {
		text-align: center;
		font-weight: bold;
	}
	.narrow {
		h2 {
			color: $blue-font;
			a {
				color: $blue-font;
				text-decoration: underline;
			}
		}
	}
}

iframe {
	height: 430px;
}

@include breakpoint ($tab) {
	#block-block-40 {
		width: 50vw;
		max-width: 50%;
		overflow: hidden;		
		float: left;
		height: 317px;
		padding-top: 2em;
		margin-top: 0px;
		h1 {
			font-size: 3.5em;
		}
	}
	.guide-photo {
		width: 50vw;
		max-width: 50%;
		overflow: hidden;
		float: right;
		height: 261px;
	}	
}

/*------------------Blog and Taste and See Page -----------------------------*/

#block-block-37 {
	background-color: $aqua;
	color: #fff;
	text-align: right;
	height: 289px;
	margin-top: -6px;
	h1 {
		text-transform: uppercase;
		margin-right: 30px;
		font-family: $museo;
		font-weight: 100;
		font-size: 3em;
		padding-top: .5em;
		margin-top: 0;
		letter-spacing: 3px;
		span {
			font-weight: 900;
		}
	}
	h2 {
		font-family: $museo;
		color: #fff;
		font-size: 2em;
		font-weight: 100;
		margin-right: 30px;
		span {
			font-weight: 100;
		}
	}	
	.contact-info {
		color: #fff;
		text-align: right;
		margin-right: 30px;
		padding-top: 20px;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;		
		h3 {
			line-height: 2rem;
			margin: 0;
		}
		.phone {
			font-weight: 300;
			img {
				width: 30px;
			}
		}
		.email {
			padding-left: 20px;
			img {
			 	width: 50px;
			 }
		}
		a {
			color: #fff;
			font-weight: 300;
		}
	}	
}

.blog-photo {
	background-image: url(/sites/default/files/families-images/workstation-336369.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 240px;
}

.page-blog {
	.page-title {
		display: none;
	}
	.view-display-id-block_1 {
		margin-top: 0px;
	}	
}

.section-blog,
.node-type-article {
	.page-title {
		color: $blue-font;
	}
	.node-article {
		width: 70vw;
		margin: 0 auto;
		.field-name-body {
			p:first-child {
				margin-top: 0px;
			}
		}
	}


	.view-display-id-block_1 {
		background-color: $menu-tan;
		border: 2px solid $yellow;
		width: 240px;
		border-radius: 10px;
		margin: 0 auto;
		padding: 0 1em 1em;
		text-align: left;
		display: none;
		.view-header {
			color: $red-font;
			p {
				font-family: $mr-eaves-sans;
				font-weight: bold;
				font-size: 26px;
			}
		}
		.view-content {
			a {
				color: $blue-font;

			}
		}
		@include breakpoint ($tab-l) {
			display: block;
		}
	}
	
	.view-blog.view-display-id-page {
		width: 70vw;
		margin: 0 auto;
		.views-row {
			border-bottom: 2px dashed $light-gray;
			padding-top: 30px;
			a {
				color: $blue-font;
				font-size: 25px;
				font-family: $museo;
				font-weight: bold;		
			}	
		}
		.views-row:first-child {
			padding-top: 0px;
		}
	}



	@include breakpoint ($tab) {
		#block-block-37 {
			width: 50vw;
			max-width: 50%;
			overflow: hidden;		
			float: left;
			height: 317px;
			padding-top: 2em;
			margin-top: 0px;
			h1 {
				font-size: 3.5em;
			}
		}
		.blog-photo {
			width: 50vw;
			max-width: 50%;
			overflow: hidden;		
			float: right;
			height: 261px;
		}
	}
	@include breakpoint ($tab-l) {	
		#block-views-blog-block-1,
		.view-blog.view-display-id-block_1 {
			float: left;
			margin-left: 5%;
		}
		.view-blog.view-display-id-page,
		.node-article {
			width: 62%;
			float: right;
			margin-right: 7vw;
			text-align: left;
		}
	}
}

#block-block-48 {
	background-color: #9f0602;
	color: #fff;
	text-align: right;
	height: 289px;
	margin-top: -6px;
	h1 {
		text-transform: uppercase;
		margin-right: 30px;
		font-family: $museo;
		font-weight: 100;
		font-size: 3em;
		padding-top: .5em;
		margin-top: 0;
		letter-spacing: 3px;
		span {
			font-weight: 900;
		}
	}
	h2 {
		font-family: $museo;
		color: #fff;
		font-size: 2em;
		font-weight: 100;
		margin-right: 30px;
		span {
			font-weight: 100;
		}
	}	
	.contact-info {
		color: #fff;
		text-align: right;
		margin-right: 30px;
		padding-top: 20px;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;		
		h3 {
			line-height: 2rem;
			margin: 0;
		}
		.phone {
			font-weight: 300;
			img {
				width: 30px;
			}
		}
		.email {
			padding-left: 20px;
			img {
			 	width: 50px;
			 }
		}
		a {
			color: #fff;
			font-weight: 300;
		}
	}	
}

.page-taste-and-see {
	.page-title {
		display: none;
	}
	.view-display-id-block_1 {
		margin-top: 0px;
	}	
}

.section-taste-and-see,
.node-type-taste-and-see-post {
	.page-title {
		color: $blue-font;
	}
	.node-taste-and-see-post {
		width: 70vw;
		margin: 0 auto;
		.field-name-body {
			p:first-child {
				margin-top: 0px;
			}
		}
	}
	.field-name-field-image {
		.field-items {
			display: flex;
			flex-wrap: wrap;
		}
		.field-item {
			max-width: 400px;
			min-width: 300px;
			width: 30%;
		}
	}


	.view-display-id-block_1 {
		background-color: $menu-tan;
		border: 2px solid $yellow;
		width: 240px;
		border-radius: 10px;
		margin: 0 auto;
		padding: 0 1em 1em;
		text-align: left;
		display: none;
		.view-header {
			color: $red-font;
			p {
				font-family: $mr-eaves-sans;
				font-weight: bold;
				font-size: 26px;
			}
		}
		.view-content {
			a {
				color: $blue-font;

			}
		}
		@include breakpoint ($tab-l) {
			display: block;
		}
	}
	
.view-taste-and-see.view-display-id-page {
	width: 70vw;
	margin: 0 auto;
	.view-header {
			img {
				max-width: 350px;
				margin: 0 auto;
			}
		}	
	.views-row {
		border-bottom: 2px dashed $light-gray;
		padding-top: 30px;
		a {
			color: $blue-font;
			font-size: 25px;
			font-family: $museo;
			font-weight: bold;		
		}	
	}
	.views-row:first-child {
		padding-top: 0px;
	}
}



	@include breakpoint ($tab) {
		#block-block-48 {
			width: 50vw;
			max-width: 50%;
			overflow: hidden;		
			float: left;
			height: 317px;
			padding-top: 2em;
			margin-top: 0px;
			h1 {
			}
		}
		.blog-photo {
			width: 50vw;
			max-width: 50%;
			overflow: hidden;		
			float: right;
			height: 261px;
		}
	}
	@include breakpoint ($tab-l) {	
		#block-block-48 {
			h1 {
				font-size: 3.5em;
			}
		}
		#block-views-blog-block-1,
		.view-taste-and-see.view-display-id-block_1 {
			float: left;
			margin-left: 5%;
		}
		.view-taste-and-see.view-display-id-page,
		.node-taste-and-see-post {
			width: 62%;
			float: right;
			margin-right: 7vw;
			text-align: left;
		}
	}
}


/*----------------- Delivery Page -------------------------*/
.page-node-48 {
	#block-block-45 {
		
	}
	.page-title {
		color: $blue-font;
	}
	.narrow {
		h2,
		p,
		a {
			color: $blue-font;
		}
	}

	.map-flex {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		text-align: center;
		background-color: $light-gray;
		border-radius: 10px;
		margin: 20px;
		padding: 0px 0px 25px;
		.directions {
			width: 51%;
			min-width: 300px;
			max-width: 400px;
		}
		iframe {
			border: 3px solid white;
			height: 300px;
		}
		img {
			border: solid 4px #fff;
		}
		p {
			margin-top: 8px;
			margin-bottom: 0px;
		}
		.left {
			float: left;
			margin-left: 46px;
		}
		.right {
			float: right;
			margin-right: 46px;
		}
		.country {
			clear: both;
		}
		.hidden {
			display: none;
		}
	}

	.delivery-icons {
		width: 100%;
		text-align: center;
		color: $blue-font;
		margin: 0 auto;
		#outdoor,
		#playsets,
		#structures {
			margin: 0 auto;
		}
	}
	
	.flex-icon {
		padding-top: 1.5em;
		h2 {
			font-size: 1.4em;
			line-height: 1.5;
			margin-top: .5rem;
		}
		h3 {
			font-size: 1.1em;
			line-height: 1.5;
			margin-top: .5rem;
		}
	}
	
	.circle {
		width: 70%;
		padding-bottom: 70%;
		@include breakpoint ($tab-l) {
			width: 45%;
			padding-bottom: 45%;
		}
	}

	.map-tool {
		color: $red-font;
		text-align: center;
		font-size: 1.5rem;
	}
}


@include breakpoint ($tab-l){
	.page-node-48 {
		#block-block-45 {
			h1 {
				font-size: 2.7em;
			}
		}
		.delivery-icons {
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
		}
		.flex-icon {
			display: flex;
			flex-direction: column;
			width: 33%;
			vertical-align: top;
			padding: 0px 10px;
		}
	}
}

@include breakpoint (900px) {
	.page-node-48 {
		#block-block-45 {
			h1 {
				font-size: 3.5em;
			}
		}
	}	
}

/*------------------------------Mulch and Border Page -------------------------------*/

#block-block-6 {
	width: 90vw;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;	
}
.simpleTabsContent {
	width: 500px;
}
#rubber_calculator_box {
	h2 {
		color: $recycled-text;
		text-align: center;
		padding-top: 20px;
	}
	background-color: $recycled-back;
	padding: 0 1em;
	border-radius: 10px;
	width: 100%;
	min-width: 285px;
	//margin: 1em auto;
}
#calculator_box {
	h2 {
		color: $wooden-text;
		text-align: center;
		padding-top: 20px;
	}
	background-color: $wooden-back;
	padding: 0 1em;
	border-radius: 10px;
	width: 100%;
	min-width: 285px;	
	//margin: 1em auto;		
}

.mulch-photos {
	display: flex;
	justify-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: baseline;
	.mulch-flex {
		display: flex;
		flex-direction: column;
		text-align: center;
		margin: 1em;
	}
}

/*-----------------------------Reviews Page-------------------------------*/
.review-first {
	text-align: center;
	a {
		font-weight: 600;
		text-decoration: underline;
	}
}
.review-text {
	max-width: 34em !important;
}
.reviews-links {
	text-align: center;
}

.google-rev {
	width: 100px;	
	vertical-align: middle;
	margin-bottom: 5px;
}

.facebook-rev {
	width: 35px;
}

.field-name-field-review {
	width: 70vw;
	margin: 0 auto;
}

.field-collection-container {
	border-bottom: none;
}

.field-collection-view {
	border: 1px solid $light-gray;
	padding: 1em;
	margin: 1em;
}

.field-name-field-review-text {
	clear: both;
	padding: 1em 0;
	font-weight: 300;
}

.field-name-field-name-and-place {
	font-weight: bold;
	color: $gray;
}

/*--------------------------------Shed Options Page--------------------*/
.page-node-171 { 
	.narrow {
		text-align: center;
	}
	.page-title {
		color: $aqua;
	}
	.options-head {
		color: $blue-font;
	}
	.options-title {
		color: $red-font;
		font-weight: bold;
		font-family: $mr-eaves-sans;
		text-align: center;
		font-size: 2rem;
		padding: .5em 3em;
		width: 100%;
		margin: 0 auto;
		border: 2px solid $yellow;
		background-color: $menu-tan;
		line-height: 1.2;
	}
	.options-flex-1,
	.options-flex-2,
	.options-flex-3  {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 2em;
		justify-content: space-around;
		align-items: baseline;
	}
	
	.options-flex-2,
	.options-flex-3 {
		.flex-items {
			width: 15%;
			min-width: 190px;
		}
		img {
			width: 150px;
		}
		.enlarge {
			transition: all 1s ease-in-out .2s;
		}	
		.enlarge:hover {
			transform: scale(1.5);
		}
	}	
	.flex-items {
		width: 30%;
		min-width:300px;
			img{
			border: 3px solid white;
			box-shadow: 1px 1px 6px $dark-gray;
		}
	}
}

/*---------------------------------Construction Specs Page-------------------------*/
.page-node-164 {
	.page-title {
		color: $dark-gray;
	}
	.specs-head {
		color: $red-font;
		text-align: center;
	}
	.big-specs,
	.specs-flex {
		clear: both;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 2em;
		justify-content: space-around;
		align-items: baseline;
	}
	.flex-items {
		width: 30%;
		min-width: 300px;
			img{
			border: 3px solid white;
			box-shadow: 1px 1px 6px $dark-gray;
		}			
	}
	.small-pict {
		width: 20%;
		margin: 30px 3px;
		min-width: 100px;
		border: 2px solid white;
		box-shadow: 1px 1px 6px $dark-gray;
		transition: all 1s ease-in-out .2s;
	}
	.small-pict:hover {
		transform: scale(1.5);
	}
}

/*---------------------------------Construction Specs Page-------------------------*/
.page-node-163 {
	.page-title {
		color: $blue-font;
	}
	.right {
		float: right;
	}
	.reasons-head {
		color: $red-font;
		text-align: center;
	}
	h3 {
		font-family: $mr-eaves-mod;
		font-size: 20px;
		
	}	
}

/*---------------------------------Site Prep Page-------------------------*/

.page-node-172 {
	.page-title {
		color: $blue-font;
	}
	.prep {
		color: $dark-gray;
	}
}

/*------------------------------Poly Colors Page--------------------------*/
.page-title-2 {
    color: #536a27;
    font-family: $museo;
    font-weight: 900;
    font-size: 3em;
    span {
	    font-weight: 100;
    }
}
.warnty {
	display: none;
	@include breakpoint ($tab) {
		display: block;
		float: right;
	}
}
.center {
	text-align: center;
	font-size: 1.2rem;
}

.colors {
	font-family: $gio;
	color: $blue-font;
	font-size: 3rem;
	text-align: center;
	}
.colors-2 {
	font-family: $museo;
	color: $red-font;
	font-size: 1.5rem;
	
}
.color-flex {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: baseline;
	margin: auto;
	width: 90%;
	min-width: 300px;

	.poly-color {
		width: 135px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 15px;
	}
}

.fabric {
	background-color: #ebf3dc;
	width: 85%;
	margin: 0 auto;
	a {
		color: $recycled-text;
	}
	.text {
		color: $recycled-text;
		 p:last-child {
			 text-align: center;
			 .view-fabrics {
				color: $recycled-back;
				background-color: $recycled-text;
				text-align: center; 
				padding: 7px 18px;
			}
		}
	}
	.fabrics-photo {
		background-image: url(/sites/default/files/families-images/fabric-color-options.png);
	    background-size: cover;
	    background-repeat: no-repeat;
	    background-position: center;
	    height: 250px;
	    overflow: hidden;
	}
}

@include breakpoint (1024px) {
	.fabric {
		.text {
			float: left;
			width: 40%;
		}

	}
	
}

/*------------------------------Designer Page--------------------------*/
.page-node-176 {
	iframe {
		height: 600px;
		display: block;
		margin: 0 auto;
	}
}


/*--------------------------Admin page ------------------------------*/

#user-login {
	margin: 0 auto;
	width: 80%;
	max-width: 500px;
}